import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";
import "./updatecards.component.css";
import Button from "@material-ui/core/Button";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import * as AiIcons from "react-icons/ai";
import { switchUrls } from "../../../api/index.js";

const baseURL = switchUrls("genie");
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let reports = [];

export default class MeasurementUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      selectedImage: null,
      measurement_reminder: false,
      tag_number: localStorage.getItem("tag_number"),
      Type: "Measurement",
      measurement_id: this.props.measurement_id,
      Height: "",
      Weight: "",
      Measurement_date: "",
      Measuredby: "",
      MeasurementNotes: "",
      MeasurementTitle: "",
      MeasurementEverynumber: "",
      MeasurementEvery: "",
      MeasurementNext: "",
      MeasurementRemind_me: "",
      MeasurementReminderNotes: "",
      MeasurementDone: false,
      Measurement_Reportname: "",
      Measurement_Reportlocation: "",
      Measurement_Reportnames: [],
      Measurement_Reportlocations: [],
      message: "",
      docs: [],
    };
  }

  join = (t, a, s) => {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);

      return f.format(t);
    }

    return a.map(format).join(s);
  };

  month = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];

  componentDidMount() {
    axios
      .get(`${baseURL}/api/pet/getMedicalDetails/` + this.state.tag_number)
      .then((response) => {
        response.data[0].Measurement.map((elm) => {
          if (this.state.measurement_id === elm.measurement_id) {
            this.setState({
              Height: elm.Height,
              Weight: elm.Weight,
              Measurement_date: this.join(
                new Date(elm.Measurement_date),
                this.month,
                "-"
              ),
              Measuredby: elm.Measuredby,
              MeasurementNotes: elm.MeasurementNotes,
              MeasurementTitle: elm.MeasurementTitle,
              MeasurementNext: elm.MeasurementNext
                ? this.join(new Date(elm.MeasurementNext), this.month, "-")
                : "",
              MeasurementRemind_me: elm.MeasurementRemind_me,
              MeasurementReminderNotes: elm.MeasurementReminderNotes,
              MeasurementDone: elm.MeasurementDone,
              Measurement_Reportname: elm.Measurement_Reportname,
              Measurement_Reportlocation: elm.Measurement_Reportlocation,
              Measurement_Reportnames: elm.Measurement_ReportsNames,
              Measurement_Reportlocations: elm.Measurement_ReportsLocations,
            });
          }
        });
        for (let i = 0; i < this.state.Measurement_Reportnames.length; i++) {
          let obj = {
            name: this.state.Measurement_Reportnames[i],
            location: this.state.Measurement_Reportlocations[i],
          };
          reports.push(obj);
        }
        this.setState({ docs: reports });
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  setReports = () => {
    for (let i = 0; i < this.state.Measurement_ReportsNames.length; i++) {
      let obj = {
        name: this.state.Measurement_ReportsNames[i],
        location: this.state.Measurement_ReportsLocations[i],
      };
      reports.push(obj);
    }
    this.setState({ docs: reports });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  measurementChangeHandler = (e) => {
    this.setState({ [e.target.name]: !this.state.MeasurementDone });
  };

  handleChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileSize = file.size;

    const fileExtension = fileName.split(".")[1];
    const size = fileSize / (1024 * 1024);

    const regex = /\./gm;

    const isValidFileExtension = [
      "pdf",
      "docx",
      "docs",
      "png",
      "jpg",
      "jpeg",
      "csv",
      "xlsx",
    ].includes(fileExtension);
    const isValidSize = size < 5 ? true : false;
    const isValidFileName = fileName.match(regex).length === 1 ? true : false;

    if (isValidFileExtension && isValidSize && isValidFileName) {
      this.setState({ message: "" });
      this.setState((prevState) => ({
        message: "",
        docs: [...prevState.docs, event.target.files[0]],
      }));
    } else if (!isValidFileName) {
      this.setState({
        message:
          "Oops!! File name is not appropriate. Kindly rename the file and try again.",
      });
    } else if (!isValidSize) {
      this.setState({ message: "File size should be less than 5 MB" });
    } else if (!isValidFileExtension) {
      this.setState({
        message: `File with extension .${fileExtension} are not allowed!`,
      });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();

    axios
      .post(`${baseURL}/api/pet/updateMedicalDetails`, this.state, {
        headers: { Authorization: usertoken },
      })
      .then((res) => {
        const fd = new FormData();

        for (let i = 0; i < this.state.docs.length; i++) {
          fd.append("image", this.state.docs[i]);
        }

        fd.append("tag_number", this.state.tag_number);
        fd.append("Type", this.state.Type);
        fd.append("measurement_id", this.state.measurement_id);

        axios
          .post(`${baseURL}/api/pet/reports-upload`, fd, {
            Accept: "application/json",
          })
          .then((response) => {
            window.location.reload();

            console.info(response);
          })
          .catch((error) => {
            console.error(error.messsage);
          });
      })
      .catch((error) => {
        console.error(error.message);

        this.props.closeDialog();
      });
  };

  render() {
    const {
      measurement_reminder,
      Height,
      Weight,
      Measurement_date,
      Measuredby,
      MeasurementNotes,
      MeasurementTitle,
      MeasurementEverynumber,
      MeasurementEvery,
      MeasurementNext,
      MeasurementRemind_me,
      MeasurementReminderNotes,
      MeasurementDone,
      Measurement_Reportname,
      Measurement_Reportlocation,
    } = this.state;
    return (
      <div className="update-card-details-form-wrapper">
        <form>
          <h4>Measurement</h4>

          <>
            <></>
            <div className="update-card-details-form-content">
              <label>Weight (kg) *</label>

              <input
                type="number"
                className="update-card-details-form-input"
                name="Weight"
                required
                value={Weight}
                onChange={this.changeHandler}
              />
            </div>

            <div className="update-card-details-form-content">
              <label>Height (cm)</label>

              <input
                type="number"
                className="update-card-details-form-input"
                name="Height"
                value={Height}
                onChange={this.changeHandler}
              />
            </div>

            <></>
            <div className="update-card-details-form-content">
              <label>Measurement Date *</label>

              <input
                type="date"
                className="update-card-details-form-input"
                name="Measurement_date"
                required
                value={Measurement_date}
                onChange={this.changeHandler}
              />
            </div>

            <div className="update-card-details-form-content">
              <label>Measured By</label>

              <input
                type="textbox"
                className="update-card-details-form-input"
                name="Measuredby"
                value={Measuredby}
                onChange={this.changeHandler}
              />
            </div>

            <></>
            <div className="update-card-details-form-content">
              <label>Notes</label>

              <textarea
                rows="2"
                name="MeasurementNotes"
                value={MeasurementNotes}
                onChange={this.changeHandler}
              />
            </div>

            <Button
              onClick={() => {
                this.setState({
                  measurement_reminder: !measurement_reminder,
                });
              }}
            >
              <AddAlertIcon />
              Set Reminder
            </Button>

            <>
              <>
                {measurement_reminder ? (
                  <>
                    {/* <div className="row ml-1 mr-1 mt-3 p-1 bg-warning">
                      <p className="ml-2 mb-0 font-weight-bold">Measurement</p>
                      <Button
                        className="p-0 measure-update-btn"
                        style={{ marginLeft: "80px" }}
                        onClick={() => {
                          this.setState({
                            measurement_reminder: !measurement_reminder,
                          });
                        }}
                      >
                        <ExpandMoreIcon />
                      </Button>
                    </div> */}

                    <div className="update-card-details-form-content">
                      <label>Reminder Title</label>

                      <input
                        type="textbox"
                        className="update-card-details-form-input"
                        name="MeasurementTitle"
                        value={MeasurementTitle}
                        onChange={this.changeHandler}
                      />
                    </div>

                    <div className="update-card-details-form-content">
                      <label>Next Appointment Date</label>

                      <input
                        type="date"
                        className="update-card-details-form-input"
                        name="MeasurementNext"
                        value={MeasurementNext}
                        onChange={this.changeHandler}
                      />
                    </div>

                    <div className="update-card-details-form-content">
                      <label>Remind Me</label>

                      <select
                        className="update-card-details-form-input"
                        name="MeasurementRemind_me"
                        value={this.state.MeasurementRemind_me}
                        onChange={this.changeHandler}
                      >
                        <option hidden></option>
                        <option value="On The Same Day">On The Same Day</option>
                        <option value="1 Day Before">1 Day Before</option>
                        <option value="2 Days Before">2 Days Before</option>
                        <option value="3 Days Before">3 Days Before</option>
                        <option value="15 Days Before">15 Days Before</option>
                        <option value="1 Month Before">1 Month Before</option>
                      </select>
                    </div>

                    <div className="update-card-details-form-content">
                      <label>Notes</label>

                      <textarea
                        row="2"
                        name="MeasurementReminderNotes"
                        value={MeasurementReminderNotes}
                        onChange={this.changeHandler}
                      />
                    </div>

                    <div className="update-card-details-uploadReport-container">
                      <label htmlFor="all">Done;</label>

                      <input
                        type="checkbox"
                        checked={MeasurementDone}
                        id="all"
                        name="MeasurementDone"
                        value={MeasurementDone}
                        onChange={this.measurementChangeHandler}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            </>

            <div className="update-card-details-uploadReport-container">
              <input type="file" onChange={this.handleChange} />

              <label style={{ color: "red" }}>{this.state.message}</label>

              {this.state.docs.length
                ? this.state.docs.map((rep) => (
                    <Tooltip title={rep.name}>
                      <a href={rep.location}>
                        <AiIcons.AiFillFileText />
                      </a>
                    </Tooltip>
                  ))
                : null}

              {Measurement_Reportlocation ? (
                <Tooltip title={Measurement_Reportname}>
                  <a href={Measurement_Reportlocation}>
                    <AiIcons.AiFillFileText />
                  </a>
                </Tooltip>
              ) : null}
            </div>
          </>

          <>
            <div className="update-card-details-btn-wrapper">
              <button
                className="update-card-details-btns"
                style={{ backgroundColor: "#ffc107", color: "white" }}
                onClick={this.submitHandler}
              >
                Submit
              </button>

              <button
                className="update-card-details-btns"
                style={{
                  color: "black",
                }}
                onClick={this.props.closeDialog}
              >
                Close
              </button>
            </div>
          </>
        </form>
      </div>
    );
  }
}
