import React from "react";
import Card from "@material-ui/core/Card";
import "./reportfound.component.css";
import axios from "axios";
import moment from "moment";
import {
  setIcon,
  setGeneric,
  DOLPHIN_PRODUCTS,
  SCAN_REASON,
} from "../../utils/setValues.js";
import { switchUrls } from "../../api/index.js";
import Footer from "../footer/footerblack.jsx";
import { Button } from "@material-ui/core";
// import call from "../../assets/call.png";
// import email from "../../assets/email.png";
// import whatsapp from "../../assets/whatsapp.png";
// import callback from "../../assets/callback.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Findermodal from "../scan-routing/Findermodal.jsx";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { IconButton, Snackbar } from "@material-ui/core";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
const baseURL = switchUrls("gateway");
const basebagURL = switchUrls("bag");
const basewalletURL = switchUrls("wallet");
const basegatewayURL = switchUrls("gateway");
const basekeyURL = switchUrls("key");
const basegenericURL = switchUrls("generic");
const baseearphoneURL = switchUrls("earphone");
const passportURL = switchUrls("passport");

// let test = getCords();
function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/*function whatsApp(cc, phone) {
  // let phoneCc = cc;
  let phoneCc = cc;
  let number = phone.split("").length > 10 ? phone.slice(1) : phone;

  return phoneCc.match(/\d+/)[0].concat(number);
}*/

// function telOne(cc, phone) {
//   // let phoneCc = cc;
//   let phoneCc = cc || "91";
//   let number = phone.split("").length > 10 ? phone.slice(1) : phone;

//   return phoneCc.match(/\d+/)[0].concat(number);
// }
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}
const BlurredBackground = ({ children }) => {
  return <div className="blurred-background">{children}</div>;
};
var usertoken = readCookie("token");

export default class FoundDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tag_number: this.props.tag_number,
      product_category: "",
      category: "",
      tag_status: "",
      phone_cc: "",
      valuable_type: "other",
      keychain_description: "",
      identification_mark: "",
      owner_phone: "",
      owner_phonelnf: "9029008248",
      owner_phonecode: "",
      owner_phonecodelnf: "+91",
      owner_emaillnf: "support@tag8.in",
      owner_phoneeuro: "9029008248",
      owner_phonecodeeuro: "+91",
      owner_emaileuro: "support@tag8.co.in",
      company_name: "",
      owner_email: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      curlatitude: "",
      curlongitude: "",
      address: "",
      comments: "",
      lnfSQLAlreadyActivatedTag: false,
      key_imagelocation: "",
      pet_imagelocation: "",
      other_noofkeys: "",
      beacon_name: "",
      identification_mark: "",
      vehicle_licensenumber: "",
      product_type: "",
      passport: "",
      placeofissue: "",
      valuable_desc: "",
      description: "",
      bag_color: "",
      bag_brand: "",
      bag_imagelocation: "",
      imagelocation: "",
      earphone_color: "",
      earphone_model: "",
      earphone_brand: "",
      earphone_imagelocation: "",
      generic_imagelocation:"",
      wallet_imagelocation:"",
      passport_imagelocation:"",
      latitude: "",
      longitude: "",
      errmsg: "",
      snackbaropen: false,
      scan: false,
      snackbarmsg: "",
      notfound: false,
      isBoxOpen: false,
      screenWidth: window.innerWidth,
      notactivated: false,
      showdiv: false,
      Alert: false,
      arrivalDate: null,
      departureDate: null,
      loading: true,
      token: false,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      foundermodal: false,
      drawer: false,
      showFooter: true,
      value: 0,
      tag8_support: false,
      user_support: false,
      checkedUserSupport: true,
      checkedTag8Support: false,
      //tata aig
      tataaig_email: "EA.tataclaims@europ-assistance.in",
      tataaig_phone: "+912268227600",
      deactive_timer: false,
      //medical tag
      medical_tag: false,
      medical_warning: false,
      display_name: null,
      reason : '',
      restrict_email : this.props.productcategory === "QR Vehicle" ? true : false,
      clear_timeout : false,
    };
    //tata aig
    this.fetchUserCountry = this.fetchUserCountry.bind(this);
    this.fetchUserCountryIP = this.fetchUserCountryIP.bind(this)
    this.getCoordinates = this.getCoordinates.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handelform = this.handelform.bind(this);
    this.handleScreen = this.handleScreen.bind(this);
    this.sendReason = this.sendReason.bind(this)
    this.sendVehiclescan = this.sendVehiclescan.bind(this)

    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handelreason = this.handelreason.bind(this);
    // this.convertCamelCaseToWords = this.convertCamelCaseToWords.bind(this);
    this.laptopScreenWidthThreshold = 900;
  }

  drawerRef = React.createRef();

  async sendVehiclescan (position){
    try{

       // Delay helper function
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Wait for 1.5 seconds
    await delay(15000);

        if(!this.state.clear_timeout){
           // Fetch country code asynchronously
       const country_code = await this.fetchUserCountryIP();
       axios
         .get(`${baseURL}/api/sendemail/` + this.state.tag_number, {
           params: {
             latitude: position.coords.latitude,
             longitude: position.coords.longitude,
             country_code: country_code,
           },
         })
         .then((res) => {
           // console.log(res.data, "restyy");
           this.setState({
             snackbaropen: true,
             snackbarmsg: res.data.message,
           });
         })
         .catch((error) => {
           // console.log(error.response.data);
           if (error?.response?.data?.message !== "Tag is canceled") {
             this.setState({
               snackbaropen: true,
               snackbarmsg: error?.response?.data?.message || "Failed",
             });
           }
         });
         
        }
        
      

    }
    catch(er){

    }
  }

  async fetchUserCountryIP() {
    try {
      const {data} = await axios.get("https://ipapi.co/json/")
      return data?.country_code || "IN"; // Return country code or default to "IN"
    } catch (er) {
      return "IN"
    }
  }

  async sendReason(){
    try {
      
      if(this.state?.product_category === "QR Vehicle" && this.state?.reason?.length){

      
      const { data } = await axios.post(
        `${baseURL}/api/vehicle/scanreason/` + this.state?.tag_number,
        { reason: this.state?.reason,latitude:this.state?.latitude,longitude:this.state?.longitude  }
      );

      
      
    }
    else{
      
    }
    return
      
    } catch (error) {
      
    }

  }

  async handelreason(e) {
    try {
  
      this.setState({reason : e?.target?.value})

      this.setState({clear_timeout : true})

      const { data } = await axios.post(
        `${baseURL}/api/vehicle/scanreason/` + this.state?.tag_number,
        { reason: e?.target?.value,latitude:this.state?.latitude,longitude:this.state?.longitude  }
      );

      this.setState({
        snackbaropen: true,
        snackbarmsg: data?.message || "Email Send"
      });
 
    } catch (error) {
      //console.log(error);
    }
  }

  async fetchUserCountry() {
    try {
      const {data} = await axios.get("https://ipapi.co/json/")

      if (data && data?.country_code) {
        const userCountryCode = data.country_code;
        this.setState({
          country_code: userCountryCode,
        });

        if (data.country_code === "CA" || data.country_code === "US") {
          this.setState({
            tataaig_email: "tata.aig@europ-assistance.in",
            tataaig_phone: "+18334401575",
          });
        }
      }
    } catch (er) {}
  }

  handleScreen() {
    const showFooter = window.innerWidth > 768;
    this.setState({
      drawer: false,
      showFooter,
    });
  }
  handelform() {
    this.setState({
      drawer: false,
      foundermodal: true,
    });
    
  }
  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleScreen);
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     this.getCoordinates,
    //     this.handleError
    //   );
    // if (this.getCoordinates) {
    //   console.log(position, "pos");
    //   window.location.reload("false");
    // }
    // } else {
    //   alert("Geolocation is not supported by this browser.");
    // }
    window.addEventListener("resize", this.handleResize);
    setTimeout(() => {
      this.setState({ Alert: false });
    }, 3000);
    if (usertoken) {
      this.setState({
        token: true,
      });
    }
    axios
      .get("/api/getTagNumber", {
        params: {
          tag: this.state.tag_number,
        },
      })
      .then((res) => {
        // console.log(res, "resss");
        var data = res.data.tagResult.product_category;
        // console.log(data, "resshhshh");
        // console.log(typeof data, "type of");
        if (data) {
          // console.log(data, "yes");
          // this.setState({ category: data }, () => {
          //   // console.log(this.state.category, "category");
          // });
          this.setState({ category: data }, () => {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                // console.log(position, this.state.category, "catgeoyrr");
                this.getCoordinates(position, this.state.category);
              },
              (error) => {
                // console.log(error);
                this.setState({ locationalert: true });
              }
            );
          });

          this.setState({
            company_name: res.data?.tagResult?.company_name,
            tag_status: res.data?.tagResult?.tag_status,
            comments: res.data?.tagResult?.comments,
            lnfSQLAlreadyActivatedTag:
              res.data?.tagResult?.lnfSQLAlreadyActivatedTag,
            tag8_support: res.data?.tagResult?.tag8_support || false,
            user_support: res.data?.tagResult?.user_support || false,
            loading: false,
          });
        } else {
          // console.log("no");
          this.setState({
            loading: false, // Set loading to false if there's an error
          });
        }
        // this.setState({ product_category: data }, () => {
        //   // Callback function executed after state is updated
        //   this.getCoordinates();
        // });
        // this.state.product_category(data);
        // setProductcategory(data);
        // showinitialletter(data);
      })
      .catch((error) => {});
    // console.log(this.state.category, "category");
    // axios
    //   .post("/api/checkTagNumberWithoutHeader", {
    //     tag_number: this.state.tag_number,
    //   })
    //   .then((res) => {
    //     if (res.data === "Tag not Found by the specified Number") {
    //       this.getLNFtagDetails(this.state.tag_number);
    //     } else if (res.data === "Tag Activated Successfully") {
    //       this.setState({ notactivated: true });
    //     } else if (res.data.msg === "Tag is already activated") {
    //       this.setState({ scan: true });

    //       localStorage.setItem("Scan", this.state.scan);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error.message);
    //   });

    this.listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      var genieNav = document.querySelector("#genie-nav");

      if (genieNav) {
        if (scrolled >= 20) {
          genieNav.style.backgroundColor = "white";
        } else {
          genieNav.style.backgroundColor = "transparent";
        }
      }
    });

    // axios
    //   .get("http://localhost:3004/api/getUserProfile", {
    //     headers: {
    //       Authorization:usertoken,
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     this.setState({
    //       phone_cc: res.data.phonecountrycode,
    //     });
    //   })
    //   .catch((error) => {
    //     console.error(error.message);
    //   });
    axios
      .get(`${basegatewayURL}/api/genericreportfound/` + this.props.tag_number)
      .then((response) => {
        // if (response.data.details) {

        //   var phonenumber = response.data.details.owner_phone;
        //   var firstname = response.data.details.owner_firstname;
        //   var lastname = response.data.details.owner_lastname;
        //   var email = response.data.details.owner_email;
        //   var phonecode = response.data.details.owner_phonecode;
        // } else {

        //   var phonenumber = response.data.owner_phone
        //     ? response.data.owner_phone
        //     : response.data.phone;
        //   var fullname = response.data.fullName;
        //   var firstname = response.data.owner_firstname;
        //   var lastname = response.data.owner_lastname;
        //   var email = response.data.owner_email;
        //   var phonecode = response.data.owner_phonecode;
        // }
        // var mobilenumber = response.data.details
        //   ? response.data.details.owner_phone
        //   : response.data.owner_phone;
        // console.log(phonenumber, "phone");
        // if (phonenumber.startsWith(0)) {
        //   var phone = phonenumber.substr(1);
        // } else {
        //   var phone = phonenumber;
        // }
        // const selectedImageLocation =
        //   response.data.key_imagelocation ||
        //   response.data.pet_imagelocation ||
        //   response.data.bag_imagelocation ||
        //   response.data.earphone_imagelocation;
        // if (response.data.owner_phone.startsWith(0)) {
        //   var mobile = response.data.owner_phone.substr(1);
        // } else {

        //   var mobile = response.data.owner_phone;

        // }

        // owner_phone;

        if (response.data) {
          if (response.data.details) {
            var phonenumber = response.data.details.owner_phone;
            var firstname = response.data.details.owner_firstname;
            var lastname = response.data.details.owner_lastname;
            var email = response.data.details.owner_email;
            var phonecode = response.data.details.owner_phonecode;
          } else {
            var phonenumber = response.data.owner_phone
              ? response.data.owner_phone
              : response.data.phone;
            var fullname = response.data.fullName;
            var firstname = response.data.owner_firstname;
            var lastname = response.data.owner_lastname;
            var email = response.data.owner_email;
            var phonecode = response.data.owner_phonecode;
          }

          if (phonenumber.startsWith(0)) {
            var phone = phonenumber.substr(1);
          } else {
            var phone = phonenumber;
          }

          const selectedImageLocation =
            response.data?.key_imagelocation ||
            response.data?.pet_imagelocation ||
            response.data?.bag_imagelocation ||
            response.data?.wallet_imagelocation ||
            response.data?.earphone_imagelocation ||
            response.data?.generic_imagelocation ||
            response.data?.passport_imagelocation ||
            response.data?.vehicle_imagelocation;
          if (
            response?.data?.certificateEndDate &&
            (new Date(response?.data?.certificateEndDate)?.getDate() ===
              new Date()?.getDate() ||
              new Date()?.getDate() ===
                new Date(response?.data?.certificateEndDate)?.getDate() + 1)
          ) {
            this.setState({
              deactive_timer: true,
            });
          }

          this.setState({
            owner_firstname: firstname,
            owner_lastname: lastname,
            owner_phone: phone.replace(/[^0-9]/g, ""),
            owner_email: email,
            owner_phonecode: phonecode,
            product_type: response.data.product_type,
            product_category: response.data.product_category,
            lost_date: response.data.lost_date,
            rewardcurrency: response.data.rewardcurrency,
            reward: response.data.reward,
            imagelocation: selectedImageLocation,
            // tag_status: tagstatus,
            fullname: fullname,
            valuable_type: DOLPHIN_PRODUCTS.includes(
              response.data.product_category
            )
              ? "DOLPHIN"
              : response.data.valuable_type,
            bag_brand: response.data.bag_brand,
            description: response.data.description,
            arrivalDate: response?.data?.certificateEndDate?.split("T")[0],
            departureDate: response?.data?.certificateStartDate?.split("T")[0],
            //medical tag
            medical_tag: response?.data?.medical_tag,
            display_name: response?.data?.display_name,
          
          });
        } else {
        }
      })
      .catch((error) => {
        console.error(error.message);
      });

    // Set up the interval to update the countdown of Deativate
    this.timerId = setInterval(() => {
      this.updateCountdownDeactivate();
    }, 1000);

    const divSelted = document.getElementsByClassName("mat-card");
    Array.from(divSelted).forEach((element) => {
      element.classList.add("mt-0");
    });
  }
  handleLoginClick = () => {
    if (usertoken) {
      const uid = localStorage.getItem("uid");
      const dashboardRoute = "/dashboard/" + uid;
      window.location.href = dashboardRoute;
    } else {
      window.location.href = `${baseURL}/#/login`;
    }
  };
  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getLNFtagDetails(tag_number) {
    axios
      .get(`/api/getAllLFtagsById/${tag_number}`, {})
      .then((res) => {
        if (res.data.data.length === 0) {
          //this.setState({ notfound: true });
          //Move to the tag Not Found Component
          window.location.href = "/#/tags-not-found";

          //Check if the Tag is L&F Tag
        } else if (res.data.data.length > 0) {
          // this.setState({ notactivated: true });

          this.setState({
            owner_firstname: "tag8 Support",
            owner_lastname: "tag8 Support",
            owner_phone: "9029008248",
            owner_email: "support@tag8.in",
            owner_phonecode: "+91",
            product_type: res.data.data[0].product_category,
            product_category: res.data.data[0].product_category,
            valuable_type: res.data.data[0].product_category,
            tag_status: "lost",
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  componentDidUpdate(prevstate, prevprops) {
    // this.setState({
    //   Alert: true,
    // });
    // if (prevprops.Alert === false && this.state.Alert === true) {
    //   this.handleError(null, prevstate, prevprops);
    // }
    var alertmessage = prevprops.Alert;
    // if (prevprops.Alert !== this.state.Alert) {
    //   this.handleError(prevstate, prevprops, prevprops.Alert);
    // }
    // this.handleError(null, prevstate, prevprops);
  }
  handleError(error) {
    var tagnumber = this.state.tag_number.toUpperCase().substring(0, 3);
    // const { prevState, prevProps } = this.state;
    // console.log(this.state, "handleerrr");
    // setTimeout(function () {
    //   alert("Please Turned On Your GPS location");
    // }, 1000);
    // alert("Please Turned On Your GPS location");

    // window.location.href = "/#/scan/" + this.state.tag_number;
    // console.log(this.state.sendmail, "send");
    // console.log(alertmessage, "handlerroro");
    if (tagnumber == "TGU") {
      axios
        .get(
          `${baseearphoneURL}/api/earphone/urlearphonereportfound/` +
            this.state.tag_number,
          {
            params: {
              errmsg: error.message,
            },
          }
        )
        .then((res) => {
          // console.info(res.data, "res");
          const word = res.data.slice(0, 12);
          // console.log(word, "word");
          if (word === "Mail sent on") {
            // console.log(res, "true");
            this.setState({ Alert: true });
            setTimeout(() => {
              window.location.reload(false);
            }, 10000);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    if (tagnumber == "TGU") {
      axios
        .get(
          `${baseearphoneURL}/api/earphone/urlearphonereportfound/` +
            this.state.tag_number,
          {
            params: {
              errmsg: error.message,
            },
          }
        )
        .then((res) => {
          // console.info(res.data, "res");
          const word = res.data.slice(0, 12);
          // console.log(word, "word");
          if (word === "Mail sent on") {
            // console.log(res, "true");
            this.setState({ Alert: true });
            setTimeout(() => {
              window.location.reload(false);
            }, 10000);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    // if (tagnumber == "PET" || tagnumber == "TGZ") {
    //   axios
    //     .get(`${baseURL}/api/pet/getreportfound/` + this.state.tag_number, {
    //       params: {
    //         errmsg: error.message,
    //       },
    //     })
    //     .then((res) => {
    //       const word = res.data.slice(0, 12);
    //       // console.log(word, "word");
    //       if (word === "Mail sent on") {
    //         // console.log(res, "true");
    //         this.setState({ Alert: true });
    //         setTimeout(() => {
    //           window.location.reload(false);
    //         }, 10000);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error.message);
    //     });
    // }
    if (tagnumber == "GTB" || tagnumber == "CLB") {
      axios
        .get(
          `${basebagURL}/api/bag/urlbagreportfound/` + this.state.tag_number,
          {
            params: {
              errmsg: error.message,
            },
          }
        )
        .then((res) => {
          // console.info(res, "res");
          const word = res.data.slice(0, 12);
          // console.log(word, "word");
          if (word === "Mail sent on") {
            // console.log(res, "true");
            this.setState({ Alert: true });
            setTimeout(() => {
              window.location.reload(false);
            }, 10000);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    if (tagnumber == "TWW") {
      axios
        .get(
          `${basewalletURL}/api/wallet/urlwalletreportfound/` +
            this.state.tag_number,
          {
            params: {
              errmsg: error.message,
            },
          }
        )
        .then((res) => {
          console.info(res, "res");
          const word = res.data.slice(0, 12);
          // console.log(word, "word");
          if (word === "Mail sent on") {
            // console.log(res, "true");
            this.setState({ Alert: true });
            setTimeout(() => {
              window.location.reload(false);
            }, 10000);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    if (tagnumber == "TGY") {
      axios
        .get(
          `${basekeyURL}/api/key/urlkeyreportfound/` + this.state.tag_number,
          {
            params: {
              errmsg: error.message,
            },
          }
        )
        .then((res) => {
          console.info(res, "res");
          const word = res.data.slice(0, 12);
          // console.log(word, "word");
          if (word === "Mail sent on") {
            // console.log(res, "true");
            this.setState({ Alert: true });
            setTimeout(() => {
              window.location.reload(false);
            }, 10000);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    if (tagnumber == "GEN") {
      axios
        .get(
          `${basegenericURL}/api/generic/urlgenericreportfound/` +
            this.state.tag_number,
          {
            params: {
              errmsg: error.message,
            },
          }
        )
        .then((res) => {
          console.info(res, "res");
          const word = res.data.slice(0, 12);
          // console.log(word, "word");
          if (word === "Mail sent on") {
            // console.log(res, "true");
            this.setState({ Alert: true });
            setTimeout(() => {
              window.location.reload(false);
            }, 10000);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    if (tagnumber == "TGR") {
      axios
        .get(
          `${passportURL}/api/passport/urlpassportreportfound/` +
            this.state.tag_number,
          {
            params: {
              errmsg: error.message,
            },
          }
        )
        .then((res) => {
          console.info(res, "res");
          const word = res.data.slice(0, 12);
          // console.log(word, "word");
          if (word === "Mail sent on") {
            // console.log(res, "true");
            this.setState({ Alert: true });
            setTimeout(() => {
              window.location.reload(false);
            }, 10000);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }
  async getCoordinates(position, category) {
    var tagnumber = this.state.tag_number.toUpperCase().substring(0, 3);
    // console.log(category, "category");
    // if (position.coords.latitude !== "" && position.coords.longitude !== "") {
    //   if (tagnumber == "PET" || tagnumber == "TGZ") {
    //     axios
    //       .get(`${baseURL}/api/pet/getreportfound/` + this.state.tag_number, {
    //         params: {
    //           latitude: position.coords.latitude,
    //           longitude: position.coords.longitude,
    //         },
    //       })
    //       .then((response) => {
    //         console.log(response);
    //       })
    //       .catch((error) => {
    //         console.log(error.message);

    //         this.setState({
    //           snackbaropen: true,
    //           snackbarmsg: "Location could not be added",
    //         });
    //       });
    //   }
    // }
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
    if (position.coords.latitude !== "" && position.coords.longitude !== "") {
      // console.log(this.state.latitude, "latitude");
      // console.log(this.state.longitude, "longitude");
      axios
        .post(`${baseURL}/api/ScanLocation/` + this.state.tag_number, {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
        .then((response) => {
          if (response.data) {
            //location is added
          } else {
            this.setState({
              snackbaropen: true,
              snackbarmsg: "Could not find location",
            });
          }
        })
        .catch((error) => {
          // console.error(error.message);

          this.setState({
            snackbaropen: true,
            snackbarmsg: "Location could not be added",
          });
        });
    }
    if (position.coords.latitude !== "" && position.coords.longitude !== "" && !this.state?.restrict_email) {
      
       // Fetch country code asynchronously
       const country_code = await this.fetchUserCountryIP();
      axios
        .get(`${baseURL}/api/sendemail/` + this.state.tag_number, {
          params: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            country_code: country_code,
          },
        })
        .then((res) => {
          // console.log(res.data, "restyy");
          this.setState({
            snackbaropen: true,
            snackbarmsg: res.data.message,
          });
        })
        .catch((error) => {
          // console.log(error.response.data);
          if (error?.response?.data?.message !== "Tag is canceled") {
            this.setState({
              snackbaropen: true,
              snackbarmsg: error?.response?.data?.message || "Failed",
            });
          }
        });
        
        
    }

    if(this.state?.restrict_email && position.coords.latitude !== "" && position.coords.longitude !== "" ){
      this.sendVehiclescan(position)
    }
  }

  showOptions() {
    // console.log(this.state.showdiv, "showidopton");

    this.setState((prevState) => ({ showdiv: !prevState.showdiv }));
    if (this.state.showdiv) {
      // console.log(this.state.showdiv, "showid");
      // document.getElementById("bdiv").style.bottom = "-15.5rem";
      document.getElementById("aup").style.display = "block";
      document.getElementById("adown").style.display = "none";
      // document.getElementById("blurBackground").classList.add("show");
      this.setState({ showdiv: false });
    } else {
      // console.log(this.state.showdiv, "showidselse");
      // document.getElementById("bdiv").style.bottom = "0";
      document.getElementById("aup").style.display = "none";
      document.getElementById("adown").style.display = "block";
      // document.getElementById("blurBackground").classList.remove("show");
      this.setState({ showdiv: true });
    }
  }
  // toggleBox = () => {
  //   this.setState((prevState) => ({
  //     isBoxOpen: !prevState.isBoxOpen,
  //   }));
  // };
  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };
  handleSnackbarClose = () => {
    this.setState({ snackbaropen: false });
  };
  convertCamelCaseToWords = (str) => {
    if (!str) return "";
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before capital letters
      .replace(
        /\b(\w+\b)/g,
        (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()
      ); // Capitalize each word
  };
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
      checkedUserSupport: newValue === 0,
      checkedTag8Support: newValue === 1,
    });
  };
  updateCountdownDeactivate = () => {
    const { arrivalDate } = this.state;
    // console.log(arrivalDate, "date of arrival");
    const formatedDate = arrivalDate?.split("T")[0];
    const arrivalNextDay = new Date(formatedDate);
    if (
      arrivalNextDay.getUTCHours() === 0 &&
      arrivalNextDay.getUTCMinutes() === 0
    ) {
      arrivalNextDay.setHours(arrivalNextDay.getHours() - 5);
      arrivalNextDay.setMinutes(arrivalNextDay.getMinutes() - 30);
    }

    arrivalNextDay.setDate(arrivalNextDay.getDate() + 2);
    const y = arrivalNextDay.getFullYear();
    const m = arrivalNextDay.getMonth() + 1;
    const dd = arrivalNextDay.getDate();
    const timerEndDate = `${y}-${m < 10 ? `0${m}` : m}-${
      dd < 10 ? `0${dd}` : dd
    }`;
    const targetMoment = moment(timerEndDate);
    const now = moment();
    const difference = targetMoment.diff(now);
    const duration = moment.duration(difference);

    if (difference < 0)
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    this.setState({
      days: days.toString().padStart(2, "0"),
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    });
  };

  render() {
    const {
      tag_number,
      owner_firstname,
      tag8_support,
      user_support,
      checkedUserSupport,
      checkedTag8Support,
      product_category,
      category,
      owner_lastname,
      phone_cc,
      comments,
      lnfSQLAlreadyActivatedTag,
      snackbaropen,
      snackbarmsg,
      keychain_description,
      other_noofkeys,
      owner_phone,
      tag_status,
      owner_email,
      owner_phonecode,
      owner_emaillnf,
      owner_phonecodelnf,
      owner_phonelnf,
      valuable_type,
      company_name,
      fullname,
      value,
      owner_phoneeuro,
      owner_phonecodeeuro,
      owner_emaileuro,
      valuable_desc,
      address,
      beacon_name,
      reward,
      rewardcurrency,
      identification_mark,
      lost_date,
      lost_text,
      vehicle_licensenumber,
      chasis_number,
      product_type,
      sendmail,
      passport,
      placeofissue,
      owner_altemail,
      owner_altphonecode,
      owner_altphone,
      lost_timeone,
      lost_timetwo,
      description,
      bag_color,
      bag_brand,
      longitude,
      latitude,
      earphone_brand,
      earphone_color,
      earphone_model,
      curlatitude,
      curlongitude,
      errmsg,
      Alert,
      screenWidth,
      imagelocation,
      isBoxOpen,
      token,
      days,
      hours,
      minutes,
      seconds,
      foundermodal,
      drawer,
      showFooter,
      tataaig_email,
      tataaig_phone,
      medical_tag,
      medical_warning,
      deactive_timer,
      display_name,
      reason
    } = this.state;

    // console.log(Alert, "sends");
    // console.log(tag_status, "tag status");
    // console.log(company_name, "name of company");
    // console.log("hou: ", hours);
    // console.log("days", days);
    const isLaptopScreen = screenWidth > this.laptopScreenWidthThreshold;
    const tagInitial = tag_number.toUpperCase().substring(0, 3);
    // const type = product_category ? product_category : product_type;
    // console.log(product_type, "TYpeehhh");
    const cleanedValuableType = valuable_type
      ? valuable_type.replace(/\s+/g, "")
      : product_type;
    // console.log(product_type, "tyepe");
    const type = cleanedValuableType ? cleanedValuableType : product_type;
    // console.log(type, "type");
    const image =
      tagInitial === "GEN"
        ? setGeneric(cleanedValuableType, tag_number)
        : setIcon(type, tag_number);
    // const formattedValuableType = cleanedValuableType
    //   ? this.convertCamelCaseToWords(cleanedValuableType)
    //   : "";
    // console.log("..." + product_category);
    const formattedProductCategory = product_category
      ? this.convertCamelCaseToWords(product_category.replace(/^QR /, ""))
      : "";
    let contact_info = {
      phone: "",
      email: "",
    };

    if (checkedUserSupport) {
      contact_info = {
        phone: `${owner_phonecode}${owner_phone}`,
        email: owner_email,
      };
    } else if (checkedTag8Support) {
      contact_info = {
        phone: `${owner_phonecodelnf}${owner_phonelnf}`,
        email: owner_emaillnf,
      };
    }
    // console.log(contact_info, "phone");
    // console.log(owner_phonecode, "phonecode");
    // const valuableName = setValuableName(valuable_type,product_type);
    // console.log(valuable_type,product_type,"valuabename")
    // const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    return (
      <>
        {this.state.loading ? (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        ) : (
          <div>
            {foundermodal ? (
              <Findermodal
                findermodal={foundermodal}
                setfindermodal={(value) =>
                  this.setState({ foundermodal: value })
                }
                tagnumber={tag_number}
                image={imagelocation ? imagelocation : image}
              />
            ) : null}
            {company_name === "Tata_aig" ? (
              <>
                {tag_status === "deactive" ? (
                  <>
                    <div
                      className="tata-deactive-main"
                      style={{ marginBottom: "5rem" }}
                    >
                      <div
                        className="tata-deatcive-upper"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          marginTop: "3rem",
                        }}
                      >
                        <div
                          style={{
                            margin: "1rem",
                            width: "50%",
                            textAlign: "center",
                          }}
                          className="tata-deactive-logo"
                        >
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qr/tag8logoblack.png"
                            }
                            alt="tag8 logo"
                            width="auto"
                            height="140px"
                            loading="lazy"
                          />
                        </div>
                        <div
                          style={{ margin: "1rem" }}
                          className="tata-deactive-pasportlogo"
                        >
                          <img
                            alt="b"
                            src="https://storage.googleapis.com/pettag/qr/assets/passport.png"
                          ></img>
                        </div>
                        <div className="tata-deactive-tagnumber">
                          <p style={{ marginBottom: "0.2rem" }}>
                            <img
                              alt="qr"
                              src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                              style={{ width: "30px", height: "30px" }}
                            />
                            {tag_number || "NA"}
                          </p>
                        </div>
                        <div className="tata-deactive-heading">
                          <h1>Your Passport tag has been Deactivated</h1>
                        </div>
                        <div className="tata-deactive-details">
                          <p>
                            Your safe arrival is great news. The journey's done,
                            but memories will last a lifetime.
                          </p>
                          <p style={{ marginTop: "1rem" }}>
                            {" "}
                            Wishing you many more smooth journeys ahead.
                          </p>
                        </div>
                      </div>

                      <div className="extrabottomdiv"></div>
                    </div>
                  </>
                ) : null}
                {tag_status === "active" ? (
                  <>
                    <div className="tata-active-main">
                      <div className="tata-nav">
                        <img
                          src={
                            "https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
                          }
                          alt="logo"
                          style={{ width: "5rem" }}
                        />
                      </div>
                      <div className="tata-active-upper">
                        <div className="tata-active-heading">
                          <h1>Thank You For Finding Me</h1>
                        </div>
                        <div className="tata-active-passportlogi">
                          <img
                            alt="b"
                            src="https://storage.googleapis.com/pettag/qr/assets/passport.png"
                          ></img>
                        </div>
                        <div className="tata-active-tagnumber">
                          <p>
                            <img
                              alt="qr"
                              src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                              style={{ width: "30px", height: "30px" }}
                            />
                            {tag_number || ""}
                          </p>
                        </div>
                        <div className="tata-active-subheading">
                          <h1>
                            This Passport belongs to{" "}
                            <strong>{fullname || ""}</strong>
                          </h1>
                        </div>

                        {deactive_timer && (
                          <>
                            <div className="tata-active-details">
                              <p>
                                Please note as per your travel insurance policy
                                issued your Passport tag will be deactivate in
                                24 hours after your arrival.
                              </p>
                            </div>
                            <div className="timer-div">
                              <span className="timer-day-div ">
                                <span className="timer-value">{days}</span>
                                <span className="timer-info">DAYS</span>
                              </span>

                              <span className="timer-dot-div">
                                <span className="timer-dot-div-1"></span>
                                <span className="timer-dot-div-2"></span>
                              </span>

                              <span className="timer-hrs-div">
                                <span className="timer-value">{hours}</span>
                                <span className="timer-info">HRS</span>
                              </span>

                              <span className="timer-dot-div">
                                <span className="timer-dot-div-1"></span>
                                <span className="timer-dot-div-2"></span>
                              </span>

                              <span className="timer-hrs-div">
                                <span className="timer-value">{minutes}</span>
                                <span className="timer-info">MINS</span>
                              </span>

                              <span className="timer-dot-div">
                                <span className="timer-dot-div-1"></span>
                                <span className="timer-dot-div-2"></span>
                              </span>

                              <span className="timer-hrs-div">
                                <span className="timer-value">{seconds}</span>
                                <span className="timer-info">SECS</span>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="extrabottomdiv"></div>
                      <div
                        className="footerof-tata"
                        style={{ marginBottom: "2rem" }}
                      >
                        <div
                          className="drawer  pet-bottom-div tata-footer"
                          onClick={() => {
                            this.setState({ drawer: !drawer });
                          }}
                        >
                          <div className="drawerarrow">
                            {!drawer ? (
                              <i
                                className="fa fa-angle-double-up drawerarrow"
                                onClick={() =>
                                  this.setState({ drawer: !drawer })
                                }
                              ></i>
                            ) : (
                              <i
                                className="fa fa-angle-double-down drawerarrow"
                                onClick={() =>
                                  this.setState({ drawer: !drawer })
                                }
                              ></i>
                            )}
                          </div>

                          <p
                            className="returnreward-text"
                            style={{
                              fontSize: "23px",
                              fontFamily: "prompt,sans-serif",
                            }}
                          >
                            Contact Owner
                          </p>

                          <div className="guardianinfo">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                className="contactdivlg"
                                style={{ marginLeft: "2rem" }}
                              >
                                <>
                                  <div
                                    style={{
                                      marginRight: "1rem",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <a
                                      href={`tel:${tataaig_phone}`}
                                      style={{ color: "black" }}
                                    >
                                      <p>
                                        <span
                                          className="conatct-div-block"
                                          style={{ display: "inline-block" }}
                                        >
                                          <img
                                            alt="call"
                                            className="reportfound-vector"
                                            src={
                                              "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                            }
                                          />
                                          {"  "}
                                          Call
                                        </span>
                                      </p>
                                    </a>
                                  </div>
                                </>
                              </div>

                              <div
                                className="contactdivlg"
                                style={{ marginLeft: "2rem" }}
                              >
                                <>
                                  <div
                                    style={{
                                      marginRight: "1rem",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <a
                                      href={`mailto:${tataaig_email}`}
                                      style={{ color: "black" }}
                                    >
                                      <p>
                                        <div
                                          className="conatct-div-block"
                                          style={{ display: "inline-block" }}
                                        >
                                          <img
                                            alt="email"
                                            className="reportfound-vector"
                                            src={
                                              "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                            }
                                          />{" "}
                                          Email
                                        </div>
                                      </p>
                                    </a>
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>

                          <SwipeableDrawer
                            className="swipabledrawer"
                            anchor="bottom"
                            open={drawer}
                            onClose={() => this.setState({ drawer: !drawer })}
                            onOpen={() => this.setState({ drawer: !drawer })}
                            disableSwipeToOpen={false}
                            ModalProps={{
                              keepMounted: true,
                            }}
                          >
                            <div
                              className="reportfound-pet-bottom-inner-div guardianmobile"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <i
                                  className="fa fa-angle-double-down drawerarrow"
                                  onClick={() =>
                                    this.setState({ drawer: !drawer })
                                  }
                                ></i>
                              </div>
                              <div>
                                <div style={{ textAlign: "center" }}>
                                  <p
                                    style={{
                                      fontSize: "20px",
                                      fontFamily: "prompt,sans-serif",
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Contact Owner
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <div style={{ marginRight: "1rem" }}>
                                    <a
                                      href={`tel:${tataaig_phone}`}
                                      style={{ color: "black" }}
                                    >
                                      <p className="pabr footer-font flex-between">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          <div style={{ marginBottom: "5px" }}>
                                            <img
                                              alt="call"
                                              className="reportfound-vector"
                                              src="https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                            />
                                          </div>
                                          <div>
                                            <span>Call</span>
                                          </div>
                                        </div>
                                      </p>
                                    </a>
                                  </div>

                                  <div>
                                    <a
                                      href={`mailto:${tataaig_email}`}
                                      style={{ color: "black" }}
                                    >
                                      <p className="flex-between footer-font text-center pabr">
                                        <div
                                          style={{ display: "inline-block" }}
                                        >
                                          <div style={{ marginBottom: "5px" }}>
                                            <img
                                              alt="email"
                                              className="reportfound-vector"
                                              src={
                                                "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                              }
                                            />
                                          </div>
                                          <div>
                                            <span> Email</span>
                                          </div>
                                        </div>
                                      </p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Footer />
                          </SwipeableDrawer>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {tag_status === "cancel" ? (
                  <>
                    <div className="tata-active-main">
                      <div className="tata-nav">
                        <img
                          src={
                            "https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
                          }
                          alt="logo"
                          style={{ width: "5rem" }}
                        />
                      </div>
                      <div className="tata-active-upper">
                        <div className="tata-active-heading">
                          <h1>Thank You For Finding Me</h1>
                        </div>
                        <div className="tata-active-passportlogi">
                          <img
                            alt="b"
                            src="https://storage.googleapis.com/pettag/qr/assets/passport.png"
                          ></img>
                        </div>
                        <div className="tata-active-tagnumber">
                          <p>
                            <img
                              alt="qr"
                              src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                              style={{ width: "30px", height: "30px" }}
                            />
                            {tag_number || ""}
                          </p>
                        </div>
                        <div className="tata-active-subheading">
                          <h3>
                            Passport Security Tag Deactivated due to policy
                            cancellation
                          </h3>
                        </div>
                      </div>
                      <div className="extrabottomdiv"></div>
                      <div
                        className="footerof-tata"
                        style={{ marginBottom: "2rem" }}
                      >
                        <div
                          className="drawer cancel-policy-drawer pet-bottom-div tata-footer"
                          onClick={() => {
                            this.setState({ drawer: !drawer });
                          }}
                        >
                          <div className="drawerarrow">
                            {!drawer ? (
                              <i
                                className="fa fa-angle-double-up drawerarrow"
                                onClick={() =>
                                  this.setState({ drawer: !drawer })
                                }
                              ></i>
                            ) : (
                              <i
                                className="fa fa-angle-double-down drawerarrow"
                                onClick={() =>
                                  this.setState({ drawer: !drawer })
                                }
                              ></i>
                            )}
                          </div>

                          <p
                            className="returnreward-text"
                            style={{
                              fontSize: "25px",
                              fontFamily: "prompt,sans-serif",
                            }}
                          >
                            Contact Us
                          </p>

                          <div className="guardianinfo">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div className="contactdivlg">
                                <>
                                  <a
                                    href={`https://home.tag8.in/`}
                                    target="_blank"
                                    style={{ color: "black" }}
                                  >
                                    <p>
                                      <span
                                        className="conatct-div-block"
                                        style={{
                                          display: "inline-block",
                                          border: "0.5px solid gray",
                                          borderRadius: "7px",
                                          padding: "7px",
                                        }}
                                      >
                                        {"  "}
                                        Purchase Options
                                      </span>
                                    </p>
                                  </a>
                                </>
                              </div>
                            </div>
                          </div>

                          <SwipeableDrawer
                            className="swipabledrawer"
                            anchor="bottom"
                            open={drawer}
                            onClose={() => this.setState({ drawer: !drawer })}
                            onOpen={() => this.setState({ drawer: !drawer })}
                            disableSwipeToOpen={false}
                            ModalProps={{
                              keepMounted: true,
                            }}
                          >
                            <div
                              className="reportfound-pet-bottom-inner-div guardianmobile"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <i
                                  className="fa fa-angle-double-down drawerarrow"
                                  onClick={() =>
                                    this.setState({ drawer: !drawer })
                                  }
                                ></i>
                              </div>
                              <div>
                                <div style={{ textAlign: "center" }}>
                                  <p
                                    style={{
                                      fontSize: "20px",
                                      fontFamily: "prompt,sans-serif",
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Contact Us
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <div>
                                    <a
                                      href={`https://home.tag8.in/`}
                                      target="_blank"
                                      style={{ color: "black" }}
                                    >
                                      <p className="pabr flex-between">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          <div
                                            style={{ marginBottom: "5px" }}
                                          ></div>
                                          <div>
                                            <span>Purchase Options</span>
                                          </div>
                                        </div>
                                      </p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Footer />
                          </SwipeableDrawer>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <div></div>
              </>
            ) : (
              <div>
                <div
                  className="genie-nav"
                  id="genie-nav"
                  style={{
                    borderBottom: "1px solid #ddd",
                    paddingBottom: "5px",
                  }}
                >
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
                    }
                    alt="logo"
                    style={{ width: "5rem" }}
                  />
                  <div className="genie-nav-button-container">
                    <button
                      className="genie-login-button"
                      onClick={this.handleLoginClick}
                    >
                      {token ? "Dashboard" : "LOG IN"}
                    </button>
                  </div>
                </div>
                <div className="container">
                  <div id="contentToConvert" className="pb-3">
                    <Card className="mat-card">
                      <div className="lost-info-detail-header row  text-center">
                        <p className="header-font text-font ">
                          {
                            product_category === "QR Vehicle" ? "Contact Vehicle Owner" : "Thank You for Finding Me"
                          }
                          
                        </p>
                      </div>
                      <div className="custom-container">
                        <div>
                          <img
                            src={imagelocation ? imagelocation : image}
                            alt="valuable"
                            className={
                              imagelocation
                                ? "imagelocation-image"
                                : "default-image"
                            }
                          />
                        </div>
                        <div>
                          <p style={{ marginTop: "0.5rem" }}>
                            <img
                              src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                              style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "0.5rem",
                              }}
                              alt="qr"
                            />
                            <b>
                              {tag_number && tag_number !== "-"
                                ? tag_number
                                : "N/A"}
                            </b>
                          </p>
                        </div>
                        <div className="colorw container-for-rows"></div>
                      </div>
                      <div>
                        {medical_tag && (
                          <div className="medical_detials">
                            <div className="lg_screen_medical">
                              {valuable_type === "Diabetes Supplies" ? (
                                <p
                                  style={{
                                    textAlign: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  The Air Carrier Access Act (ACAA) and DOT
                                  rules prohibit discriminatory treatment of
                                  persons with disabilities in air
                                  transportation. The limit of one carry on bag
                                  and one personal bag does not apply to medical
                                  supplies and/or medical equipment. Passengers
                                  with disobilities generally may carry medical
                                  supplies, equipment, medications and assistive
                                  devices on board the aircraft. Medical
                                  supplies must conform to airlines carry on
                                  dimensions. VIOLATORS OF DOT RULES OR THE ACAA
                                  MAY BE SUBJECT TO SIGNIFICANT FINES This bag
                                  contains medically required supplies and must
                                  be in the possession of the prescribed owner
                                  at all times.
                                </p>
                              ) : (
                                <p style={{ textAlign: "center" }}>
                                  This {valuable_type} is a medical necessity
                                  and should be kept with the patient at all
                                  times during travel. As defined in USDOT (Dept
                                  of Transportation) 14 CFR Part 382, this
                                  equipment should not be stored away from the
                                  passenger and will not count toward the limit
                                  on carry-on items. TSA (Transportation
                                  Security Admin) rules state that this
                                  equipment may be inspected at all checkpoints
                                  but should not be separated from the
                                  passenger.
                                </p>
                              )}
                            </div>
                            <div className="mobile_screen_medical">
                              <Button
                                onClick={() => {
                                  this.setState({
                                    medical_warning: true,
                                  });
                                }}
                                variant="outlined"
                                color="error"
                                startIcon={<PriorityHighIcon />}
                              >
                                Attention
                              </Button>
                            </div>
                          </div>
                        )}
                        {comments === "lnfsqldb" ||
                        lnfSQLAlreadyActivatedTag ? (
                          <div className="reportfound-message-content">
                            <span
                              style={{
                                fontFamily: "prompt, sans-serif",
                                fontSize: "1.3rem",
                                lineHeight: "1.8rem",
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              <p>
                                <span className="reportfound-message-header">
                                  Please contact tag8 Support to learn more
                                  about the opportunity to win a reward.
                                </span>
                              </p>
                            </span>
                          </div>
                        ) : (
                          <div className="reportfound-message-content">
                            <span
                              style={{
                                fontFamily: "prompt",
                                fontWeight: "normal",
                                fontSize: "1.3rem",
                                lineHeight: "1.8rem",
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              This{" "}
                              <b
                                style={{
                                  fontFamily: "prompt",
                                  color: "black",
                                }}
                              >
                                {formattedProductCategory}
                              </b>
                              {"  "}
                              belongs to
                              <b
                                style={{
                                  fontFamily: "prompt",
                                  color: "black",
                                }}
                              >
                                {" "}
                                {display_name
                                  ? display_name
                                  : owner_firstname && owner_firstname !== "-"
                                  ? owner_firstname
                                  : null}{" "}
                                {display_name
                                  ? null
                                  : owner_lastname && owner_lastname !== "-"
                                  ? owner_lastname
                                  : null}
                              </b>
                            </span>
                          </div>
                        )}
                      </div>
                      {
                        product_category === "QR Vehicle" &&
                        <div className="vehicle_div">
                          <FormControl
                          
                            sx={{
                              maxWidth: "100%", // Ensure it doesn't exceed container size // full width on xs/sm screens, half width on lg screens
                            }}
                          
                          >
                            <InputLabel style={{marginTop:"-6px"}} >
                              Select the reason for scanning
                            </InputLabel>
                            <Select
                              onChange={this.handelreason}
                              value={reason}
                              label="Reason"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 150, // Set the max height for the dropdown
                                    overflowY: "auto", // Enable scrolling when height exceeds the limit
                                  },
                                },
                              }}
                            >
                              {SCAN_REASON?.map((e) => {
                                return (
                                  <MenuItem key={e?._id} value={e?.reason}>
                                    {e?.reason}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      }
                      {comments === "lnfsqldb" ||
                      lnfSQLAlreadyActivatedTag ||
                      (!user_support && tag8_support) ? (
                        <>
                          <div
                            className="drawer scan-bottom-div tata-footer"
                            onClick={() => {
                              this.setState({ drawer: !drawer });
                            }}
                          >
                            <div className="drawerarrow">
                              {!drawer ? (
                                <i
                                  className="fa fa-angle-double-up drawerarrow"
                                  onClick={() =>
                                    this.setState({ drawer: !drawer })
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-angle-double-down drawerarrow"
                                  onClick={() =>
                                    this.setState({ drawer: !drawer })
                                  }
                                ></i>
                              )}
                            </div>
                            <p
                              style={{
                                fontSize: "21px",
                                fontFamily: "prompt,sans-serif",
                              }}
                            >
                              tag8 customer support
                            </p>
                            <div className="guardianinfo">
                              <div style={{ display: "flex", gap: "1rem" }}>
                                <div
                                  className="contactdivlg"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <div
                                    style={{
                                      marginRight: "1rem",
                                      marginBottom: "1rem",
                                      // height: "50%",
                                      // width: "50%",
                                    }}
                                  >
                                    <a
                                      href={`tel:${owner_phonecodelnf}${owner_phonelnf}`}
                                      style={{ color: "black" }}
                                    >
                                      <p>
                                        <span
                                          className="conatct-div-block"
                                          style={{ display: "inline-block" }}
                                        >
                                          <img
                                            alt="call"
                                            className="reportfound-vector"
                                            src={
                                              "https://storage.googleapis.com/pettag/qr/assets/call.png"
                                            }
                                          />
                                          {"  "}
                                          Call
                                        </span>
                                      </p>
                                    </a>
                                  </div>
                                </div>
                                <div className="contactdivlg">
                                  <div
                                    style={{
                                      marginRight: "1rem",
                                      marginBottom: "1rem",
                                      // height: "50%",
                                      // width: "50%",
                                    }}
                                  >
                                    <a
                                      href={`sms:${owner_phonecodelnf}${owner_phonelnf}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{ color: "black" }}
                                    >
                                      <p>
                                        <div
                                          className="conatct-div-block"
                                          style={{ display: "inline-block" }}
                                        >
                                          <img
                                            alt="phone"
                                            className="reportfound-vector"
                                            src={
                                              "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                            }
                                          />{" "}
                                          SMS
                                        </div>
                                      </p>
                                    </a>
                                  </div>
                                </div>
                                <div className="contactdivlg">
                                  <div
                                    style={{
                                      marginBottom: "1rem",
                                      marginRight: "1rem",
                                    }}
                                  >
                                    <a
                                      href={`https://wa.me/${owner_phonecodelnf.replace(
                                        "+",
                                        ""
                                      )}${owner_phonelnf}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{ color: "black" }}
                                    >
                                      <p>
                                        <div
                                          className="conatct-div-block"
                                          style={{ display: "inline-block" }}
                                        >
                                          <img
                                            alt="phone"
                                            className="reportfound-vector"
                                            src={
                                              "https://storage.googleapis.com/pettag/qr/assets/whatsapp1.png"
                                            }
                                          />{" "}
                                          Whatsapp
                                        </div>
                                      </p>
                                    </a>
                                  </div>
                                </div>
                                <div className="contactdivlg">
                                  <div
                                    style={{
                                      marginRight: "1rem",
                                      marginBottom: "1rem",
                                      // height: "50%",
                                      // width: "50%",
                                    }}
                                  >
                                    <a
                                      href={`mailto:${owner_emaillnf}`}
                                      style={{ color: "black" }}
                                    >
                                      <p>
                                        <div
                                          className="conatct-div-block"
                                          style={{ display: "inline-block" }}
                                        >
                                          <img
                                            alt="email"
                                            className="reportfound-vector"
                                            src={
                                              "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                            }
                                          />{" "}
                                          Email
                                        </div>
                                      </p>
                                    </a>
                                  </div>
                                </div>
                                <div className="contactdivlg">
                                  <>
                                    <div
                                      style={{
                                        marginRight: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <p
                                        onClick={() =>
                                          this.setState({
                                            foundermodal: true,
                                          })
                                        }
                                      >
                                        <p
                                          style={{
                                            color: "black",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div
                                            className="conatct-div-block"
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            <img
                                              alt="email"
                                              className="reportfound-vector"
                                              src={
                                                "https://storage.googleapis.com/pettag/qr/assets/callback.png"
                                              }
                                            />{" "}
                                            Contact Me
                                          </div>
                                        </p>
                                      </p>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                            <SwipeableDrawer
                              className="swipabledrawer"
                              anchor="bottom"
                              open={drawer}
                              onClose={() => this.setState({ drawer: !drawer })}
                              onOpen={() => this.setState({ drawer: !drawer })}
                              disableSwipeToOpen={false}
                              ModalProps={{ keepMounted: true }}
                            >
                              <div
                                className="reportfound-pet-bottom-inner-div guardianmobile"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <div>
                                  <i
                                    className="fa fa-angle-double-down drawerarrow"
                                    onClick={() =>
                                      this.setState({ drawer: !drawer })
                                    }
                                  ></i>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <p
                                    style={{
                                      fontSize: "20px",
                                      fontFamily: "prompt,sans-serif",
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Contact tag8
                                  </p>
                                </div>
                                <div>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        marginRight: "1rem",
                                        height: "50%",
                                        width: "50%",
                                      }}
                                    >
                                      <a
                                        href={`tel:${owner_phonecodelnf}${owner_phonelnf}`}
                                        style={{ color: "black" }}
                                      >
                                        <p className="pabr footer-font flex-between">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <div
                                              style={{ marginBottom: "5px" }}
                                            >
                                              <img
                                                alt="call"
                                                className="reportfound-vector"
                                                src={
                                                  "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                                }
                                              />
                                            </div>
                                            <div>
                                              <span>Call</span>
                                            </div>
                                          </div>
                                        </p>
                                      </a>
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "1rem",
                                        height: "50%",
                                        width: "50%",
                                      }}
                                    >
                                      <a
                                        href={`sms:${owner_phonecodelnf}${owner_phonelnf}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ color: "black" }}
                                      >
                                        <p className="flex-between footer-font text-center pabr">
                                          <div
                                            style={{ display: "inline-block" }}
                                          >
                                            <div
                                              style={{ marginBottom: "5px" }}
                                            >
                                              <img
                                                alt="phone"
                                                className="reportfound-vector"
                                                src={
                                                  "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                                }
                                              />
                                            </div>
                                            <div>
                                              <span>SMS</span>
                                            </div>
                                          </div>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                  <div>
                                    <div style={{ display: "flex" }}>
                                      <div
                                        style={{
                                          marginBottom: "1rem",
                                          marginRight: "1rem",
                                          height: "50%",
                                          width: "50%",
                                        }}
                                      >
                                        <a
                                          href={`https://wa.me/${owner_phonecodelnf.replace(
                                            "+",
                                            ""
                                          )}${owner_phonelnf}`}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{ color: "black" }}
                                        >
                                          <p className="pabr flex-between footer-font text-center">
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <div
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <img
                                                  alt="phone"
                                                  className="reportfound-vector"
                                                  src={
                                                    "https://storage.googleapis.com/pettag/qr/assets/whatsapp1.png"
                                                  }
                                                />
                                              </div>
                                              <div>Whatsapp</div>
                                            </div>
                                          </p>
                                        </a>
                                      </div>
                                      <div
                                        style={{ height: "50%", width: "50%" }}
                                      >
                                        <a
                                          href={`mailto:${owner_emaillnf}`}
                                          style={{ color: "black" }}
                                        >
                                          <p className="flex-between footer-font text-center pabr">
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <div
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <img
                                                  alt="email"
                                                  className="reportfound-vector"
                                                  src={
                                                    "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <span> Email</span>
                                              </div>
                                            </div>
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      <p onClick={this.handelform}>
                                        <p className="pabr flex-between welcome-footer-font text-center">
                                          <div
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            <img
                                              src={
                                                "https://storage.googleapis.com/pettag/qr/assets/callback.png"
                                              }
                                              alt="contact support"
                                              className="vector"
                                              loading="lazy"
                                            />
                                            &nbsp; Contact Me
                                          </div>
                                        </p>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Footer />
                            </SwipeableDrawer>
                          </div>
                        </>
                      ) : tag8_support && user_support ? (
                        <div className="footerof-tata">
                          <div
                            className="drawer  scan-bottom-div tata-footer"
                            onClick={() => {
                              this.setState({ drawer: !drawer });
                            }}
                          >
                            <div className="drawerarrow">
                              {!drawer ? (
                                <i
                                  className="fa fa-angle-double-up drawerarrow"
                                  onClick={() =>
                                    this.setState({ drawer: !drawer })
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-angle-double-down drawerarrow"
                                  onClick={() =>
                                    this.setState({ drawer: !drawer })
                                  }
                                ></i>
                              )}
                            </div>

                            {/* <p
                              className="returnreward-text"
                              style={{
                                fontSize: "27px",
                                fontFamily: "prompt,sans-serif",
                              }}
                            >
                              {/* {checkedUserSupport
                                ? "Contact Owner"
                                : checkedTag8Support
                                ? "Contact tag8"
                                : "Contact"} */}
                            {/* </p>  */}
                            <div>
                              <Box>
                                <p
                                  className="returnrewardboth-text"
                                  style={{
                                    fontSize: "20px",
                                    fontFamily: "prompt,sans-serif",
                                  }}
                                >
                                  Contact Support
                                </p>
                                <Tabs
                                  value={value}
                                  onChange={this.handleChange}
                                  centered
                                  textColor="inherit"
                                  TabIndicatorProps={{
                                    style: {
                                      backgroundColor: "grey",
                                    },
                                  }}
                                >
                                  <Tab
                                    label="Owner"
                                    style={{
                                      color: "black",
                                      textTransform: "none",
                                      fontFamily: "prompt, sans-serif",
                                      fontSize: "15px",
                                      // borderBottom: "2px solid grey",
                                    }}
                                    // className="tab-with-line"
                                  />
                                  <Tab
                                    label="tag8"
                                    style={{
                                      color: "black",
                                      textTransform: "none",
                                      fontFamily: "prompt, sans-serif",
                                      fontSize: "15px",
                                      // borderBottom: "2px solid grey",
                                    }}
                                    // className="tab-with-line"
                                  />
                                </Tabs>
                              </Box>

                              <div className="guardianinfo">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1rem",
                                  }}
                                >
                               {
                                contact_info?.phone && <>
                                   <div
                                    className="contactdivlg"
                                    style={{ marginLeft: "2rem" }}
                                  >
                                    <div
                                      style={{
                                        marginRight: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <a
                                        href={`tel:${contact_info.phone}`}
                                        style={{ color: "black" }}
                                      >
                                        <p>
                                          <span
                                            className="conatct-div-block"
                                            style={{ display: "inline-block" }}
                                          >
                                            <img
                                              alt="call"
                                              className="reportfound-vector"
                                              src={
                                                "https://storage.googleapis.com/pettag/qr/assets/call.png"
                                              }
                                            />
                                            {"  "}
                                            Call
                                          </span>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="contactdivlg">
                                    <div
                                      style={{
                                        marginRight: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <a
                                        href={`sms:${contact_info.phone}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ color: "black" }}
                                      >
                                        <p>
                                          <div
                                            className="conatct-div-block"
                                            style={{ display: "inline-block" }}
                                          >
                                            <img
                                              alt="phone"
                                              className="reportfound-vector"
                                              src="https://storage.googleapis.com/pettag/qr/assets/message.png"
                                            />{" "}
                                            SMS
                                          </div>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="contactdivlg">
                                    <div
                                      style={{
                                        marginBottom: "1rem",
                                        marginRight: "1rem",
                                      }}
                                    >
                                      <a
                                        href={`https://wa.me/${contact_info.phone}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ color: "black" }}
                                      >
                                        <p>
                                          <div
                                            className="conatct-div-block"
                                            style={{ display: "inline-block" }}
                                          >
                                            <img
                                              alt="phone"
                                              className="reportfound-vector"
                                              src={
                                                "https://storage.googleapis.com/pettag/qr/assets/whatsapp1.png"
                                              }
                                            />{" "}
                                            Whatsapp
                                          </div>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </>
                               }
                                  <div className="contactdivlg">
                                    <div
                                      style={{
                                        marginRight: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <a
                                        href={`mailto:${contact_info.email}`}
                                        style={{ color: "black" }}
                                      >
                                        <p>
                                          <div
                                            className="conatct-div-block"
                                            style={{ display: "inline-block" }}
                                          >
                                            <img
                                              alt="email"
                                              className="reportfound-vector"
                                              src={
                                                "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                              }
                                            />{" "}
                                            Email
                                          </div>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="contactdivlg">
                                    <>
                                      <div
                                        style={{
                                          marginRight: "1rem",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <p
                                          onClick={() =>
                                            this.setState({
                                              foundermodal: true,
                                            })
                                          }
                                        >
                                          <p
                                            style={{
                                              color: "black",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <div
                                              className="conatct-div-block"
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <img
                                                alt="email"
                                                className="reportfound-vector"
                                                src={
                                                  "https://storage.googleapis.com/pettag/qr/assets/callback.png"
                                                }
                                              />{" "}
                                              Contact Me
                                            </div>
                                          </p>
                                        </p>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <SwipeableDrawer
                              className="swipabledrawer"
                              anchor="bottom"
                              open={drawer}
                              onClose={() => this.setState({ drawer: !drawer })}
                              onOpen={() => this.setState({ drawer: !drawer })}
                              disableSwipeToOpen={false}
                              ModalProps={{
                                keepMounted: true,
                              }}
                            >
                              <div
                                className="reportfound-pet-bottom-inner-div guardianmobile"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <div>
                                  <i
                                    className="fa fa-angle-double-down drawerarrow"
                                    onClick={() =>
                                      this.setState({ drawer: !drawer })
                                    }
                                  ></i>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <p
                                    style={{
                                      fontSize: "20px",
                                      fontFamily: "prompt,sans-serif",
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {checkedUserSupport
                                      ? "Contact Owner"
                                      : checkedTag8Support
                                      ? "Contact tag8"
                                      : "Contact"}
                                  </p>
                                </div>
                                <div>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        marginRight: "1rem",
                                        height: "50%",
                                        width: "50%",
                                      }}
                                    >
                                      <a
                                        href={`tel:${contact_info.phone}`}
                                        style={{ color: "black" }}
                                      >
                                        <p className="pabr footer-font flex-between">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <div
                                              style={{ marginBottom: "5px" }}
                                            >
                                              <img
                                                alt="call"
                                                className="reportfound-vector"
                                                src={
                                                  "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                                }
                                              />
                                            </div>
                                            <div>
                                              <span>Call</span>
                                            </div>
                                          </div>
                                        </p>
                                      </a>
                                    </div>

                                    <div
                                      style={{
                                        marginBottom: "1rem",
                                        height: "50%",
                                        width: "50%",
                                      }}
                                    >
                                      <a
                                        href={`sms:${contact_info.phone}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ color: "black" }}
                                      >
                                        <p className="flex-between footer-font text-center pabr">
                                          <div
                                            style={{ display: "inline-block" }}
                                          >
                                            <div
                                              style={{ marginBottom: "5px" }}
                                            >
                                              <img
                                                alt="phone"
                                                className="reportfound-vector"
                                                src={
                                                  "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                                }
                                              />
                                            </div>
                                            <div>
                                              <span>SMS</span>
                                            </div>
                                          </div>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                  <div>
                                    <div style={{ display: "flex" }}>
                                      <div
                                        style={{
                                          marginBottom: "1rem",
                                          marginRight: "1rem",
                                          height: "50%",
                                          width: "50%",
                                        }}
                                      >
                                        <a
                                          href={`https://wa.me/${contact_info.phone}`}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{ color: "black" }}
                                        >
                                          <p className="pabr flex-between footer-font text-center">
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <div
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <img
                                                  alt="phone"
                                                  className="reportfound-vector"
                                                  src={
                                                    "https://storage.googleapis.com/pettag/qr/assets/whatsapp1.png"
                                                  }
                                                />
                                              </div>
                                              <div>Whatsapp</div>
                                            </div>
                                          </p>
                                        </a>
                                      </div>

                                      <div
                                        style={{ height: "50%", width: "50%" }}
                                      >
                                        <a
                                          href={`mailto:${contact_info.email}`}
                                          style={{ color: "black" }}
                                        >
                                          <p className="flex-between footer-font text-center pabr">
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <div
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <img
                                                  alt="email"
                                                  className="reportfound-vector"
                                                  src={
                                                    "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <span> Email</span>
                                              </div>
                                            </div>
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      <p onClick={this.handelform}>
                                        <p className="pabr flex-between welcome-footer-font text-center">
                                          <div
                                            style={{ display: "inline-block" }}
                                          >
                                            <img
                                              src={
                                                "https://storage.googleapis.com/pettag/qr/assets/callback.png"
                                              }
                                              alt="contact support"
                                              className="vector"
                                              loading="lazy"
                                            />
                                            &nbsp; Contact Me
                                          </div>
                                        </p>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Footer />
                            </SwipeableDrawer>
                          </div>
                        </div>
                      ) : (
                        <>
                          {tag_status !== "deactive" ? (
                            <>
                              <div className="footerof-tata">
                                <div
                                  className="drawer  pet-bottom-div tata-footer"
                                  onClick={() => {
                                    this.setState({ drawer: !drawer });
                                  }}
                                >
                                  <div className="drawerarrow">
                                    {!drawer ? (
                                      <i
                                        className="fa fa-angle-double-up drawerarrow"
                                        onClick={() =>
                                          this.setState({ drawer: !drawer })
                                        }
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-angle-double-down drawerarrow"
                                        onClick={() =>
                                          this.setState({ drawer: !drawer })
                                        }
                                      ></i>
                                    )}
                                  </div>

                                  <p
                                    className="returnreward-text"
                                    style={{
                                      fontSize: "23px",
                                      fontFamily: "prompt,sans-serif",
                                    }}
                                  >
                                    Contact Owner
                                  </p>

                                  <div className="guardianinfo">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                    {
                                      owner_phone && owner_phonecode && <>
                                        <div
                                        className="contactdivlg"
                                        style={{ marginLeft: "2rem" }}
                                      >
                                        <>
                                          <div
                                            style={{
                                              marginRight: "1rem",
                                              marginBottom: "1rem",
                                            }}
                                          >
                                            <a
                                         
                                              href={`tel:${owner_phonecode}${owner_phone}`}
                                            >
                                              <p>
                                                <span
                                                  className="conatct-div-block"
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  <img
                                                    alt="call"
                                                    className="reportfound-vector"
                                                    src={
                                                      "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                                    }
                                                  />
                                                  {"  "}
                                                  Call
                                                </span>
                                              </p>
                                            </a>
                                          </div>
                                        </>
                                      </div>
                                      <div className="contactdivlg">
                                        <>
                                          <div
                                            style={{
                                              marginRight: "1rem",
                                              marginBottom: "1rem",
                                            }}
                                          >
                                            <a
                                         
                                              href={`sms:${owner_phonecode}${owner_phone}`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <p>
                                                <div
                                                  className="conatct-div-block"
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  <img
                                                    alt="phone"
                                                    className="reportfound-vector"
                                                    src={
                                                      "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                                    }
                                                  />{" "}
                                                  SMS
                                                </div>
                                              </p>
                                            </a>
                                          </div>
                                        </>
                                      </div>

                                      <div className="contactdivlg">
                                        <div
                                          style={{
                                            marginBottom: "1rem",
                                            marginRight: "1rem",
                                          }}
                                        >
                                          <a
                                         
                                            href={`https://wa.me/${owner_phonecode?.replace(
                                              "+",
                                              ""
                                            )}${owner_phone}`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <p>
                                              <div
                                                className="conatct-div-block"
                                                style={{
                                                  display: "inline-block",
                                                }}
                                              >
                                                <img
                                                  alt="phone"
                                                  className="reportfound-vector"
                                                  src={
                                                    "https://pettag.storage.googleapis.com/qr-bag/WhatsAppImage2024-03-20at2.25.34PM.jpeg1710925717209"
                                                  }
                                                />{" "}
                                                Whatsapp
                                              </div>
                                            </p>
                                          </a>
                                        </div>
                                      </div>
                                      </>
                                    }
                                      <div className="contactdivlg">
                                        <>
                                          <div
                                            style={{
                                              marginRight: "1rem",
                                              marginBottom: "1rem",
                                            }}
                                          >
                                            <a 
                                           
                                            href={`mailto:${owner_email}`}>
                                              <p>
                                                <div
                                                  className="conatct-div-block"
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  <img
                                                    alt="email"
                                                    className="reportfound-vector"
                                                    src={
                                                      "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                                    }
                                                  />{" "}
                                                  Email
                                                </div>
                                              </p>
                                            </a>
                                          </div>
                                        </>
                                      </div>
                                      <div className="contactdivlg">
                                        <>
                                          <div
                                            style={{
                                              marginRight: "1rem",
                                              marginBottom: "1rem",
                                            }}
                                          >
                                            <p
                                              onClick={() =>
                                              {
                                              
                                                this.setState({
                                                  foundermodal: true,
                                                })
                                             
                                              }
                                              }
                                            >
                                              <p
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <div
                                                  className="conatct-div-block"
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  <img
                                                    alt="email"
                                                    className="reportfound-vector"
                                                    src={
                                                      "https://pettag.storage.googleapis.com/qr-bag/Untitleddesign(39)(1).png1710925466237"
                                                    }
                                                  />{" "}
                                                  Contact Me
                                                </div>
                                              </p>
                                            </p>
                                          </div>
                                        </>
                                      </div>
                                    </div>
                                  </div>

                                  <SwipeableDrawer
                                    className="swipabledrawer"
                                    anchor="bottom"
                                    open={drawer}
                                    onClose={() =>
                                      this.setState({ drawer: !drawer })
                                    }
                                    onOpen={() =>
                                      this.setState({ drawer: !drawer })
                                    }
                                    disableSwipeToOpen={false}
                                    ModalProps={{
                                      keepMounted: true,
                                    }}
                                  >
                                    <div
                                      className="reportfound-pet-bottom-inner-div guardianmobile"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div>
                                        <i
                                          className="fa fa-angle-double-down drawerarrow"
                                          onClick={() =>{
                                            this.setState({
                                              drawer: !drawer,
                                            })
                                         
                                          }
                                          }
                                        ></i>
                                      </div>
                                      <div style={{ textAlign: "center" }}>
                                        <p
                                          style={{
                                            fontSize: "20px",
                                            fontFamily: "prompt,sans-serif",
                                            marginBottom: "10px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          Contact Owner
                                        </p>
                                      </div>
                                      <div>
                                        <div style={{ display: "flex" }}>
                 {
                  owner_phonecode && owner_phone && <>
                                           <div
                                            style={{
                                              marginRight: "1rem",
                                              width: "50%",
                                              height: "50%",
                                            }}
                                          >
                                            <a
                                         
                                              href={`tel:${owner_phonecode}${owner_phone}`}
                                            >
                                              <p className="pabr footer-font flex-between">
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      marginBottom: "5px",
                                                    }}
                                                  >
                                                    <img
                                                      alt="call"
                                                      className="reportfound-vector"
                                                      src={
                                                        "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                                      }
                                                    />
                                                  </div>
                                                  <div>
                                                    <span>Call</span>
                                                  </div>
                                                </div>
                                              </p>
                                            </a>
                                          </div>

                                          <div
                                            style={{
                                              marginBottom: "1rem",
                                              width: "50%",
                                              height: "50%",
                                            }}
                                          >
                                            <a
                                         
                                              href={`sms:${owner_phonecode}${owner_phone}`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <p className="flex-between footer-font text-center pabr">
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      marginBottom: "5px",
                                                    }}
                                                  >
                                                    <img
                                                      alt="phone"
                                                      className="reportfound-vector"
                                                      src={
                                                        "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                                      }
                                                    />
                                                  </div>
                                                  <div>
                                                    <span>SMS</span>
                                                  </div>
                                                </div>
                                              </p>
                                            </a>
                                          </div>
                  </>
                 }
                                        </div>
                                        <div>
                                          <div style={{ display: "flex" }}>
                                         {
                                          owner_phone && owner_phonecode && <>
                                             <div
                                              style={{
                                                marginBottom: "1rem",
                                                marginRight: "1rem",
                                                width: "50%",
                                                height: "50%",
                                              }}
                                            >
                                              <a
                                            
                                                href={`https://wa.me/${owner_phonecode?.replace(
                                                  "+",
                                                  ""
                                                )}${owner_phone}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <p className="pabr flex-between footer-font text-center">
                                                  <div
                                                    style={{
                                                      display: "inline-block",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      <img
                                                        alt="phone"
                                                        className="reportfound-vector"
                                                        src={
                                                          "https://pettag.storage.googleapis.com/qr-bag/WhatsAppImage2024-03-20at2.25.34PM.jpeg1710925717209"
                                                        }
                                                      />
                                                    </div>
                                                    <div>Whatsapp</div>
                                                  </div>
                                                </p>
                                              </a>
                                            </div>
                                          </>
                                         }

                                            <div
                                              style={{
                                                width: "50%",
                                                height: "50%",
                                              }}
                                            >
                                              <a
                                            
                                               href={`mailto:${owner_email}`}>
                                                <p className="flex-between footer-font text-center pabr">
                                                  <div
                                                    style={{
                                                      display: "inline-block",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      <img
                                                        alt="email"
                                                        className="reportfound-vector"
                                                        src={
                                                          "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                                        }
                                                      />
                                                    </div>
                                                    <div>
                                                      <span> Email</span>
                                                    </div>
                                                  </div>
                                                </p>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div>
                                            <p onClick={this.handelform}>
                                              <p className="pabr flex-between welcome-footer-font text-center">
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      "https://pettag.storage.googleapis.com/qr-bag/Untitleddesign(39)(1).png1710925466237"
                                                    }
                                                    alt="contact support"
                                                    className="vector"
                                                    loading="lazy"
                                                  />
                                                  &nbsp; Contact Me
                                                </div>
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <Footer />
                                  </SwipeableDrawer>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      )}
                    </Card>
                  </div>
                </div>
              </div>
            )}

            <Dialog open={this.state.locationalert} maxWidth="md">
              <DialogTitle id="alert-dialog-title">{"GPS Alert"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <p style={{ margin: "1rem" }}>
                    Please Enable your GPS Location to continue
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({ locationalert: false });
                    window.location.reload();
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={medical_warning}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>Warning</DialogTitle>
              <DialogContent>
                <div style={{ margin: "1rem" }}>
                  {valuable_type === "Diabetic Supplies" ? (
                    <DialogContentText style={{ color: "red" }}>
                      The Air Carrier Access Act (ACAA) and DOT rules prohibit
                      discriminatory treatment of persons with disabilities in
                      air transportation. The limit of one carry on bag and one
                      personal bag does not apply to medical supplies and/or
                      medical equipment. Passengers with disobilities generally
                      may carry medical supplies, equipment, medications and
                      assistive devices on board the aircraft. Medical supplies
                      must conform to airlines carry on dimensions. VIOLATORS OF
                      DOT RULES OR THE ACAA MAY BE SUBJECT TO SIGNIFICANT FINES
                      This bag contains medically required supplies and must be
                      in the possession of the prescribed owner at all times.
                    </DialogContentText>
                  ) : (
                    <DialogContentText style={{ color: "red" }}>
                      This {valuable_type} is a medical necessity and
                      should be kept with the patient at all times during
                      travel. As defined in USDOT (Dept of Transportation) 14
                      CFR Part 382, this equipment should not be stored away
                      from the passenger and will not count toward the limit on
                      carry-on items. TSA (Transportation Security Admin) rules
                      state that this equipment may be inspected at all
                      checkpoints but should not be separated from the
                      passenger.
                    </DialogContentText>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({
                      medical_warning: false,
                    });
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              open={snackbaropen}
              autoHideDuration={8000}
              onClose={this.handleSnackbarClose}
              message={snackbarmsg}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleSnackbarClose}
                >
                  X
                </IconButton>,
              ]}
            />
          </div>
        )}
        {showFooter ? <Footer /> : ""}
      </>
    );
  }
}
