import axios from "axios";
import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

function ProtectedRoute({ isAuth, component: Component, ...rest }) {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    if (ca) {
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) === " ") c = c.substring(1, c.length);

        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
    }

    return null;
  }
  const token = readCookie("token");
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          return <Component {...props} />;
        } else {
          localStorage.setItem("pathname", props.location.pathname); //this will store the requested url which can be used to redirect after login
          var url = document.URL.split("/");
          if (url[0] === "https:") {
            document.cookie =
              "token=; Path=/; domain=.tag8.in; samesite=none; secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          } else {
            document.cookie =
              "token=; Path=/; domain=localhost; samesite=none; secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }
          return <Redirect to={"/login"} />;
        }
      }}
    />
  );
}

export default ProtectedRoute;
