import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MyComponent from "./googlelogincomponent";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { logEvent, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { gapi } from "gapi-script";
import axios from "axios";
import "./login.component.css";
import LoginAnimation from "./loginanimation.component.jsx";
import GoogleLoginPopup from "./googleloginpopup.component.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Navbar from "../header/header.component";
import { DialogContentText, DialogTitle } from "@material-ui/core";
import useFormValidation from "../formvalidation/formvalidation.component";
import {
  TextField,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  Button,
  Box,
} from "@mui/material";
// import emailicon from "../../assets/email.png";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as MdIcons from "react-icons/md";
import CryptoJS from "crypto-js";
import jwtDecode from "jwt-decode";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
import AppleSignin from "react-apple-signin-auth";
// import apple from "../../assets/apple.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import Apple from "@mui/icons-material/Apple";
import { switchUrls } from "../../api/index.js";
const redirect_uri = switchUrls("appleuri");
const found = localStorage.getItem("Found");
const scan = localStorage.getItem("Scan");
if (found !== false) {
  localStorage.removeItem("tag_number");
}
if (scan !== false && scan !== null) {
  // window.location.reload(false);
  localStorage.removeItem("tag_number");
}
const firebaseConfig = {
  apiKey: "AIzaSyCHWDRpd7asWYRXSPruxNfUD6Plf3Wy_as",
  authDomain: "dolphin-tracker-6d57b.firebaseapp.com",
  projectId: "dolphin-tracker-6d57b",
  storageBucket: "dolphin-tracker-6d57b.appspot.com",
  messagingSenderId: "706582936796",
  appId: "1:706582936796:web:bfc5502805578716068819",
  measurementId: "G-X1243WMENZ",
  databaseURL: "https://dolphin-tracker-6d57b-default-rtdb.firebaseio.com",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics();

function Timer({ counter }) {
  const minutes = Math.floor(counter / 60);
  const seconds = counter - minutes * 60;

  return (
    <span>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </span>
  );
}
// console.log("dashbord",dashboardurl)
function Login() {
  // const clientId ="28183007093-58tqp32c9rsjtnvsa19rp0h27b0sh2r3.apps.googleusercontent.com";

  // const clientId =
  //   "972792551017-hvq7nv8mbasb258aa2o21mki68rith9v.apps.googleusercontent.com";

  // useEffect(() => {
  // gapi.load("client:auth2", initClient);
  // var initClient = () => {
  //   //  gapi.client.init({
  //   gapi.auth2.init({
  //     clientId: clientId,
  //     scope: "",
  //   });
  // };
  //  const initClient = () => {
  //        gapi.client.init({
  //        clientId: clientId,
  //        scope: ''
  //      });
  //   };
  //   gapi.load('client:auth2', initClient);
  // });

  const [passwordType, setPasswordType] = useState("password");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [productcategory, setProductcategory] = useState("");
  const [googleuser, setGoogleUser] = useState(null);
  const [tagNumber, setTagNumber] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState();
  const [passworddecr, setPassworddecr] = useState("");
  const [message, setMessage] = useState("");
  const [loginmessage, setLoginMessage] = useState("");
  const [passwordmessage, setPasswordMessage] = useState("");
  const [alert, setAlert] = useState("none");
  const [openGLPopup, setOpenGLPopup] = useState(false);
  const [show, setShow] = useState(false);
  const [emailForgot, setEmailForgot] = useState("");
  const [otp, setOtp] = useState("");
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [characterLength, setCharacterLength] = useState("none");
  const [validPassword, setValidPassword] = useState("none");
  const [passwordValid, setPasswordValid] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [islfUser, setSetislfUser] = useState(false);
  const [counter, setCounter] = useState(180);
  const [timerId, setTimerId] = useState(null);

  const [isResendDisabled, setIsResendDisabled] = useState(false);
  // const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const {
    email,
    password,
    setEmail,
    setPassword,
    emailError,
    passwordError,
    handleChangeEmail,
    handleChangePassword,
    isFormValid,
  } = useFormValidation();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    sendOtp();
    startTimer();
    setShow(true);
    setMessage("");
  };
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
  };
  const handleClose2 = () => setShow2(false);

  const [googleloginbuttondisable, setgoogleloginbuttondisable] =
    useState(false);
  const socialbtn = {
    backgroundColor: "black",
    color: "white",
    border: "1px solid black",
    textTransform: "none",
    width: "200px",
  };
  const applebtn = {
    width: "200px",

    backgroundColor: "black",
    padding: "12px",
    color: "white",
    paddingLeft: "20px",
    textTransform: "none",
    margin: "5px 0",
    textAlign: "left",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    textDecoration: "none",
  };
  const fbbtn = {
    width: "200px",

    border: "1px solid Hex #1877F2",
    padding: "12px",
    color: "Hex #1877F2",
    paddingLeft: "20px",
    textTransform: "none",
    margin: "5px 0",
    textAlign: "left",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    textDecoration: "none",
  };
  const gbtn = {
    width: "200px",
    padding: "12px",
    border: "none",
    backgroundColor: "black",
    margin: "5px 0",

    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    textDecoration: "none",
  };

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  // useEffect(() => {
  //   // Retrieve counter from localStorage if available
  //   const storedCounter = localStorage.getItem("otpCounter");
  //   if (storedCounter !== 0) {
  //     setCounter(parseInt(storedCounter));
  //   }
  // }, []);

  useEffect(() => {
    const decrementCounter = () => {
      setCounter((prevCounter) => {
        if (prevCounter > 0) {
          return prevCounter - 1;
        } else {
          clearInterval(timerId);
          setIsResendDisabled(false); // Enable Resend OTP button
          return 0;
        }
      });
    };

    if (counter > 0 && !timerId) {
      const newTimerId = setInterval(decrementCounter, 1000);
      setTimerId(newTimerId);
      // console.log("Timer started");
      return () => clearInterval(newTimerId); // Cleanup function to clear interval
    } else if (counter === 0) {
      setIsResendDisabled(false); // Ensure Resend OTP button is enabled when counter reaches 0
    }
  }, [counter, timerId]);

  const startTimer = () => {
    setIsResendDisabled(true); // Disable Resend OTP button while timer is running

    // Initialize counter from localStorage if available, otherwise start from 180
    const initialCounter = localStorage.getItem("otpCounter") || 180;
    setCounter(parseInt(initialCounter));
    if (timerId) {
      clearInterval(timerId);
    }
    const newTimerId = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter > 0) {
          return prevCounter - 1;
        } else {
          clearInterval(newTimerId);
          // setIsResendDisabled(false); // Enable Resend OTP button
          return 0;
        }
      });
    }, 1000);

    setTimerId(newTimerId);
  };

  useEffect(() => {
    // Store counter in localStorage whenever it changes
    // console.log(counter, "counter");
    // if (counter !== 0) {
    //   localStorage.setItem("otpCounter", counter.toString());
    // }
    console.log(counter, "counter");
    localStorage.setItem("otpCounter", counter.toString());
  }, [counter]);

  useEffect(() => {
    // if (localStorage.getItem("loginemail") === "") {
    //   localStorage.setItem(
    //     "loginemail",
    //     localStorage.getItem("emailloginemail") || ""
    //   );
    // }
    // if (localStorage.getItem("password") === "") {
    //   localStorage.setItem(
    //     "password",
    //     localStorage.getItem("passwordloginpassword") || ""
    //   );
    // }
    // const initClient = () => {
    //   //  gapi.client.init({
    //   gapi.auth2.init({
    //     clientId: clientId,
    //     scope: "",
    //   });
    // };
    // gapi.load("client:auth2", initClient);
  }, []);
  const passwordRE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

  function updatePassword() {
    const state = {
      password: newPassword,
      email: email,
    };
    const jsonString = JSON.stringify(state);

    const secretKey = process.env.REACT_APP_PAYLOAD_SECRET_KEY;
    // console.log(secretKey, "key");
    const encryptedMessage = CryptoJS.AES.encrypt(
      jsonString,
      secretKey
    ).toString();

    axios
      .post(
        "/api/updatePassword",
        {
          encryptedMessage: encryptedMessage,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
        if (res.data.data === "Password updated successfully") {
          // const changePass = JSON.parse(res.config.data);
          // console.log("resdata", changePass.password);
          // setPassword(changePass.password);
          // const passwordChangeCipherText = CryptoJS.AES.encrypt(
          //   changePass.password,
          //   "AWDEF"
          // ).toString();
          // localStorage.setItem("password", passwordChangeCipherText);
          setMessage("Password Updated");
          setCounter(180);
          localStorage.setItem("otpCounter", "180");
          setTimeout(() => {
            setMessage("");
          }, 3000);
          setAlert("flex");
          setChangePassword(false);
          setShow(false);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  // const onSuccess = async (googleUser) => {

  // const idToken = googleUser.getAuthResponse().id_token;
  // const client = new OAuth2Client(clientID);
  // const ticket = await client.verifyIdToken({
  //   idToken,
  //   audience: clientID,
  // });
  // const user = ticket.getPayload();
  // console.log(googleUser);
  // };
  const responseGoogle = async (response, error) => {
    // console.log(response, "resss");
    var decoded = jwtDecode(response.credential);
    // console.log(decoded, "decodeded");

    if (response) {
      var newLine = "\r\n";
      var msg = "Please enable third party cookies if you want to login.";

      msg += newLine;
      msg += newLine;
      msg +=
        "Here are the instructions how to enable 3rd-party cookies in your Google Chrome browser:";
      msg += newLine;
      msg +=
        "1. In Google Chrome browser, at the top right, click More and then Settings.";
      msg += newLine;
      msg += "2. In the Privacy and Security section, click Site settings";
      msg += newLine;
      msg +=
        "3. Select Cookies and uncheck the box next to Block third-party cookies and site data:";

      if (
        response.details === "Cookies are not enabled in current environment."
      ) {
        window.alert(msg);

        setgoogleloginbuttondisable(true);
      } else {
        setgoogleloginbuttondisable(false);
        // setGoogleUser(decoded);
        // console.log(googleuser);
        // console.log(response, "response");
        const state = {
          first_name: capitalizeName(decoded.given_name)
            ? capitalizeName(decoded.given_name)
            : capitalizeName(decoded.name),
          last_name: capitalizeName(decoded.family_name)
            ? capitalizeName(decoded.family_name)
            : null,
          email: decoded.email,
        };
        // console.log(state, "state");
        localStorage.setItem("first_name", state.first_name || "");
        localStorage.setItem("last_name", state.last_name || "");
        localStorage.setItem("email", state.email || "");
        const jsonString = JSON.stringify(state);

        const secretKey = process.env.REACT_APP_PAYLOAD_SECRET_KEY;
        // console.log(secretKey, "key");
        const encryptedMessage = CryptoJS.AES.encrypt(
          jsonString,
          secretKey
        ).toString();

        axios
          .post("/api/loginwithgoogle", {
            encryptedMessage: encryptedMessage,
          })
          .then((res) => {
            let data = res;
            // console.log(res.data, "data");
            // console.log(data.data, "data here");
            var url = document.URL.split("/");

            if (data.data.uid) {
              switch (url[2]) {
                case "localhost:3000":
                  // console.log("local", res.data.token);
                  document.cookie = `token=${res.data.token}; domain=localhost; path=/; samesite=none; secure;`;
                  break;
                case "dashboard.tag8.co.in":
                  // console.log("testing", res.data.token);
                  document.cookie = `token=${res.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
                  break;
                case "dashboard.tag8.in":
                  // console.log("live", res.data.token);
                  document.cookie = `token=${res.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
                  break;
                default:
                  break;
              }

              localStorage.setItem("uid", data.data.uid);
              localStorage.setItem("name", data.data.first_name);
              localStorage.setItem("isAdmin", data.data.isAdmin);
              localStorage.setItem("mid", data.data._id);
              localStorage.setItem("logintype", "google");

              window.location.href = "/";
            } else {
              // console.log("else bl0dkk");
              window.location.href = "/#/signup";
              // switch (url[2]) {
              //   case "localhost:3000":
              //     // console.log("local", res.data.token);
              //     document.cookie = `token=${res.data.token}; domain=localhost; path=/; samesite=none; secure;`;
              //     break;
              //   case "dashboard.tag8.co.in":
              //     // console.log("testing", res.data.token);
              //     document.cookie = `token=${res.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
              //     break;
              //   case "dashboard.tag8.in":
              //     // console.log("live", res.data.token);
              //     document.cookie = `token=${res.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
              //     break;

              //   default:
              //     break;
              // }
              // setOpenGLPopup(true);

              // localStorage.setItem("logintype", "google");

              // axios
              //   .get("/api/getUserProfile", {
              //     headers: {
              //       Authorization: res.data.token,
              //       "Content-Type": "application/json",
              //     },
              //   })
              //   .then((res) => {
              //     localStorage.setItem("uid", res.data.uid);
              //   })
              //   .catch((error) => {
              //     console.error(error.message);
              //   });
            }
          })
          .catch((error) => {
            // console.log(error.response.data.error);
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              const errorMessage = error.response.data.error;

              if (errorMessage === "User not found. Please sign up.") {
                window.location.href = "/#/signup";
              } else {
                setLoginMessage(errorMessage);
              }
            } else {
              setLoginMessage(
                "An unexpected error occurred during login. Please try again."
              );
            }
          });
      }
    } else {
      console.error(error.message);
    }
  };
  // function checkLfUser() {
  //   const state = {
  //     email: emailForgot,
  //   };

  //   axios
  //     .post("/api/lookUpEmail", state)
  //     .then((res) => {
  //       // console.log(res, "res");
  //       if (res.data.data.length > 0) {
  //         // console.log("res if part", res.data);
  //         // sendOtpLF();
  //         setShow(true);
  //         setSetislfUser(true);
  //         // setCounter(299);
  //         setMessage("");
  //       } else {
  //         // console.log("res else part", res.data);
  //         sendOtp();
  //         setShow(true);
  //         setSetislfUser(false);
  //         // setCounter(299);
  //         setMessage("");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error, "error");

  //       // setMessage(error.message.data);
  //     });
  // }

  // function sendOtpLF() {
  //   setMessage("");

  //   const state = {
  //     email: email,
  //   };
  //   // console.log(state, "forgot1");

  //   axios
  //     .post("/api/lf/lfPasswordResetEmail", state)
  //     .then((res) => {
  //       if (res.data.data === "EMAIL SENT") {
  //         // console.log("res", res.data.data);
  //         setAlert("flex");
  //         handleShow1();
  //       } else {
  //         setMessage("OTP Already Active!Please try after 3Min");
  //         setTimeout(() => {
  //           setMessage("");
  //         }, 3000);
  //         setAlert("flex");
  //         // handleClose();
  //         handleShow1();
  //       }

  //       // if (res.data.data === "User Exists") {
  //       //   setAlert("flex");
  //       //   handleShow1();
  //       // } else if (res.data.data === "User does not exists") {
  //       //   setMessage("User does not exists.");
  //       //   setTimeout(() => {https://meet.google.com/bbp-ygbv-abv
  //       //     setMessage("");
  //       //   }, 3000);
  //       //   setAlert("flex");

  //       //   handleClose();
  //       // }
  //     })
  //     .catch((error) => {
  //       console.error(error.message);
  //     });
  // }

  const sendOtp = () => {
    setMessage("");

    const state = {
      email: email,
    };
    const jsonString = JSON.stringify(state);

    const secretKey = process.env.REACT_APP_PAYLOAD_SECRET_KEY;
    const encryptedMessage = CryptoJS.AES.encrypt(
      jsonString,
      secretKey
    ).toString();
    console.log(counter, "Counter value");

    // Adjust the condition to check if counter is equal to 0
    if (counter === 0) {
      console.log("Counter is 0, skipping API call");
      return;
    }

    // Make the API call if the counter is 0
    axios
      .post("/api/sendPasswordEmail", {
        encryptedMessage: encryptedMessage,
      })
      .then((res) => {
        if (res.data.data === "User Exists") {
          setAlert("flex");
          setShow(true);
          setCounter(180);
          setIsResendDisabled(true);
        } else if (res.data.data === "User does not exist") {
          setMessage("User does not exist.");
          setTimeout(() => setMessage(""), 3000);
          setAlert("flex");
          handleClose();
        }
      })
      .catch((error) => {
        setMessage(error.response.data.data);
        setTimeout(() => setMessage(""), 3000);
      });
  };

  const resendOTP = () => {
    setMessage("");

    const state = {
      email: email,
    };
    const jsonString = JSON.stringify(state);

    const secretKey = process.env.REACT_APP_PAYLOAD_SECRET_KEY;
    // console.log(secretKey, "key");
    const encryptedMessage = CryptoJS.AES.encrypt(
      jsonString,
      secretKey
    ).toString();
    axios
      .post("/api/resend", {
        encryptedMessage: encryptedMessage,
      })
      .then((res) => {
        setShowLoader(true);
        setTimeout(
          () => setMessage("Verification code has been sent on your email Id."),
          3000
        );

        setShow(true); // Ensure dialog is shown after resending OTP
        // startTimer();
        setTimeout(() => {
          setShowLoader(false);
        }, 3000);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  // function verifyOTPLf(event) {
  //   event.preventDefault();

  //   setMessage("");
  //   setOtp("");

  //   const state = {
  //     email: email,
  //     otp: otp,
  //   };

  //   axios
  //     .post("/api/lf/lfOTPVerify", state)
  //     .then((res) => {
  //       if (res.data === "otp is incorrect") {
  //         setMessage("OTP is Incorrect.");
  //         setTimeout(() => {
  //           setMessage("");
  //         }, 3000);
  //         setAlert("flex");
  //       } else {
  //         handleClose1();
  //         handleClose();
  //         setSetislfUser(true);
  //         setMessage("OTP is Correct.");
  //         setTimeout(() => {
  //           setMessage("");
  //         }, 3000);
  //         setAlert("flex");
  //         setChangePassword(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error.message);
  //     });
  // }

  function verifyOTP(event) {
    event.preventDefault();

    setMessage("");
    setOtp("");

    const state = {
      email: email,
      otp: otp,
    };

    axios
      .post("/api/Verifyemail", state)
      .then((res) => {
        console.log(res.data, "data");
        if (res.data === "otp is incorrect") {
          setMessage("OTP is Incorrect.");
          setTimeout(() => {
            setMessage("");
          }, 3000);
          setAlert("flex");
        } else {
          handleClose1();

          setMessage("OTP is Correct.");
          setTimeout(() => {
            setMessage("");
          }, 3000);
          setAlert("flex");
          setChangePassword(true);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  // function verifyOTP1(event) {
  //   setMessage("");

  //   event.preventDefault();

  //   const state = {
  //     email: email,
  //     otp: otp,
  //   };

  //   axios
  //     .post("/api/Verifyemail", state)
  //     .then((res) => {
  //       if (res.data === "otp is incorrect") {
  //         setMessage("OTP is Incorrect.");
  //         setTimeout(() => {
  //           setMessage("");
  //         }, 3000);
  //         setAlert("flex");
  //       } else {
  //         setMessage("OTP is Correct.");
  //         setTimeout(() => {
  //           setMessage("");
  //         }, 3000);
  //         setAlert("flex");
  //         handleClose2();
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error.message);
  //     });
  // }

  function handleSubmit(event) {
    // const baseURL = "http://localhost:3004";
    event.preventDefault();
    if (!isFormValid()) {
      // Form is not valid, do not proceed with API call
      // console.log(emailError, passwordError, "passwow");
      return;
    }
    const state = {
      email: email,
      password: password,
    };
    const jsonString = JSON.stringify(state);

    const secretKey = process.env.REACT_APP_PAYLOAD_SECRET_KEY;
    // console.log(secretKey, "key");
    const encryptedMessage = CryptoJS.AES.encrypt(
      jsonString,
      secretKey
    ).toString();

    // console.log(state, "state");
    axios
      .post(`/api/updatedUserLogin`, {
        encryptedMessage: encryptedMessage,
      })
      .then((res) => {
        // console.log(res, "ressss");
        const tagnumber = localStorage.getItem("tagNumber");
        // console.log(res.data.token);
        setShowLoader(true);
        // console.log(showLoader, "loader showtwo");
        const loaderTimer = setTimeout(() => {
          // console.log(showLoader, "loader show");
          setShowLoader(false);
        }, 10000);
        if (res.data.error) {
          setShowLoader(false);
          // if (res.data.error === "User does not exist") {
          //   setMessage("Invalid Email.");
          //   setTimeout(() => {
          //     setMessage("");
          //   }, 3000);
          //   setAlert("flex");
          // } else if (res.data.error === "Account is not verified") {
          //   setMessage("Account is not verified.");
          //   setTimeout(() => {
          //     setMessage("");
          //   }, 3000);
          //   setAlert("flex");
          //   setShow2(true);
          // } else {
          //   // console.log(showLoader, "dikhaioo");
          //   setPasswordMessage("Please enter correct password.");

          //   setTimeout(() => {
          //     setPasswordMessage("");
          //   }, 3000);
          //   setAlert("flex");
          // }
        } else {
          var url = document.URL.split("/");
          // if (url[0] == "https:") {
          //   document.cookie = `token=${res.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
          // } else {
          //   document.cookie = `token=${res.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
          // }
          // if (url[0] == "http:") {
          //   console.log("if login")
          //   document.cookie = `token=${res.data.token}; domain=.tag8.co.in;`;
          // } else {
          //   console.log("else login header");
          //   // document.cookie = `token=${res.data.token}; domain=localhost; path=/; samesite=none; secure;`;
          // }
          // console.log(url[2]);
          switch (url[2]) {
            case "localhost:3000":
              // console.log("local", res.data.token);
              document.cookie = `token=${res.data.token}; domain=localhost; path=/; samesite=none; secure;`;
              clearTimeout(loaderTimer);
              break;
            case "dashboard.tag8.co.in":
              // console.log("testing", res.data.token);
              document.cookie = `token=${res.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
              break;
            case "dashboard.tag8.in":
              // console.log("live", res.data.token);
              document.cookie = `token=${res.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
              break;

            default:
              // console.log("reached break");
              break;
          }
          localStorage.setItem("logintype", "manual");

          //Password encoding shraddha
          const passwordCipherText = CryptoJS.AES.encrypt(
            password,
            "AWDEF"
          ).toString();
          axios
            .get("/api/getUserProfile", {
              headers: {
                Authorization: res.data.token,
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              // console.log("res", res);
              localStorage.setItem("uid", res.data.uid);
              localStorage.setItem("name", res.data.first_name);
              localStorage.setItem("isAdmin", res.data.isAdmin);
              localStorage.setItem("loginemail", email);
              localStorage.setItem("mid", res.data._id);
              // localStorage.setItem("password", password);
              localStorage.setItem("password", passwordCipherText);
              localStorage.setItem("emailloginemail", email);
              localStorage.setItem("loginemailforgot", email);
              localStorage.setItem("passwordloginpassword", passwordCipherText);
              // localStorage.setItem("passwordloginpassword", password);

              if (tagnumber == null) {
                const urlpathToredirect = localStorage.getItem("pathname");

                /*
                   if(urlpathToredirect){
                 localStorage.removeItem("pathname")

                 window.location.href = urlpathToredirect
                }
                else{
              window.location.href = "/";
                }
                  */

                window.location.href = "/";
                localStorage.removeItem("tagNumber");
              } else {
                const tag_number = localStorage.getItem("tagNumber");
                if (tag_number) {
                  axios
                    .get("/api/getTagNumber", {
                      params: {
                        tag: tag_number,
                      },
                    })
                    .then((res) => {
                      var data = res.data.tagResult.product_category;
                      // console.log(data, "resshhshh");
                      setProductcategory(data);
                      // showinitialletter(data);
                    })
                    .catch((error) => {});
                }
                if (productcategory === "QR PET") {
                  window.location.href = "/pet-activation/" + tag_number;
                  localStorage.removeItem("tagNumber");
                } else if (
                  productcategory === "QR bag" ||
                  productcategory === "QR Bag" ||
                  productcategory === "Dolphin Bag"
                ) {
                  window.location.href = "/bag-activation/" + tag_number;
                  localStorage.removeItem("tagNumber");
                } else if (productcategory === "QR Key") {
                  window.location.href = "/key-activation/" + tag_number;
                  localStorage.removeItem("tagNumber");
                } else if (productcategory === "QR Gadget") {
                  window.location.href = "/#/earphone-activation/" + tag_number;
                  localStorage.removeItem("tagNumber");
                } else if (productcategory === "QR Passport") {
                  window.location.href = "/passport-activation/" + tag_number;
                  localStorage.removeItem("tagNumber");
                } else if (productcategory === "Generic") {
                  window.location.href = "/generic-activation/" + tag_number;
                  localStorage.removeItem("tagNumber");
                } else if (productcategory === "QR Wallet") {
                  window.location.href = "/wallet-activation/" + tag_number;
                  localStorage.removeItem("tagNumber");
                }
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
        }
      })
      .catch((error) => {
        // console.log(error, "responswee");
        if (error.response.data.error === "Email and password are required") {
          // setMessage("Email and password are required");
          // setTimeout(() => {
          //   setMessage("");
          // }, 3000);
          // setAlert("flex");
        } else if (error.response.data.error === "User not found") {
          setMessage("User not found.");
          setTimeout(() => {
            setMessage("");
          }, 3000);
          setAlert("flex");
          setShow2(true);
        } else {
          // console.log(showLoader, "dikhaioo");
          // console.log(error, "responswee");
          setPasswordMessage(error.response.data.error);

          setTimeout(() => {
            setPasswordMessage("");
          }, 3000);
          setAlert("flex");
        }
      });
  }

  const valid = (item) => {
    let password = document.querySelector(`#${item}`);

    password.style.display = "none";
  };

  const invalid = (item) => {
    let password = document.querySelector(`#${item}`);

    password.style.display = "block";
  };
  function formValid() {
    return passwordValid;
  }

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    // console.log(value, "valuee");
    setPassword(value);
    setIsEmpty(value.trim() === "");
    // setIsSubmitDisabled(value.trim() === "");

    localStorage.setItem("password", "");
  };
  localStorage.setItem("loginemailforgot", email);
  useEffect(() => {
    if (alert === "flex") {
      setTimeout(() => setAlert("none"), 8000);
    }

    // console.log(email, "emialld");

    // setEmailForgot(
    //   localStorage.getItem("loginemailforgot")
    //     ? localStorage.getItem("loginemailforgot")
    //     : emailForgot
    // );

    // setEmail(
    //   localStorage.getItem("loginemail")
    //     ? localStorage.getItem("loginemail")
    //     : email
    // );

    // setPassword(
    //   localStorage.getItem("password")
    //     ? localStorage.getItem("password")
    //     : password
    // );
    // const getPassword = localStorage.getItem("password");
    // var passwordDecryptText = CryptoJS.AES.decrypt(
    //   getPassword,
    //   "AWDEF"
    // ).toString(CryptoJS.enc.Utf8);

    // setPassword(passwordDecryptText);

    setPassworddecr();
    if (newPassword.length > 7 || newPassword === "") {
      setCharacterLength("none");

      if (passwordRE.test(newPassword)) {
        setPasswordValid(true);
        setValidPassword("none");
      } else if (newPassword !== "") {
        setPasswordValid(false);
        setValidPassword("block");
      } else {
        setPasswordValid(false);
        setValidPassword("none");
      }
    } else {
      setValidPassword("block");
      setCharacterLength("block");
      setPasswordValid(false);
    }

    setSubmitDisabled(!formValid());
  });
  const loginwithfb = async (response) => {
    // console.log(response);
    try {
      if (!response?.error) {
        const result = await axios.post("/api/loginwithfb", {
          userId: response.userID,
          accessToken: response.accessToken,
        });
        // console.log(result);
        if (result.data.token) {
          // console.log(result.data);
          var url = document.URL.split("/");

          switch (url[2]) {
            case "localhost:3001":
              // console.log("local", result.data.token);
              document.cookie = `token=${result.data.token}; domain=localhost; path=/; samesite=none; secure;`;
              break;
            case "dashboard.tag8.co.in":
              // console.log("testing", result.data.token);
              document.cookie = `token=${result.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
              break;
            case "dashboard.tag8.in":
              // console.log("live", result.data.token);
              document.cookie = `token=${result.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
              break;
            default:
              break;
          }
          localStorage.setItem("logintype", "manual");
          axios
            .get("/api/getUserProfile", {
              headers: {
                Authorization: result.data.token,
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              // console.log("res", res);
              localStorage.setItem("uid", res.data.uid);
              localStorage.setItem("name", res.data.first_name);
              localStorage.setItem("isAdmin", res.data.isAdmin);
              localStorage.setItem("loginemail", email);
              localStorage.setItem("mid", res.data._id);
              // localStorage.setItem("password", password);
              // localStorage.setItem("password", passwordCipherText);
              localStorage.setItem("emailloginemail", email);
              localStorage.setItem("loginemailforgot", email);
              //  localStorage.setItem("passwordloginpassword", passwordCipherText);
              // localStorage.setItem("passwordloginpassword", password);

              window.location.href = "/";
            });
        } else {
          // console.log("res.data is null");
        }
      }
    } catch (error) {
      // console.log(error);
      // console.log("reached the catch block");
    }
  };
  const applesignin = async (response) => {
    // console.log(response);
    try {
      if (!response?.error) {
        const result = await axios.post("/api/loginwithapple", response);
        // console.log(result);
        if (result.data.token) {
          // console.log(result.data);
          var url = document.URL.split("/");

          switch (url[2]) {
            case "localhost:3000":
              // console.log("local", result.data.token);
              document.cookie = `token=${result.data.token}; domain=localhost; path=/; samesite=none; secure;`;
              break;
            case "dashboard.tag8.co.in":
              // console.log("testing", result.data.token);
              document.cookie = `token=${result.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
              break;
            case "dashboard.tag8.in":
              // console.log("live", result.data.token);
              document.cookie = `token=${result.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
              break;

            default:
              break;
          }
          localStorage.setItem("logintype", "manual");
          axios
            .get("/api/getUserProfile", {
              headers: {
                Authorization: result.data.token,
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              // console.log("res", res);
              localStorage.setItem("uid", res.data.uid);
              localStorage.setItem("name", res.data.first_name);
              localStorage.setItem("isAdmin", res.data.isAdmin);
              localStorage.setItem("loginemail", email);
              localStorage.setItem("mid", res.data._id);
              // localStorage.setItem("password", password);
              // localStorage.setItem("password", passwordCipherText);
              localStorage.setItem("emailloginemail", email);
              localStorage.setItem("loginemailforgot", email);
              //  localStorage.setItem("passwordloginpassword", passwordCipherText);
              // localStorage.setItem("passwordloginpassword", password);

              /* 

                const urlpathToredirect = localStorage.getItem("pathname")
                if(urlpathToredirect){
                 localStorage.clear("pathname")
                 window.location.hash = "#" + urlpathToredirect
                }
                else{
              window.location.href = "/";
                }
                
                */
              window.location.href = "/";
            });
        } else {
          // console.log("res.data is null");
        }
      }
    } catch (error) {
      // console.log(error);
      // console.log("reached catch blog applesignin");
    }
  };

  const handleClickShowPassword = () => setPasswordType((prev) => !prev);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // const login = useGoogleOneTapLogin({
  //   onSuccess: (credentialResponse) => {
  //     console.log(credentialResponse);
  //   },
  //   onError: () => {
  //     console.log("Login Failed");
  //   },
  // });

  return (
    <>
      <div style={{ height: "105vh" }}>
        <Navbar />
        <div class="login__background">
          <div className="login__box">
            <div className="wel">
              <h3
                style={{
                  textAlign: "center",
                  fontFamily: "Prompt",
                  // fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                Welcome
              </h3>
              <h4 className="myCustomSecondText">
                Thank you for choosing to secure your valuable with us! You are
                just a few clicks away from securing your valuable
              </h4>
            </div>
            <div class="loginbox">
              <div className="loginicon sm">
                <div className="socialicons">
                  <div className="socialloginbtn">
                    <GoogleLogin
                      onSuccess={responseGoogle}
                      onError={responseGoogle}
                      context="signin"
                      type={"icon"}
                      size={"large"}
                      // width={"10rem"}
                      // shape={"rectangular"}
                      text={"signin_with"}

                      // context="signin"
                      //type={"standard"}
                      //theme={"filled_black"}
                    />

                    <GoogleLoginPopup
                      openGLPopup={openGLPopup}
                      setOpenGLPopup={setOpenGLPopup}
                    />
                  </div>

                  <div className="socialloginbtn-icon">
                    <AppleSignin
                      authOptions={{
                        clientId: "com.tag8.in.dashboard",

                        scope: "email name",

                        redirectURI: redirect_uri,

                        state: "state",

                        nonce: "nonce",

                        usePopup: true,
                      }}
                      noDefaultStyle={true}
                      className="apple-auth-btn"
                      buttonExtraChildren="Sign in with Apple"
                      render={(props) => (
                        <IconButton
                          onClick={props.onClick}
                          style={{ color: "black" }}
                        >
                          <Apple />
                        </IconButton>
                      )}
                      onSuccess={(response) => applesignin(response)} // default = undefined
                      onError={(error) => console.error(error)} // default = undefined
                    />
                  </div>

                  {/* <div className="socialloginbtn-icon"> */}
                  {/* 
                     <FacebookLogin
                    appId="1875135639493747"
                    callback={loginwithfb}
                    autoLoad = {false}
                    render={(renderProps) => (
                      <IconButton
                        onClick={renderProps.onClick}
                        style={{ color: "#1976d2" }}
                      >
                        <FacebookIcon />
                      </IconButton>
                    )}
                    textButton="&nbsp;&nbsp;Sign In with Facebook"
                  ></FacebookLogin>
                    */}

                  {/* </div> */}
                  <h4
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      fontFamily: "prompt",
                      letterSpacing: "0.5px",
                      color: "Red",
                    }}
                  >
                    {loginmessage}
                  </h4>
                </div>

                <div className="divider-container">
                  <hr className="divider" />
                  <div className="divider-text">OR</div>
                </div>
              </div>

              <div class="login__rightColumn">
                {/* <p
                  className="google-login-button mb-2"
                  onClick={responseGoogle}
                >
                  Sign up Or login with Google
                </p> */}
                {/*  <GoogleLogin
                  onSuccess={responseGoogle}
                  onError={responseGoogle}
                  context="signin"
                  type={"standard"}
                  theme={"filled_black"}
                  size={"large"}
                  // width={"10rem"}
                  shape={"rectangular"}
                  text={"signin_with"}
                />
                <GoogleLoginPopup
                  openGLPopup={openGLPopup}
                  setOpenGLPopup={setOpenGLPopup}
              /> */}

                <div
                  className="otherlogin "
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="socialloginbtn">
                    <GoogleLogin
                      onSuccess={responseGoogle}
                      onError={responseGoogle}
                      context="signin"
                      type={"standard"}
                      theme={"filled_black"}
                      size={"large"}
                      width={"200"}
                      shape={"rectangular"}
                      text={"signin_with"}

                      // context="signin"
                      //type={"standard"}
                      //theme={"filled_black"}
                    />
                    <GoogleLoginPopup
                      openGLPopup={openGLPopup}
                      setOpenGLPopup={setOpenGLPopup}
                    />
                  </div>

                  <div className="socialloginbtn">
                    <div className="lg" style={{ cursor: "pointer" }}>
                      <AppleSignin
                        authOptions={{
                          clientId: "com.tag8.in.dashboard",

                          scope: "email name",

                          redirectURI: redirect_uri,

                          state: "state",

                          nonce: "nonce",

                          usePopup: true,
                        }}
                        noDefaultStyle={true}
                        className="apple-auth-btn"
                        buttonExtraChildren="Sign in with Apple"
                        // render={(
                        //   renderProps //Custom Apple Sign in Button
                        // ) => (
                        //   <button
                        //     onClick={renderProps.onClick}
                        //     style={{
                        //       backgroundColor: "white",
                        //       // padding: 10,
                        //       border: "1px solid black",
                        //       fontFamily: "none",
                        //       lineHeight: "25px",
                        //       fontSize: "15px",
                        //     }}
                        //   >
                        //     <i className="fa-brands fa-apple px-2 "></i>
                        //     Continue with Apple
                        //   </button>
                        // )}
                        // render={(props) => (
                        //   <IconButton
                        //     onClick={props.onClick}
                        //     style={{ color: "black" }}
                        //   >
                        //     <Apple />
                        //   </IconButton>
                        // )}
                        onSuccess={(response) => applesignin(response)}
                        onError={(error) => console.error(error)}
                      />
                    </div>

                    <div className="sm"></div>
                  </div>
                  <h4
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      fontFamily: "prompt",
                      letterSpacing: "0.5px",
                      color: "Red",
                    }}
                  >
                    {loginmessage}
                  </h4>
                  {/*
                
               <FacebookLogin
                  appId="1875135639493747"
                  callback={loginwithfb}
                  autoLoad = {false}
                  icon={<i className="fa fa-facebook"></i>}
                  cssClass="btnFacebook"
                  textButton="&nbsp;&nbsp;Sign In with Facebook"
                ></FacebookLogin>
                 <AppleLogin
                  clientId="com.digitaltag.tag8"
                  redirectURI="https://dashboard.tag8.co.in/"
                  callback={applesignin} // Catch the response
                ></AppleLogin>
                */}
                </div>
              </div>
              <div className="dividerlg">
                <div className="divider-container">
                  <div className="vl ">
                    <span className="vl-innertext">OR</span>
                  </div>
                </div>
              </div>

              {/* <div className="divider-text">OR</div> */}
              <div
                className="login_leftcolum"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <form className="login__form">
                  <div className="signtext">
                    <p>Sign in </p>
                  </div>

                  <FormControl sx={{ m: 1 }} variant="outlined">
                    <TextField
                      type="email"
                      label="Email ID"
                      value={email}
                      onChange={handleChangeEmail}
                      size="small"
                      variant="outlined"
                      required
                      error={!!emailError}
                      helperText={
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            fontFamily: "prompt",
                            letterSpacing: "0.5px",
                            color: "Red",
                          }}
                        >
                          {message || emailError}
                        </span>
                      }
                      InputProps={{
                        inputProps: {
                          pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                          style: { boxShadow: "none", height: "2em" },
                        },
                      }}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 1 }} variant="outlined">
                    <TextField
                      fullWidth
                      type={passwordType ? "text" : "password"}
                      value={password}
                      onChange={handleChangePassword}
                      variant="outlined"
                      label="Password"
                      error={!!passwordError}
                      // helperText={passwordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {passwordType === "text" ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        inputProps: {
                          style: { boxShadow: "none", height: "2em" },
                        },
                      }}
                      helperText={
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            fontFamily: "prompt",
                            letterSpacing: "0.5px",
                            color: "Red",
                          }}
                        >
                          {passwordmessage || passwordError}
                        </span>
                      }
                      size="small"
                    />
                  </FormControl>

                  <div className="login__forgot">
                    <button
                      type="button"
                      disabled={!email}
                      onClick={handleShow}
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Forgot Password?
                    </button>
                  </div>

                  {email ? (
                    <Dialog
                      maxWidth="xs"
                      open={show}
                      onClose={handleClose}
                      aria-labelledby="form-dialog-title"
                    >
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{ position: "absolute", right: 8, top: 8 }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={
                            "https://storage.googleapis.com/pettag/qr/assets/email.png"
                          }
                          alt="Email Logo"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginBottom: "0.8rem",
                            // display: "block",
                            margin: "15px 2px",
                          }}
                        />
                      </div>
                      <h3
                        style={{
                          textAlign: "center",
                          fontFamily: "prompt, sans-serif",
                          fontSize: "20px",
                        }}
                      >
                        Verify your Email Address
                      </h3>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.3em",
                          padding: "0 2em",
                          // width: "100%",
                        }}
                      >
                        <TextField
                          value={otp}
                          onChange={(e) => {
                            setOtp(e.target.value);
                          }}
                          placeholder="Enter 6 digit OTP"
                          variant="outlined"
                          size="small"
                          className="otp-css"
                          helperText={
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "prompt",
                                letterSpacing: "0.5px",
                                color: "Red",
                              }}
                            >
                              {message}
                            </span>
                          }
                          // style={{
                          //   borderRadius: "5px",
                          //   width: "100%",
                          //   padding: "0rem 5rem",
                          // }}
                        />
                        <label
                          style={{
                            textAlign: "center",
                            fontFamily: "prompt, sans-serif",
                            fontSize: "11px",
                          }}
                        >
                          Email verification code has been sent on your email
                        </label>

                        <div
                          className="mb-4"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "1em",
                              gap: "1em",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              className="login-verfiy-btns"
                              onClick={verifyOTP}
                              style={{
                                backgroundColor: "#fcbc1b",
                              }}
                            >
                              Verify
                            </Button>

                            {/* <Button
                          variant="outlined"
                          className="login-cancel-btns"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button> */}
                          </span>
                        </div>
                        <div className="Timer-fix">
                          {counter > 0 ? (
                            <p>
                              <Timer counter={counter} /> Time left!
                            </p>
                          ) : (
                            // <p>Time's up!</p>
                            ""
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5em",
                            textAlign: "center",
                            margin: "-1rem 2rem 1rem 2rem",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "prompt",
                              fontWeight: "normal",
                              color: isResendDisabled ? "#aaa" : "#fcbc1b",
                              textDecoration: "underline",
                              fontSize: "1em",
                              cursor: isResendDisabled
                                ? "not-allowed"
                                : "pointer",
                            }}
                            onClick={() =>
                              isResendDisabled ? null : resendOTP()
                            }
                          >
                            Resend OTP
                          </span>
                          {showLoader && (
                            <div
                              className="loader-container"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 2, // Ensure loader is above button
                              }}
                            >
                              <div className="loader">
                                <img
                                  src={
                                    "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                                  }
                                  alt="Loading..."
                                  loading="lazy"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              </div>
                            </div>
                          )}
                          <label
                            style={{
                              fontSize: "0.7125rem",
                              color: "#4c4b4b",
                              fontFamily: "prompt, sans-serif",
                            }}
                          >
                            If email is not received, please check the spam
                            folder or click on the resend button.
                          </label>
                        </div>
                      </div>
                    </Dialog>
                  ) : (
                    ""
                  )}

                  <Dialog
                    maxWidth="xs"
                    open={changePassword}
                    onClose={() => setChangePassword(false)}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogContent
                      className="change_password_div"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "2rem 2rem 0rem 0rem",
                      }}
                    >
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setChangePassword(false)}
                        style={{
                          position: "absolute",
                          top: "0.5rem",
                          right: "0.5rem",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <h1
                        style={{
                          fontSize: "20px",
                          color: "#4c4b4b",
                          fontFamily: "prompt, sans-serif",
                        }}
                      >
                        New Password
                      </h1>

                      <Box
                        // className="mb-4 inner-change-pwd-div"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <TextField
                          value={email}
                          size="small"
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                        <TextField
                          // className="pl-3 new_pwd input-fd input__field__dialogs"
                          style={{ width: "85%" }}
                          type={passwordType}
                          placeholder="Password"
                          value={newPassword}
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                            const txt = e.target.value;

                            if (txt.length >= 8) {
                              valid("islen");
                            } else {
                              invalid("islen");
                            }

                            if (txt.match(/[A-Z]/) != null) {
                              valid("capital");
                            } else {
                              invalid("capital");
                            }

                            if (txt.match(/[a-z]/) != null) {
                              valid("small");
                            } else {
                              invalid("small");
                            }

                            if (txt.match(/[0-9]/) != null) {
                              valid("isnum");
                            } else {
                              invalid("isnum");
                            }
                          }}
                          required
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  size="small"
                                >
                                  {passwordType === "text" ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <div style={{ paddingLeft: "22px", fontSize: "14px" }}>
                          <span style={{ display: characterLength }} id="islen">
                            Password must be atleat 8 characters
                          </span>

                          <span style={{ display: validPassword }} id="issp">
                            Password must contain - @/*/_/-
                          </span>

                          <span style={{ display: validPassword }} id="isnum">
                            Atleast one number
                          </span>

                          <span style={{ display: validPassword }} id="capital">
                            Atleast one uppercase letter
                          </span>

                          <span style={{ display: validPassword }} id="small">
                            Atleast one lowercase letter
                          </span>
                        </div>

                        <Button
                          className="verifyOtp-button"
                          disabled={submitDisabled}
                          style={{ margin: "10px 80px" }}
                          onClick={updatePassword}
                          // variant="contained"
                        >
                          Submit
                        </Button>

                        {/* <Button
        className="otp-buttons"
        style={{ margin: "10px 80px" }}
        onClick={() => setChangePassword(false)}
        variant="outlined"
      >
        Cancel
      </Button> */}
                      </Box>
                    </DialogContent>
                  </Dialog>
                  {/* <div className="checkbox-css">
                <input
                  type="checkbox"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    // marginTop: "-11px",
                    // marginRight: "10px",
                    // marginLeft: "10px",
                  }}
                  onClick={() =>
                    setPasswordType(
                      passwordType === "password" ? "text" : "password"
                    )
                  }
                />

                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0.5rem 0rem 0.5rem 0rem",
                  }}
                >
                  Show Password
                </p>
              </div> */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // alignItems: "center",
                    }}
                  >
                    <button
                      id="submit__button"
                      onClick={handleSubmit}
                      // disabled={isSubmitDisabled}
                    >
                      LOG IN
                    </button>
                  </div>
                  <div className="login__register mt-2">
                    <span
                      style={{
                        fontFamily: "prompt, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Don't have an account yet?
                    </span>
                    <Link
                      to="/signup"
                      style={{
                        fontFamily: "prompt, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      Sign Up
                    </Link>
                  </div>
                </form>
              </div>

              {showLoader && (
                <div className="loader-container">
                  <div className="loader">
                    <img
                      src={
                        "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                      }
                      alt="Loading..."
                      loading="lazy"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                </div>
              )}
              {/* <p
              className="text-center feeling-lazy"
              style={{ color: "lightgray" }}
            >
              ---------- Feeling Lazy? ----------
            </p> */}
              {/* <div className="login__register mt-2">Sign Up Or Login</div> */}
            </div>
          </div>
        </div>
        {/* <div className="login"> */}

        {/* <div className="login__rightColumn"></div> */}
        {/* </div> */}
      </div>
    </>
  );
}

export default Login;
