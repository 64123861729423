import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

function Imagecarousel({bag_image1,bag_image2}) {
    const [imagearray,setimagearray] = useState([])
    useEffect(()=>{
        const images = [bag_image1,bag_image2]
       setimagearray(images)
    },[])
  return (
    <div>
        {
            imagearray?.length ? <>
            <Carousel 
            autoPlay = {false}
            navButtonsAlwaysInvisible={true}
            >
                {
                    imagearray?.map((e,i)=>{
                        return <>
                    <Paper>
                    <img
                     key={i}
                  src={
                   e
                      ? e
                      : "https://storage.googleapis.com/pettag/qr/assets/luggage.png"
                  }
                  alt="bag"
                  style={{
                    width: 100,
                    height: 100,
                  }}
                  // onLoad={handleImageLoad}
                  loading="lazy"
                />
                    </Paper>
                        </>
                    })
                }
            </Carousel>
            </> : null
        }
      
    </div>
  )
}

export default Imagecarousel
