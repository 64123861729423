import React, { useEffect, useState } from "react";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { BsFillNutFill } from "react-icons/bs";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { format } from "timeago.js";
import { switchUrls } from "../../api/index.js";
function Dolphincard({ props, location, userid }) {
  const [showLoader, setShowLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const baseUrl = switchUrls("human");
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) == " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [address, setaddress] = useState("");
  const sendsos = async ({
    person_fcm,
    person_latitude,
    person_longitude,
    fcm_token,
    first_name,
    last_name,
    person_profile_img_link,
  }) => {
    try {
      const fcmData = {
        titleExtra: "sos_alert_message",
        bodyExtra: "sos_alert_message",
        notificationType: "sos_alert_message",
        locationLat: person_latitude,
        locationLon: person_longitude,
        theName: first_name + " " + last_name,
        theImg: person_profile_img_link,
        foundTimestamp: Date.now(),
      };
      const main = {
        to: person_fcm,
        data: fcmData,
        android: '{"priority": "high"}',
        priority: 10,
        webpush: '{ "headers": { "Urgency": "high" } }',
      };

      const { data } = await axios.post(`${baseUrl}/api/human/sos`, main, {
        headers: { Authorization: readCookie("token") },
      });
      // console.log(data);
      alert("sos notification send");
    } catch (error) {
      // console.log(error);
    }
  };
  const getaddress = async (
    lat = props?.person_latitude,
    lng = props?.person_longitude
  ) => {
    try {
      const { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDwN2iN1xjbr0KzymtrAVEPGNgHKEFvr4A`
      );
      if (data?.results?.length) {
        const address = data.results[0].formatted_address;
        // console.log(address);
        setaddress(address);
      } else {
        setaddress("GPS is turned off");
      }
    } catch (error) {
      // console.log(error);
      setaddress("GPS is turned off");
    }
  };
  useEffect(() => {
    getaddress();
  }, []);

  const convertCamelCase = (sentense) => {
    if (typeof sentense !== "string") {
      return sentense;
    }
    const words = sentense?.split(" ");

    const capitalizeFirstWord = words?.map((word) => {
      if (word.length > 0) {
        return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
      } else {
        return word;
      }
    });
    const capitalizeFirstWordSentese = capitalizeFirstWord?.join(" ");
    return capitalizeFirstWordSentese;
  };
  const calculateTimeAgo = (timestamp) => {
    const currentTimestamp = moment();
    const pastTimestamp = moment(timestamp);
    const duration = moment.duration(currentTimestamp.diff(pastTimestamp));

    if (duration.asSeconds() < 60) {
      return `${Math.floor(duration.asSeconds())} seconds ago`;
    } else if (duration.asMinutes() < 60) {
      return `${Math.floor(duration.asMinutes())} minutes ago`;
    } else if (duration.asHours() < 24) {
      return `${Math.floor(duration.asHours())} hours ago`;
    } else {
      return `${Math.floor(duration.asDays())} days ago`;
    }
  };
  return (
    <>
      <div className="card_main">
        <div className="card_main_div">
          <div className="imagedetails">
            <div>
              {props?.person_profile_img_link ? (
                <img
                  className="pet-img"
                  src={props?.person_profile_img_link}
                  alt=""
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
              ) : (
                <img
                  className="pet-img"
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/human.png"
                  }
                  alt=""
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
              )}

              <div className="mobile-view">
                <ul
                  className="tagdetails"
                  style={{
                    listStyle: "none",
                    color: "black",
                    textAlign: "right",
                    marginRight: 20,
                    fontSize: "12px",
                    lineHeight: "normal",
                  }}
                >
                  {/* <li className="petownerid">
                  <span>{tag_number}</span>
                </li> */}

                  <li className="petstatus mobile-view-petstatus">
                    {location[userid] ? (
                      <div>
                        <Tooltip title="location is sharing" aria-label="add">
                          <button
                            className="activatedeactivatebutton bg-success mt-4"
                            style={{ marginRight: "4px" }}
                          ></button>
                        </Tooltip>
                        <span>Connected</span>
                      </div>
                    ) : (
                      <div>
                        <Tooltip
                          title="location is not sharing"
                          aria-label="add"
                        >
                          <button
                            className="activatedeactivatebutton bg-danger mt-4"
                            style={{ marginRight: "4px" }}
                          ></button>
                        </Tooltip>
                        <span>Disconnected</span>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="middle-content">
            <ul className="geniecardtext-ul">
              <li className="petdetails">
                <span className="lebal">Name: </span>
                <span className="lebal-content">
                  {convertCamelCase(
                    props?.first_name
                      ? props?.first_name + " " + props?.last_name?.slice(0, 15)
                      : null
                  )}
                </span>
              </li>
              <li className="petdetails">
                <span className="lebal">Last Seen: </span>
                <span className="lebal-content">
                  {format(
                    props?.person_lastest_update_datetime,
                    "en_US"
                  ).replace(/^in\s/, "") +
                    " " +
                    "ago"}
                </span>
              </li>

              <li className="petdetails">
                <span className="lebal">Last location: </span>
                <span className="lebal-content">{address}</span>
              </li>
            </ul>
            <ul
              className="tagdetails laptop-view"
              style={{
                listStyle: "none",
                color: "black",
                textAlign: "right",
                marginRight: 20,
                // fontSize: "12px",
                lineHeight: "normal",
              }}
            >
              <li className="petstatus">
                {location[userid] ? (
                  <div>
                    <Tooltip title="location is sharing" aria-label="add">
                      <button
                        className="activatedeactivatebutton bg-success mt-4"
                        style={{ marginRight: "4px" }}
                      ></button>
                    </Tooltip>
                    <span>Connected</span>
                  </div>
                ) : (
                  <div>
                    <Tooltip title="location is not sharing" aria-label="add">
                      <button
                        className="activatedeactivatebutton bg-danger mt-4"
                        style={{ marginRight: "4px" }}
                      ></button>
                    </Tooltip>
                    <span>Disconnected</span>
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="tagdetailsandicons">
            <div className="icons" style={{ marginRight: "30px" }}>
              {props?.person_latitude !== "0" &&
              props?.person_longitude !== "0" ? (
                <>
                  <Tooltip title="Click here to see location" aria-label="add">
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${props?.person_latitude},${props?.person_longitude}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="images-css"
                        src={"./loc.png"}
                        alt="Pet Icon"
                      />
                    </a>
                  </Tooltip>
                </>
              ) : null}

              <Tooltip
                style={{ cursor: "pointer" }}
                title="Click here to send sos notification"
                aria-label="add"
                onClick={() => sendsos(props)}
              >
                <img className="images-css" src={"./sos.png"} alt="Pet Icon" />
              </Tooltip>
              <i
                id="simple-menu"
                aria-controls="menu-list-grow"
                className="fa fa-ellipsis-v menu-drop"
                onClick={handleClick}
                style={{
                  color: "#ffc200f7",
                  fontSize: 27,
                  marginTop: 5,
                  // marginRight: "-40px",
                  // marginLeft: "-10px",
                  // paddingLeft: "10px",
                  // paddingRight: "10px",
                }}
              ></i>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <p
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${props?.person_latitude},${props?.person_longitude}`
                      );
                    }}
                  >
                    Last Location
                  </p>
                </MenuItem>
                <MenuItem>
                  <p onClick={() => sendsos(props)}>Send sos notification</p>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>

        {showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dolphincard;
