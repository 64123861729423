import React, { Component } from "react";
import "./detail_history.css";
import { Col, Row, Table } from "react-bootstrap";

export default class Details_history extends Component {
  state = {
    data_history: [],
  };

  componentDidMount() {
    fetch("/api/pet/getMedicalNotification/" + this.props.uid)
      .then((response) => response.json())
      .then((data_list) => {
        this.setState({ data_history: data_list });
      });
  }

  render() {
    return (
      <div className="calendar_container_det">
        <div className="Med_tit"> All Medical details </div>
        <Row>
          <Col>
            <Table striped bordered hover className="table1">
              <thead>
                <tr>
                  <th>Upcoming</th>
                </tr>
              </thead>

              <tbody>
                {this.state.data_history.map((data, index) => {
                  if (new Date(data.StartTime).getTime() > new Date().getTime())
                    return (
                      <tr key={index}>
                        <td>{data.Subject}</td>
                      </tr>
                    );
                })}
              </tbody>
            </Table>
          </Col>

          <Col>
            <Table striped bordered hover className="table1">
              <thead>
                <tr>
                  <th>Past</th>
                </tr>
              </thead>

              <tbody>
                {this.state.data_history.map((data, index) => {
                  if (
                    new Date(data.StartTime).getTime() < new Date().getTime() &&
                    data.Done
                  )
                    return (
                      <tr key={index}>
                        <td>{data.Subject}</td>
                      </tr>
                    );
                })}
              </tbody>
            </Table>
          </Col>

          <Col>
            <Table striped bordered hover className="table1">
              <thead>
                <tr>
                  <th>Overdue</th>
                </tr>
              </thead>

              <tbody>
                {this.state.data_history.map((data, index) => {
                  if (
                    new Date(data.StartTime).getTime() < new Date().getTime() &&
                    !data.Done
                  )
                    return (
                      <tr key={index}>
                        <td>{data.Subject}</td>
                      </tr>
                    );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}
