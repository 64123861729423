import React from "react";
import "./medicalcard.component.css";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

function Medicalcard({ props, type }) {
  function toTitleCase(str) {
    if (str !== undefined) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  }
  const convertISOStringToMonthDay = (date) => {
    const tempDate = new Date(date);
    const day = tempDate.getUTCDate().toString().padStart(2, "0");
    const month = tempDate.toLocaleString("default", { month: "short" });
    const year = tempDate.getUTCFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  };
  return (
    <>
      <div className="cards-component-wrapper">
        {type === "vaccine" && (
          <>
            <div className="cards-component-container">
              <div className="formbox">
                <div className="cards-component-main-wrapper-flex">
                  <Box
                    component="form"
                    style={{ width: "100%" }}
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Vaccination Name"
                        value={
                          props.Vaccination_name.charAt(0).toUpperCase() +
                          props.Vaccination_name.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                      <TextField
                        disabled
                        label="Vaccinated Date"
                        value={convertISOStringToMonthDay(
                          props?.VaccinationAdministration_date
                        )}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                    <div className="twodiv">
                      {props?.vaccinedoctor_firstname && (
                        <TextField
                          disabled
                          label="Doctor Firstname"
                          value={
                            "Dr" +
                            " " +
                            props?.vaccinedoctor_firstname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.vaccinedoctor_firstname?.slice(1)
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}
                      {props?.vaccinedoctor_lastname && (
                        <TextField
                          disabled
                          label="Doctor Lastname"
                          value={
                            props?.vaccinedoctor_lastname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.vaccinedoctor_lastname?.slice(1)
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}
                    </div>
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Vaccinated Notes"
                        value={
                          props?.VaccinationNotes?.charAt(0).toUpperCase() +
                          props?.VaccinationNotes?.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </>
        )}
        {type === "allergy" && (
          <>
            <div className="cards-component-container">
              <div className="formbox">
                <div className="cards-component-main-wrapper-flex">
                  <Box
                    component="form"
                    style={{ width: "100%" }}
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Allergy Name"
                        value={
                          props?.Allergy_name?.charAt(0).toUpperCase() +
                          props?.Allergy_name?.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                      <TextField
                        disabled
                        label="Diagnosed on"
                        value={convertISOStringToMonthDay(
                          props?.AllergyDiagnosis_date
                        )}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                    <div className="twodiv">
                      {props.allergydoctor_firstname && (
                        <TextField
                          disabled
                          label="Doctor Firstname"
                          value={
                            "Dr" +
                            " " +
                            props?.allergydoctor_firstname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.allergydoctor_firstname?.slice(1)
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}
                      {props.allergydoctor_lastname && (
                        <TextField
                          disabled
                          label="Doctor Lastname"
                          value={
                            props?.allergydoctor_lastname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.allergydoctor_lastname?.slice(1)
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}
                    </div>
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Allergy Notes"
                        value={
                          props?.AllergyNotes?.charAt(0).toUpperCase() +
                          props?.AllergyNotes?.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </>
        )}
        {type === "surgery" && (
          <>
            <div className="cards-component-container">
              <div className="formbox">
                <div className="cards-component-main-wrapper-flex">
                  <Box
                    component="form"
                    style={{ width: "100%" }}
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Surgery Name"
                        value={
                          props?.Surgery_name?.charAt(0).toUpperCase() +
                          props?.Surgery_name?.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                      <TextField
                        disabled
                        label="Performed on"
                        value={convertISOStringToMonthDay(props?.Surgery_date)}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                    <div className="twodiv">
                      {props?.surgerydoctor_firstname && (
                        <TextField
                          disabled
                          label="Doctor Firstname"
                          value={
                            "Dr" +
                            " " +
                            props?.surgerydoctor_firstname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.surgerydoctor_firstname?.slice(1)
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}
                      {props?.surgerydoctor_lastname && (
                        <TextField
                          disabled
                          label="Doctor Lastname"
                          value={
                            props?.surgerydoctor_lastname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.surgerydoctor_lastname?.slice(1)
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}
                    </div>
                    <div className="twodiv">
                      <TextField
                        label="Surgery Notes"
                        value={
                          props?.SurgeryNotes?.charAt(0).toUpperCase() +
                          props?.SurgeryNotes?.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                      />
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </>
        )}
        {type === "med" && (
          <>
            <div className="cards-component-container">
              <div className="formbox">
                <div className="cards-component-main-wrapper-flex">
                  <Box
                    style={{ width: "100%" }}
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Medication Name"
                        value={
                          props?.Medication_name?.charAt(0).toUpperCase() +
                          props?.Medication_name?.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                      <TextField
                        disabled
                        label="Start date"
                        value={convertISOStringToMonthDay(
                          props?.Medication_startdate
                        )}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                    <div className="twodiv">
                      {props?.medicinedoctor_firstname && (
                        <TextField
                          disabled
                          label="Doctor Firstname"
                          value={
                            "Dr" +
                            " " +
                            props?.medicinedoctor_firstname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.medicinedoctor_firstname.slice(1)
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}
                      {props?.medicinedoctor_lastname && (
                        <TextField
                          disabled
                          label="Doctor Lastname"
                          value={
                            props?.medicinedoctor_lastname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.medicinedoctor_lastname?.slice(1)
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}
                    </div>
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Medication Notes"
                        value={
                          props?.MedicationNotes?.charAt(0).toUpperCase() +
                          props?.MedicationNotes?.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </>
        )}
        {type === "medicalcondition" && (
          <>
            <div className="cards-component-container">
              <div className="formbox">
                <div className="cards-component-main-wrapper-flex">
                  <Box
                    style={{ width: "100%" }}
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Medical condition"
                        value={
                          props?.Medicalcondition_type?.charAt(
                            0
                          ).toUpperCase() +
                          props?.Medicalcondition_type?.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                      <TextField
                        disabled
                        label="Diagnosed on"
                        value={convertISOStringToMonthDay(
                          props?.MedicalconditionDiagnosed_date
                        )}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                    <div className="twodiv">
                      {props?.medicaldoctor_firstname && (
                        <TextField
                          disabled
                          label="Doctor Firstname"
                          value={
                            "Dr" +
                            " " +
                            props?.medicaldoctor_firstname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.medicaldoctor_firstname?.slice(1)
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}

                      {props?.medicaldoctor_lastname && (
                        <TextField
                          disabled
                          label="Doctor Lastname"
                          value={
                            props?.medicaldoctor_lastname
                              ?.charAt(0)
                              .toUpperCase() +
                            props?.medicaldoctor_lastname?.slice(1)
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#666" },
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                        />
                      )}
                    </div>
                    <div className="twodiv">
                      <TextField
                        label="Notes"
                        value={
                          props?.MedicalconditionNotes?.charAt(
                            0
                          ).toUpperCase() +
                          props?.MedicalconditionNotes?.slice(1)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                      />
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </>
        )}
        {type === "Measurement" && (
          <>
            <div
              className="cards-component-container"
              style={{ marginBottom: "2rem" }}
            >
              <div className="formbox">
                <div className="cards-component-main-wrapper-flex">
                  <Box
                    style={{ width: "100%" }}
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Height"
                        value={props?.Height + "Cm"}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                      <TextField
                        disabled
                        label="Weight"
                        value={props?.Weight + "Kg"}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Measured On"
                        value={convertISOStringToMonthDay(
                          props?.Measurement_date
                        )}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                    <div className="twodiv">
                      <TextField
                        disabled
                        label="Notes"
                        value={props?.MeasurementNotes}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#666" },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Medicalcard;
