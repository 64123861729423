import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";
import "./updatecards.component.css";
import Button from "@material-ui/core/Button";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import * as AiIcons from "react-icons/ai";
import { switchUrls } from "../../../api/index.js";
const baseURL = switchUrls("genie");
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let reports = [];

export default class SurgeryUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      selectedImage: null,
      surgery_reminder: false,
      tag_number: localStorage.getItem("tag_number"),
      Type: "Surgery",
      surgery_id: this.props.surgery_id,
      Surgery_name: "",
      SurgeryDoctor_name: "",
      Surgery_date: "",
      SurgerySeverity: "",
      SurgeryNotes: "",
      SurgeryTitle: "",
      SurgeryEverynumber: "",
      SurgeryEvery: "",
      SurgeryNext: "",
      SurgeryRemind_me: "",
      SurgeryReminderNotes: "",
      SurgeryDone: false,
      Surgery_Reportname: "",
      Surgery_Reportlocation: "",
      Surgery_Reportnames: [],
      Surgery_Reportlocations: [],
      message: "",
      docs: [],
    };
  }

  join = (t, a, s) => {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);

      return f.format(t);
    }

    return a.map(format).join(s);
  };

  month = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];

  componentDidMount() {
    axios
      .get(`${baseURL}/api/pet/getMedicalDetails/` + this.state.tag_number)
      .then((response) => {
        response.data[0].Surgery.map((elm) => {
          if (this.state.surgery_id === elm.surgery_id) {
            this.setState({
              Surgery_name: elm.Surgery_name,
              SurgeryDoctor_name: elm.SurgeryDoctor_name,
              Surgery_date: this.join(
                new Date(elm.Surgery_date),
                this.month,
                "-"
              ),
              SurgerySeverity: elm.SurgerySeverity,
              SurgeryNotes: elm.SurgeryNotes,
              SurgeryDone: elm.SurgeryDone,
              SurgeryTitle: elm.SurgeryTitle,
              SurgeryNext: elm.SurgeryNext
                ? this.join(new Date(elm.SurgeryNext), this.month, "-")
                : "",
              SurgeryRemind_me: elm.SurgeryRemind_me,
              SurgeryReminderNotes: elm.SurgeryReminderNotes,
              Surgery_Reportname: elm.Surgery_Reportname,
              Surgery_Reportlocation: elm.Surgery_Reportlocation,
              Surgery_Reportnames: elm.Surgery_ReportsNames,
              Surgery_Reportlocations: elm.Surgery_ReportsLocations,
            });
          }
        });

        for (let i = 0; i < this.state.Surgery_Reportnames.length; i++) {
          let obj = {
            name: this.state.Surgery_Reportnames[i],
            location: this.state.Surgery_Reportlocations[i],
          };

          reports.push(obj);
        }

        this.setState({ docs: reports });
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  setReports = () => {
    for (let i = 0; i < this.state.Surgery_ReportsNames.length; i++) {
      let obj = {
        name: this.state.Surgery_ReportsNames[i],
        location: this.state.Surgery_ReportsLocations[i],
      };
      reports.push(obj);
    }
    this.setState({ docs: reports });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  surgeryChangeHandler = (e) => {
    this.setState({ [e.target.name]: !this.state.SurgeryDone });
  };

  handleChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileSize = file.size;

    const fileExtension = fileName.split(".")[1];
    const size = fileSize / (1024 * 1024);

    const regex = /\./gm;

    const isValidFileExtension = [
      "pdf",
      "docx",
      "docs",
      "png",
      "jpg",
      "jpeg",
      "csv",
      "xlsx",
    ].includes(fileExtension);
    const isValidSize = size < 5 ? true : false;
    const isValidFileName = fileName.match(regex).length === 1 ? true : false;

    if (isValidFileExtension && isValidSize && isValidFileName) {
      this.setState({ message: "" });
      this.setState((prevState) => ({
        message: "",
        docs: [...prevState.docs, event.target.files[0]],
      }));
    } else if (!isValidFileName) {
      this.setState({
        message:
          "Oops!! File name is not appropriate. Kindly rename the file and try again.",
      });
    } else if (!isValidSize) {
      this.setState({ message: "File size should be less than 5 MB" });
    } else if (!isValidFileExtension) {
      this.setState({
        message: `File with extension .${fileExtension} are not allowed!`,
      });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();

    axios
      .post(`${baseURL}/api/pet/updateMedicalDetails`, this.state, {
        headers: { Authorization: usertoken },
      })
      .then((res) => {
        const fd = new FormData();

        for (let i = 0; i < this.state.docs.length; i++) {
          fd.append("image", this.state.docs[i]);
        }

        fd.append("tag_number", this.state.tag_number);
        fd.append("Type", this.state.Type);
        fd.append("surgery_id", this.state.surgery_id);

        axios
          .post(`${baseURL}/api/pet/reports-upload`, fd, {
            Accept: "application/json",
          })
          .then((response) => {
            window.location.reload();

            console.info(response);
          })
          .catch((error) => {
            console.error(error.message);
          });
      })
      .catch((error) => {
        console.error(error.message);

        this.props.closeDialog();
      });
  };

  render() {
    const {
      surgery_reminder,
      Surgery_name,
      SurgeryDoctor_name,
      Surgery_date,
      SurgerySeverity,
      SurgeryNotes,
      SurgeryTitle,
      SurgeryEverynumber,
      SurgeryEvery,
      SurgeryNext,
      SurgeryRemind_me,
      SurgeryReminderNotes,
      SurgeryDone,
      Surgery_Reportname,
      Surgery_Reportlocation,
    } = this.state;
    return (
      <div className="update-card-details-form-wrapper">
        <form>
          <h4>Surgery</h4>

          <div className="">
            <div className="update-card-details-form-content">
              <labe>Surgery Name *</labe>

              <input
                type="textbox"
                className="update-card-details-form-input"
                name="Surgery_name"
                required
                value={Surgery_name}
                onChange={this.changeHandler}
              />
            </div>

            <div className="update-card-details-form-content">
              <label>Doctor's Name</label>

              <input
                type="textbox"
                className="update-card-details-form-input"
                name="SurgeryDoctor_name"
                value={SurgeryDoctor_name}
                onChange={this.changeHandler}
              />
            </div>

            <div className="update-card-details-form-content">
              <label>Surgery Date *</label>

              <input
                type="date"
                className="update-card-details-form-input"
                name="Surgery_date"
                required
                value={Surgery_date}
                onChange={this.changeHandler}
              />
            </div>

            <div className="update-card-details-form-content">
              <label>Severity</label>

              <select
                className="update-card-details-form-input"
                name="SurgerySeverity"
                value={this.state.SurgerySeverity}
                onChange={this.changeHandler}
              >
                <option hidden></option>
                <option value="Minor">Minor</option>
                <option value="Medium">Medium</option>
                <option value="Major">Major</option>
                <option value="Critical">Critical</option>
              </select>
            </div>

            <div className="update-card-details-form-content">
              <label>Notes</label>

              <textarea
                rows="2"
                name="SurgeryNotes"
                value={SurgeryNotes}
                onChange={this.changeHandler}
              />
            </div>

            <Button
              onClick={() => {
                this.setState({ surgery_reminder: !surgery_reminder });
              }}
            >
              <AddAlertIcon />
              Set Reminder
            </Button>

            <div className="">
              <div className=""></div>
            </div>

            {surgery_reminder ? (
              <>
                {/* <div className="row ml-1 mr-1 mt-3 p-1 bg-warning">
                      <p className="ml-2 mb-0 font-weight-bold">Surgery</p>
                      <Button
                        className="p-0 myexp-btn"
                        style={{ marginLeft: "80px" }}
                        onClick={() => {
                          this.setState({
                            surgery_reminder: !surgery_reminder,
                          });
                        }}
                      >
                        <ExpandMoreIcon />
                      </Button>
                    </div> */}

                <div className="update-card-details-form-content">
                  <label>Reminder Title</label>

                  <input
                    type="textbox"
                    className="update-card-details-form-input"
                    name="SurgeryTitle"
                    value={SurgeryTitle}
                    onChange={this.changeHandler}
                  />
                </div>

                <div className="update-card-details-form-content">
                  <label>Next Appointment Date</label>

                  <input
                    type="date"
                    className="update-card-details-form-input"
                    name="SurgeryNext"
                    value={SurgeryNext}
                    onChange={this.changeHandler}
                  />
                </div>

                <div className="update-card-details-form-content">
                  <label>Remind Me</label>

                  <select
                    className="update-card-details-form-input"
                    name="SurgeryRemind_me"
                    value={this.state.SurgeryRemind_me}
                    onChange={this.changeHandler}
                  >
                    <option hidden></option>
                    <option value="On The Same Day">On The Same Day</option>
                    <option value="1 Day Before">1 Day Before</option>
                    <option value="2 Days Before">2 Days Before</option>
                    <option value="3 Days Before">3 Days Before</option>
                    <option value="15 Days Before">15 Days Before</option>
                    <option value="1 Month Before">1 Month Before</option>
                  </select>
                </div>

                <div className="update-card-details-form-content">
                  <label>Notes</label>

                  <textarea
                    row="2"
                    name="SurgeryReminderNotes"
                    value={SurgeryReminderNotes}
                    onChange={this.changeHandler}
                  />
                </div>

                <div className="update-card-details-form-checkbox">
                  <label htmlFor="all">Done</label>

                  <input
                    type="checkbox"
                    checked={SurgeryDone}
                    id="all"
                    name="SurgeryDone"
                    value={SurgeryDone}
                    onChange={this.surgeryChangeHandler}
                  />
                </div>
              </>
            ) : (
              ""
            )}

            <div className="update-card-details-uploadReport-container">
              <label>
                <AiIcons.AiOutlineUpload /> Report
              </label>

              <input type="file" onChange={this.handleChange} />

              <label style={{ color: "red" }}>{this.state.message}</label>

              {this.state.docs.length
                ? this.state.docs.map((rep) => (
                    <Tooltip title={rep.name}>
                      <a href={rep.location}>
                        <AiIcons.AiFillFileText />
                      </a>
                    </Tooltip>
                  ))
                : null}

              {Surgery_Reportlocation ? (
                <Tooltip title={Surgery_Reportname}>
                  <a href={Surgery_Reportlocation}>
                    <AiIcons.AiFillFileText />
                  </a>
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="update-card-details-btn-wrapper">
            <button
              className="update-card-details-btns"
              style={{ backgroundColor: "#ffc107", color: "white" }}
              onClick={this.submitHandler}
            >
              Submit
            </button>

            <button
              className="update-card-details-btns"
              style={{
                color: "black",
              }}
              onClick={this.props.closeDialog}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    );
  }
}
