import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import "./card.component.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import petPhoto from "../../assets/passport-icon.png";

// import other from "https://storage.googleapis.com/pettag/qrtagdev/assets/other.png";
import Tooltip from "@material-ui/core/Tooltip";
// import "./passportcard.component.css";
//import "./oldtags.component.css";
import { BsThreeDots } from "react-icons/bs";
function OldTagsCard({
  valuable_type,
  valuable_desc,
  passport,
  Surname,
  givenName,
  nationality,
  placeofissue,
  address,
  dateofissue,
  validityperiod,
  tag_number,
  product_category,
  tagActivateProduct_Description,
  activation_date,
  tag_status,
  lf_status,
}) {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  const token = readCookie("token");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const uid = localStorage.getItem("uid");

  // function activateTag() {
  //   const state = {
  //     tag_number: tag_number
  //   };

  //   axios
  //     .post("/api/passportactiveTag", state, {
  //       headers: {
  //         Authorization: token,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data === "Tag is Activated") {
  //         window.location.href = "/dashboard/" + uid;
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error.message);
  //     });
  // }

  function showPassportDetails() {
    const redirectLink = "/#/passport-lost-found-tag/" + tag_number;
    window.location.href = redirectLink;
  }

  function showExtraDetails() {
    const redirectLink = "/#/lftag-details/" + tag_number;
    window.location.href = redirectLink;
  }

  function activateTag() {
    const mid = localStorage.getItem("mid");

    const state = {
      tag_number: tag_number,
      tag_status: "active",
      lf_status: lf_status,
      valuable_type: valuable_type,
      valuable_desc: valuable_desc,
      product_category: valuable_type,
      create_date: activation_date,
      user: mid,
    };
    axios
      .post("/api/lostfoundtags/deactive", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("res.data--->",res.data);
        if (res.data != null) {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function deactivateTag() {
    const mid = localStorage.getItem("mid");

    const state = {
      tag_number: tag_number,
      tag_status: "deactive",
      lf_status: lf_status,
      valuable_type: valuable_type,
      valuable_desc: valuable_desc,
      product_category: valuable_type,
      create_date: activation_date,
      user: mid,
    };
    axios
      .post("/api/lostfoundtags/deactive", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("res.data--->",res.data);
        if (res.data != null) {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function reportFoundTag() {
    const mid = localStorage.getItem("mid");

    const state = {
      tag_number: tag_number,
      tag_status: tag_status,
      lf_status: "found",
      valuable_type: valuable_type,
      valuable_desc: valuable_desc,
      product_category: valuable_type,
      create_date: activation_date,
      user: mid,
    };
    axios
      .post("/api/lostfoundtags/reportLost", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data != null) {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function reportFound() {
    const mid = localStorage.getItem("mid");

    const state = {
      tag_number: tag_number,
      tag_status: tag_status,
      lf_status: "found",
      valuable_type: valuable_type,
      valuable_desc: valuable_desc,
      product_category: valuable_type,
      create_date: activation_date,
      user: mid,
    };
    axios
      .post("/api/lostfoundtags/reportLost", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data != null) {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  const convertISOStringToMonthDay = (date) => {
    const tempDate = new Date(date);
    const day = tempDate.getUTCDate().toString().padStart(2, "0");
    const month = tempDate.toLocaleString("default", { month: "short" });
    const year = tempDate.getUTCFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    // console.log("event--->", event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setShow(false);
  };

  const foundOrLost = () => {
    //this.reportLostTag()
    if (lf_status === "lost") {
      setShow2(true);
      handleClose();
    } else {
      const redirectLink = "/#/report-lost-found-tags/" + tag_number;
      window.location.href = redirectLink;
    }
  };

  return (
    <div className="card_main">
      <div className="card_main_div">
        <div
          className="imagedetails"
          style={{ margin: 5, display: "flex", justifyContent: "center" }}
        >
          <div>
            <img
              src={
                "https://storage.googleapis.com/pettag/qrtagdev/assets/other.png"
              }
              alt="passport"
              style={{
                width: 100,
                height: 100,
                marginTop: 15,
              }}
            />
            <div className="qrscan-img">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                style={{ width: "20px", height: "20px" }}
              />
              <p style={{ fontSize: "0.85rem" }}>
                <b>{tag_number && tag_number !== "-" ? tag_number : "N/A"}</b>
              </p>
            </div>
            <div className="mobile-view">
              <ul
                className="tagdetails"
                style={{
                  listStyle: "none",
                  color: "black",
                  textAlign: "right",
                  marginRight: 20,
                  fontSize: "12px",
                  lineHeight: "normal",
                }}
              >
                {/* <li className="petownerid">
              <span>{tag_number}</span>
            </li> */}
                {lf_status === "lost" ? (
                  <li className="petloststatus mobile-view-lostpetstatus">
                    <span>Reported Lost</span>
                  </li>
                ) : (
                  <li className="petstatus mobile-view-petstatus">
                    {tag_status === "active" ? (
                      <Tooltip
                        title="Click here to deactivate tag"
                        aria-label="add"
                      >
                        <button
                          className="activatedeactivatebutton bg-success mt-4"
                          onClick={() => {
                            setShow(true);
                            handleClose();
                          }}
                          style={{ marginTop: 15, marginRight: 1 }}
                        ></button>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Click here to activate tag"
                        aria-label="add"
                      >
                        <button
                          className="activatedeactivatebutton bg-danger mt-4"
                          onClick={() => {
                            setShow(true);
                            handleClose();
                          }}
                          style={{ marginTop: 15, marginRight: 1 }}
                        ></button>
                      </Tooltip>
                    )}
                    <span>{`${
                      tag_status.charAt(0).toUpperCase() + tag_status.slice(1)
                    }`}</span>
                  </li>
                )}
                {/* <li className="petactivationdate">
                  <span>{`Active Since:${convertISOStringToMonthDay(
                    activation_date
                  )}`}</span>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="middle-content">
          <ul
            // className="keycardtext"
            // style={{
            //   textAlign: "left",
            //   listStyle: "none",
            //   display: "inline-block",
            //   color: "black",
            //   marginLeft: 5,
            //   lineHeight: "normal",
            //   fontSize: "16px",
            // }}
            className="geniecardtext-ul"
          >
            <div>
              <li className="petdetails">
                <span className="lebal">Valuable Type: </span>
                <span className="lebal-content">
                  {valuable_type ? valuable_type : product_category}
                </span>
              </li>
            </div>
            <li className="petdetails">
              <span className="lebal">Description: </span>
              <span className="lebal-content">
                {valuable_desc?.slice(0, 35)
                  ? valuable_desc
                  : tagActivateProduct_Description}
                {valuable_desc?.length > 35 ? (
                  <BsThreeDots className="mt-1" />
                ) : (
                  ""
                )}
              </span>
            </li>

            <li className="petdetails date-laptopview">
              <span className="lebal">Active Since: </span>
              <span
                span
                className="lebal-content"
              >{` ${convertISOStringToMonthDay(activation_date)}`}</span>
            </li>
          </ul>
          <ul
            className="tagdetails laptop-view"
            style={{
              listStyle: "none",
              color: "black",
              textAlign: "right",
              marginRight: 20,
              // marginTop: -10,
              fontSize: "12px",
              lineHeight: "normal",
            }}
          >
            {/* <li className="petownerid">
              <span>{tag_number}</span>
            </li> */}

            {/* <li className="petactivationdate">
              <span>{`Activation: ${convertISOStringToMonthDay(
                activation_date
              )}`}</span>
            </li> */}

            {lf_status === "lost" ? (
              <li className="petloststatus mobile-view-lostpetstatus mt-3">
                <span>Reported Lost</span>
              </li>
            ) : (
              <li className="petstatus">
                {tag_status === "active" ? (
                  <Tooltip
                    title="Click here to deactivate tag"
                    aria-label="add"
                  >
                    <button
                      className="activatedeactivatebutton bg-success mt-4"
                      onClick={() => {
                        setShow(true);
                        handleClose();
                      }}
                      style={{ marginTop: 15, marginRight: 1 }}
                    ></button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Click here to activate tag" aria-label="add">
                    <button
                      className="activatedeactivatebutton bg-danger mt-4"
                      onClick={() => {
                        setShow(true);
                        handleClose();
                      }}
                      style={{ marginTop: 15, marginRight: 1 }}
                    ></button>
                  </Tooltip>
                )}
                <span>{`${
                  tag_status.charAt(0).toUpperCase() + tag_status.slice(1)
                }`}</span>
              </li>
            )}
            <li className="petactivationdate">
              <span className="petactive-lebal">Active Since:</span>
              <span
                span
                className="lebal-content"
              >{` ${convertISOStringToMonthDay(activation_date)}`}</span>
            </li>
          </ul>
        </div>
        <div className="tagdetailsandicons">
          <div className="icons" style={{ marginRight: "30px" }}>
            {/* <Tooltip title="Passport Details" aria-label="add">
              <a href={`/passport-details/${tag_number}`}>
                <i
                  className="fas fa-passport"
                  style={{ color: "black", fontSize: 18, marginTop: 10 }}
                ></i>
              </a>
            </Tooltip> */}
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
            {/* {tag_status === "active" ? (
              <Tooltip title="Click here to deactivate tag" aria-label="add">
                <button
                  className="activatedeactivatebutton bg-success mt-4"
                  onClick={() => {
                   setShow(true);
                    handleClose();
                  }}
                  style={{ marginTop: 15, marginRight: 1 }}
                ></button>
              </Tooltip>
            ) : (
              <Tooltip title="Click here to activate tag" aria-label="add">
                <button
                  className="activatedeactivatebutton bg-danger mt-4"
                  onClick={() => {
                   setShow(true);
                   handleClose();
                  }}
                  style={{ marginTop: 15, marginRight: 1 }}
                ></button>
              </Tooltip>
            )} */}
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
            <i
              aria-controls="menu-list-grow"
              onClick={handleClick}
              className="fa fa-ellipsis-v menu-drop"
              style={{
                color: "#ffc200f7",
                fontSize: 27,
                marginTop: 5,
                // marginRight: "-40px",
                // marginLeft: "-10px",
                // paddingLeft: "10px",
                // paddingRight: "10px",
              }}
            ></i>
            {/* <img
              className="images-css"
              src={"https://storage.googleapis.com/pettag/qr/assets/list.png"}
              onClick={handleClick}
              alt="Pet Icon"
              style={{
                fontSize: 15,
              }}
            /> */}
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {valuable_type === "Passport" ? (
                <MenuItem
                  onClick={() => {
                    showPassportDetails();
                  }}
                >
                  Passport Details
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    showExtraDetails();
                  }}
                >
                  Edit Details
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  foundOrLost();
                }}
              >
                {lf_status === "lost" ? "Report Found" : "Report Lost"}
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setShow(true);
                  handleClose();
                }}
              >
                {tag_status === "active" ? "Deactivate Tag" : "Activate Tag"}
              </MenuItem>

              {/* {lf_status === "lost" ? (
                <MenuItem
                  onClick={() => {
                    window.location.href = "/passport-poster/" + tag_number;
                  }}
                >
                  Passport Poster
                </MenuItem>
              ) : null} */}
            </Menu>
            <Dialog
              disableBackdropClick
              open={show}
              onClose={handleClose1}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h1
                  style={{
                    margin: "20px",
                    marginTop: "10px",
                    font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                    letterSpacing: "normal",
                  }}
                >
                  {tag_status === "active"
                    ? "Are you sure you want to deactivate the tag?"
                    : "Are you sure you want to activate the tag?"}
                </h1>

                <div style={{ display: "flex" }}>
                  <button
                    onClick={
                      tag_status === "active" ? deactivateTag : activateTag
                    }
                    className="card__confirm"
                    style={{ margin: "10px 20px" }}
                  >
                    CONFIRM
                  </button>

                  <button
                    onClick={() => setShow(false)}
                    className="card__cancel"
                    style={{ margin: "10px 20px" }}
                  >
                    CANCEL
                  </button>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
              disableBackdropClick
              open={show2}
              onClose={handleClose1}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h1
                  style={{
                    margin: "20px",
                    marginTop: "10px",
                    font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                    letterSpacing: "normal",
                  }}
                >
                  Are you sure you want to report found?
                </h1>

                <div style={{ display: "flex" }}>
                  <button
                    onClick={reportFound}
                    className="card__confirm"
                    style={{ margin: "10px 20px" }}
                  >
                    CONFIRM
                  </button>

                  <button
                    onClick={() => setShow2(false)}
                    className="card__cancel"
                    style={{ margin: "10px 20px" }}
                  >
                    CANCEL
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OldTagsCard;
