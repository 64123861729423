import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import "../../reportfound/reportfound.component.css";
import Footer from "../../footer/footerblack.jsx";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Findermodal from "../../scan-routing/Findermodal.jsx";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { IconButton, Snackbar } from "@material-ui/core";

import { readCookie } from "../../../utils/setValues";
import moment from "moment";
import { switchUrls } from "../../../api/index.js";
import axios from "axios";
import Imagecarousel from "../../dashboard/Imagecarousel.jsx";
const gatewayFrontend = switchUrls("gateway_frontend");
const baseURL = switchUrls("gateway");
function Newscan({ tagdetails, ownerdetails, productcategory }) {
  const usertoken = readCookie("token");
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarmsg, setsnackbarmsg] = useState("");
  const [foundermodal, setfoundermodal] = useState(false);
  const [loader, setloader] = useState(false);
  const [deactivetimer, setdeactivetimer] = useState(false);
  const getImage = {
    "E-Passport":
      "https://storage.googleapis.com/pettag/qr/assets/passport.png",
    "E-Bag": "https://storage.googleapis.com/pettag/qr/assets/luggage.png",
  };

  const productheading = {
    "E-Passport": "Passport",
    "E-Bag": "Bag",
  };
  const productText = {
    "E-Passport": "Passport Security eTag",
    "E-Bag": "Bag Security eTag",
  };

  const [drawer, setdrawer] = useState(false);
  const [locationalert, setloactionalert] = useState(false);
  const handleLoginClick = () => {
    if (usertoken) {
      const uid = localStorage.getItem("uid");

      window.location.href = `${gatewayFrontend}/dashboard/${uid}`;
    } else {
      window.location.href = `${gatewayFrontend}/#/login`;
    }
  };

  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const updateCountdownDeactivate = (enddate) => {
    const arrivalNextDay = new Date(enddate);
    arrivalNextDay.setDate(arrivalNextDay.getDate() + 2);

    const targetMoment = moment(arrivalNextDay);
    const now = moment();

    const difference = targetMoment.diff(now);
   
    const duration = moment.duration(difference);
    if (
      difference < 0 ||
      difference === undefined ||
      isNaN(difference) ||
      difference === null
    ) {
      // End date has passed
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    }

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return {
      days: days.toString().padStart(2, "0"),
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    };
  };
  const scanLocation = async(lat,lng)=>{
    try{
  const {data} = await axios.post(`${baseURL}/api/ScanLocation/` + tagdetails?.tag_number,{
    latitude : lat,
    longitude : lng
  })
  return
    }
    catch(er){

      setsnackbar(true)
      setsnackbarmsg("Location could not be added.")
    }
  }

  const sendEmailapi = async (lat, lng) => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/sendemail/` + tagdetails?.tag_number,
        {
          params: {
            latitude: lat,
            longitude: lng,
          },
        }
      );
      setsnackbar(true);
      setsnackbarmsg(data?.message || "Email Send");
    } catch (error) {
      setsnackbar(true);
      setsnackbarmsg(error?.response?.data?.message || "Failed");
    }
  };

  useEffect(() => {

  if((new Date()?.getDate() === new Date(tagdetails?.journey_enddate)?.getDate() || new Date()?.getDate() === new Date(tagdetails?.journey_enddate)?.getDate() + 1) && (new Date()?.getMonth() ===  new Date(tagdetails?.journey_enddate)?.getMonth())&&(new Date()?.getFullYear() === new Date(tagdetails?.journey_enddate)?.getFullYear())){
          setdeactivetimer(true)
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        scanLocation(position?.coords?.latitude, position?.coords?.longitude)
        sendEmailapi(position?.coords?.latitude, position?.coords?.longitude);
      },
      (error) => {
        setloactionalert(true);
      }
    );
  }, []);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
     
      setTimeRemaining(updateCountdownDeactivate(tagdetails?.journey_enddate));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <>
        {loader ? (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        ) : (
          <div>
            {foundermodal ? (
              <Findermodal
                findermodal={foundermodal}
                setfindermodal={setfoundermodal}
                tagnumber={tagdetails?.tag_number}
                image={
                  tagdetails?.bag_image1
                    ? tagdetails?.bag_image1
                    : tagdetails?.bag_image2
                    ? tagdetails?.bag_image2
                    : tagdetails?.passport_imagelocation
                    ? tagdetails?.passport_imagelocation
                    : getImage[productcategory]
                }
              />
            ) : null}

            <div style={{ overflowX: "hidden" }}>
              <div
                className="genie-nav"
                id="genie-nav"
                style={{
                  borderBottom: "1px solid #ddd",
                  paddingBottom: "5px",
                }}
              >
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
                  }
                  alt="logo"
                  style={{ width: "5rem" }}
                />
                <div className="genie-nav-button-container">
                  <button
                    className="genie-login-button"
                    onClick={handleLoginClick}
                  >
                    {usertoken ? "Dashboard" : "LOG IN"}
                  </button>
                </div>
              </div>
              <div className="container">
                <div id="contentToConvert" className="pb-3">
                  <Card className="mat-card">
                    <div className="lost-info-detail-header row bg-gray text-center">
                    <p className="header-font text-font">
  {tagdetails?.tag_status === "active" 
    ? "Thank You for Finding Me" 
    : tagdetails?.tag_status === "cancel" 
      ? `Your ${productText[productcategory]}  has been Expired` 
      : `Your ${productText[productcategory]}  has been Deactivated`}
</p>
                    </div>
                    <div className="custom-container">
                      <div>
                        {productcategory === "E-Bag" ? (
                          <>
                            {tagdetails?.bag_image1 &&
                            tagdetails?.bag_image2 ? (
                              <>
                                <Imagecarousel
                                  bag_image1={tagdetails?.bag_image1}
                                  bag_image2={tagdetails?.bag_image2}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    tagdetails?.bag_image1
                                      ? tagdetails?.bag_image1
                                      : tagdetails?.bag_image2 ?
                                      tagdetails?.bag_image2
                                      : getImage[productcategory]
                                  }
                                  alt="valuable"
                                  className={
                                    tagdetails?.imagelocation
                                      ? "imagelocation-image"
                                      : "default-image"
                                  }
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <img
                              src={
                                tagdetails?.passport_imagelocation
                                  ?  tagdetails?.passport_imagelocation
                                  : getImage[productcategory]
                              }
                              alt="valuable"
                              className={
                              
                                  "default-image"
                              }
                            />
                          </>
                        )}
                      </div>
                      <div>
                        <p style={{ marginTop: "0.5rem" }}>
                          <img
                            src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "0.5rem",
                            }}
                            alt="qr"
                          />
                          <b>
                            {tagdetails?.tag_number &&
                            tagdetails?.tag_number !== "-"
                              ? tagdetails?.tag_number
                              : "N/A"}
                          </b>
                        </p>
                      </div>
                      <div className="colorw container-for-rows"></div>
                    </div>
                    <div>
                      {tagdetails?.tag_status === "active" ? (
                        <div className="reportfound-message-content">
                          <span
                            className="scanpage_text"
                            style={{
                              fontFamily: "prompt",
                              fontWeight: "normal",
                              fontSize: "1.3rem",
                              lineHeight: "1.8rem",
                              color: "black",
                            }}
                          >
                            This{" "}
                            <b
                              style={{
                                fontFamily: "prompt",
                                color: "black",
                              }}
                            >
                              {productheading[productcategory]}
                            </b>
                            {"  "}
                            belongs to
                            <b
                              style={{
                                fontFamily: "prompt",
                                color: "black",
                              }}
                            >
                              {" "}
                              {productcategory === "E-Passport" &&
                              tagdetails?.givenName &&
                              tagdetails?.Surname
                                ? tagdetails?.givenName +
                                  " " +
                                  tagdetails?.Surname
                                : productcategory === "E-Bag" &&
                                  tagdetails?.passenger1_firstname &&
                                  tagdetails?.passenger1_lastname
                                ? tagdetails?.passenger1_firstname +
                                  " " +
                                  tagdetails?.passenger1_lastname
                                : ownerdetails?.first_name &&
                                  ownerdetails?.last_name
                                ? ownerdetails?.first_name +
                                  " " +
                                  ownerdetails?.Surname
                                : null}
                            </b>
                          </span>
                        </div>
                      ) : (
                        <div className="tata-deactive-details">
                         {
                          tagdetails?.tag_status === "cancel" ? <>
                           <p>
                            Your {productText[productcategory]} Tag has been expired
                          </p>
                         
                          </> : <>
                          <p>
                            Your safe arrival is great news. The journey's done,
                            but memories will last a lifetime.
                          </p>
                          <p style={{ marginTop: "1rem" }}>
                            {" "}
                            Wishing you many more smooth journeys ahead.
                          </p>
                          </>
                         }
                        </div>
                      )}
                    </div>
                    <div className="deactive_timer_etag">
                      <div>
                        {tagdetails?.journey_enddate &&
                          tagdetails?.tag_status === "active" &&
                          deactivetimer && (
                            <>
                              <div className="tata-active-details">
                                <p className="scanpage_text">
                                  Please note your{" "}
                                  {productheading[productcategory]} tag will be
                                  deactivate in 24 hours after your arrival.
                                </p>
                              </div>
                              <div className="timer-div scan_pagetimer">
                                <div className="timer-div">
                                  <div className="timer-div">
                                    <span className="timer-hrs-div">
                                      <span className="timer-value">
                                        {timeRemaining?.days
                                          ? timeRemaining.days
                                          : "00"}
                                      </span>
                                      <span className="timer-info">Days</span>
                                    </span>
                                    <span className="timer-dot-div">
                                      <span className="timer-dot-div-1"></span>
                                      <span className="timer-dot-div-2"></span>
                                    </span>
                                    <span className="timer-hrs-div">
                                      <span className="timer-value">
                                        {timeRemaining?.hours
                                          ? timeRemaining.hours
                                          : "00"}
                                      </span>
                                      <span className="timer-info">HRS</span>
                                    </span>

                                    <span className="timer-dot-div">
                                      <span className="timer-dot-div-1"></span>
                                      <span className="timer-dot-div-2"></span>
                                    </span>

                                    <span className="timer-hrs-div">
                                      <span className="timer-value">
                                        {timeRemaining?.minutes
                                          ? timeRemaining.minutes
                                          : "00"}
                                      </span>
                                      <span className="timer-info">MINS</span>
                                    </span>

                                    <span className="timer-dot-div">
                                      <span className="timer-dot-div-1"></span>
                                      <span className="timer-dot-div-2"></span>
                                    </span>

                                    <span className="timer-hrs-div">
                                      <span className="timer-value">
                                        {timeRemaining?.seconds
                                          ? timeRemaining.seconds
                                          : "00"}
                                      </span>
                                      <span className="timer-info">SECS</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    <>
                      {tagdetails?.tag_status !== "deactive" && tagdetails?.tag_status !== "cancel"  ? (
                        <>
                          <div className="footerof-tata">
                            <div
                              className="drawer  pet-bottom-div tata-footer"
                              onClick={() => setdrawer(!drawer)}
                            >
                              <div className="drawerarrow">
                                {!drawer ? (
                                  <i
                                    className="fa fa-angle-double-up drawerarrow"
                                    onClick={() => setdrawer(!drawer)}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-angle-double-down drawerarrow"
                                    onClick={() => setdrawer(!drawer)}
                                  ></i>
                                )}
                              </div>

                              <p
                                className="returnreward-text"
                                style={{
                                  fontSize: "23px",
                                  fontFamily: "prompt,sans-serif",
                                }}
                              >
                                   Contact Owner
                              </p>

                              <div className="guardianinfo">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="contactdivlg"
                                    style={{ marginLeft: "2rem" }}
                                  >
                                    <>
                                      <div
                                        style={{
                                          marginRight: "1rem",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <a
                                          href={`tel:${ownerdetails?.phonecountrycode}${ownerdetails?.phone}`}
                                        >
                                          <p>
                                            <span
                                              className="conatct-div-block"
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <img
                                                alt="call"
                                                className="reportfound-vector"
                                                src={
                                                  "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                                }
                                              />
                                              {"  "}
                                              Call
                                            </span>
                                          </p>
                                        </a>
                                      </div>
                                    </>
                                  </div>
                                  <div className="contactdivlg">
                                    <>
                                      <div
                                        style={{
                                          marginRight: "1rem",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <a
                                          href={`sms:${ownerdetails?.phonecountrycode}${ownerdetails?.phone}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <p>
                                            <div
                                              className="conatct-div-block"
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <img
                                                alt="phone"
                                                className="reportfound-vector"
                                                src={
                                                  "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                                }
                                              />{" "}
                                              SMS
                                            </div>
                                          </p>
                                        </a>
                                      </div>
                                    </>
                                  </div>

                                  <div className="contactdivlg">
                                    <div
                                      style={{
                                        marginBottom: "1rem",
                                        marginRight: "1rem",
                                      }}
                                    >
                                      <a
                                        href={`https://wa.me/${ownerdetails?.phonecountrycode?.replace(
                                          "+",
                                          ""
                                        )}${ownerdetails?.phone}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <p>
                                          <div
                                            className="conatct-div-block"
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            <img
                                              alt="phone"
                                              className="reportfound-vector"
                                              src={
                                                "https://pettag.storage.googleapis.com/qr-bag/WhatsAppImage2024-03-20at2.25.34PM.jpeg1710925717209"
                                              }
                                            />{" "}
                                            Whatsapp
                                          </div>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="contactdivlg">
                                    <>
                                      <div
                                        style={{
                                          marginRight: "1rem",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <a
                                          href={`mailto:${ownerdetails?.email}`}
                                        >
                                          <p>
                                            <div
                                              className="conatct-div-block"
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <img
                                                alt="email"
                                                className="reportfound-vector"
                                                src={
                                                  "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                                }
                                              />{" "}
                                              Email
                                            </div>
                                          </p>
                                        </a>
                                      </div>
                                    </>
                                  </div>
                                  <div className="contactdivlg">
                                    <>
                                      <div
                                        style={{
                                          marginRight: "1rem",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <p
                                          onClick={() => setfoundermodal(true)}
                                        >
                                          <p
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            <div
                                              className="conatct-div-block"
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <img
                                                alt="email"
                                                className="reportfound-vector"
                                                src={
                                                  "https://pettag.storage.googleapis.com/qr-bag/Untitleddesign(39)(1).png1710925466237"
                                                }
                                              />{" "}
                                              Contact Me
                                            </div>
                                          </p>
                                        </p>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>

                              <SwipeableDrawer
                                className="swipabledrawer"
                                anchor="bottom"
                                open={drawer}
                                onClose={() => setdrawer(!drawer)}
                                onOpen={() => setdrawer(!drawer)}
                                disableSwipeToOpen={false}
                                ModalProps={{
                                  keepMounted: true,
                                }}
                              >
                                <div
                                  className="reportfound-pet-bottom-inner-div guardianmobile"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div>
                                    <i
                                      className="fa fa-angle-double-down drawerarrow"
                                      onClick={() => setdrawer(!drawer)}
                                    ></i>
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <p
                                      style={{
                                        fontSize: "20px",
                                        fontFamily: "prompt,sans-serif",
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                      }}
                                    >
                                       Contact Owner
                                    </p>
                                  </div>
                                  <div>
                                    <div style={{ display: "flex" }}>
                                      <div style={{ marginRight: "1rem",width:"50%",height:"50%" }}>
                                        <a
                                          href={`tel:${ownerdetails?.phonecountrycode}${ownerdetails?.phone}`}
                                        >
                                          <p className="pabr footer-font flex-between">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  marginBottom: "5px",
                                                }}
                                              >
                                                <img
                                                  alt="call"
                                                  className="reportfound-vector"
                                                  src={
                                                    "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <span>Call</span>
                                              </div>
                                            </div>
                                          </p>
                                        </a>
                                      </div>

                                      <div style={{ marginBottom: "1rem",width:"50%",height:"50%" }}>
                                        <a
                                          href={`sms:${ownerdetails?.phonecountrycode}${ownerdetails?.phone}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <p className="flex-between footer-font text-center pabr">
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  marginBottom: "5px",
                                                }}
                                              >
                                                <img
                                                  alt="phone"
                                                  className="reportfound-vector"
                                                  src={
                                                    "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <span>SMS</span>
                                              </div>
                                            </div>
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                    <div>
                                      <div style={{ display: "flex" }}>
                                        <div
                                          style={{
                                            marginBottom: "1rem",
                                            marginRight: "1rem",
                                            width:"50%",height:"50%"
                                          }}
                                        >
                                          <a
                                            href={`https://wa.me/${ownerdetails?.phonecountrycode.replace(
                                              "+",
                                              ""
                                            )}${ownerdetails?.phone}`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <p className="pabr flex-between footer-font text-center">
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <img
                                                    alt="phone"
                                                    className="reportfound-vector"
                                                    src={
                                                      "https://pettag.storage.googleapis.com/qr-bag/WhatsAppImage2024-03-20at2.25.34PM.jpeg1710925717209"
                                                    }
                                                  />
                                                </div>
                                                <div>Whatsapp</div>
                                              </div>
                                            </p>
                                          </a>
                                        </div>

                                        <div style={{width:"50%",height:"50%"}}>
                                          <a
                                            href={`mailto:${ownerdetails?.email}`}
                                          >
                                            <p className="flex-between footer-font text-center pabr">
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <img
                                                    alt="email"
                                                    className="reportfound-vector"
                                                    src={
                                                      "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                                    }
                                                  />
                                                </div>
                                                <div>
                                                  <span> Email</span>
                                                </div>
                                              </div>
                                            </p>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div>
                                        <p
                                          onClick={() => setfoundermodal(true)}
                                        >
                                          <p className="pabr flex-between welcome-footer-font text-center">
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <img
                                                src={
                                                  "https://pettag.storage.googleapis.com/qr-bag/Untitleddesign(39)(1).png1710925466237"
                                                }
                                                alt="contact support"
                                                className="vector"
                                                loading="lazy"
                                              />
                                              &nbsp; Contact Me
                                            </div>
                                          </p>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <Footer />
                              </SwipeableDrawer>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  
                  </Card>
                </div>
              </div>
            </div>

            <Dialog open={locationalert} maxWidth="md">
              <DialogTitle id="alert-dialog-title">{"GPS Alert"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <p style={{ margin: "1rem" }}>
                    Please Enable your GPS Location to continue
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setloactionalert(false);
                    window.location.reload();
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>

            <Snackbar
              open={snackbar}
              autoHideDuration={8000}
              onClose={() => {
                setsnackbar(false);
              }}
              message={snackbarmsg}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => {
                    setsnackbar(false);
                  }}
                >
                  X
                </IconButton>,
              ]}
            />
          </div>
        )}
        <div className="footer_scanpage">
          <Footer />
        </div>
      </>
    </>
  );
}

export default Newscan;
