import "./App.css";
import { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  HashRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute.js";
import Humanwelcome from "./components/scan-routing/humanwelcome.component.jsx";
import Profile from "./components/profile/profile.component.jsx";
import Dashboard from "./components/dashboard/dashboard.component.jsx";
import * as Sentry from "@sentry/react";
// import { Helmet, HelmetProvider } from "react-helmet-async";
// import BulkUpload from "./components/bulk-upload/bulkUpload.component.jsx";
import Signup from "./components/signup/signup.component.jsx";
import Login from "./components/login/login.component.jsx";
import Footer from "../src/components/footer/footerblack.jsx";
// import MedicalDashboard from "./components/medicaldashboard/medicaldashboard.component.jsx";
import { createBrowserHistory } from "history";
import { ErrorBoundary } from "@sentry/react";
import Calendar from "./components/calendar/calendar.component.jsx";
import ScanRouting from "./components/scan-routing/scanrouting.component.jsx";
// import EditPetDetails from "../src/components/editpetdetails/editpetdetails.component.jsx";
// import EdiParentDetails from "../src/components/editparentdetails/editparentdetails.component.jsx";
import Notifications from "./components/scan-notification/notifications.component.jsx";
import LFTagDetails from "./components/lftags-details/lftTagsdetails.component.jsx";
import ReportLost from "./components/report-lost-form/reportlost.component.jsx";
import LostFoundPoster from "./components/lost-found-poster/lost-foundposter.component.jsx";
import CRMRedirect from "./components/crm-redirection/crm-redirect.component.jsx";
import ActivateLfTag from "./components/activatelftag/activatelftag.component.jsx";
import ReportFoundLF from "./components/reportfoundlf/reportfoundlf.component.jsx";
import OldTagDetails from "./components/oldtags-details/oldtagsdtls.component.jsx";
import NotFoundDetails from "./components/not-foundtags/notfound.component.jsx";
// import EarphoneActivation from "./components/earphoneactivation/earphoneactivation.component.jsx";
import Welcome from "./components/scan-routing/welcome.component";
import ViewPetDetails from "./components/reportfound/viewpetdetails/viewpetdetails.component.jsx";
import Concentform from "./components/concentform/Consentform.jsx";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { logEvent } from "firebase/analytics";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import { getDatabase, ref, set } from "firebase/database";


const firebaseConfig = {
  apiKey: "AIzaSyCHWDRpd7asWYRXSPruxNfUD6Plf3Wy_as",
  authDomain: "dolphin-tracker-6d57b.firebaseapp.com",
  projectId: "dolphin-tracker-6d57b",
  storageBucket: "dolphin-tracker-6d57b.appspot.com",
  messagingSenderId: "706582936796",
  appId: "1:706582936796:web:bfc5502805578716068819",
  measurementId: "G-X1243WMENZ",
  databaseURL: "https://dolphin-tracker-6d57b-default-rtdb.firebaseio.com",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
// const app = initializeApp(firebaseConfig);
// const firestore = getFirestore(app);

function App() {
  const history = useHistory();
  var location = useLocation();

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  let usertoken = readCookie("token");
  // const history = createBrowserHistory();

  const uid = localStorage.getItem("uid");
  const dashboardRoute = "/dashboard/" + uid;
  const tag_number = localStorage.getItem("tag_number");
  const activetag = localStorage.getItem("active");
  var petactivationroute = "/pet-activation/" + tag_number;
  // var initial = tag_number.substring(0, 3);
  var url =
    tag_number === null
      ? dashboardRoute
      : tag_number.substring(0, 3) === "PET" ||
        tag_number.substring(0, 3) === "TGZ"
      ? petactivationroute
      : dashboardRoute;
  // const [pageTitle, setPageTitle] = useState("tag8");
  // const [pageDescription, setPageDescription] = useState(
  //   "Your default description"
  // );
  // const [pageImage, setPageImage] = useState(
  //   "https://example.com/default-image.jpg"
  // );
  // if (url === petactivationroute && document.URL.includes("pet-activation")) {
  //   window.location.reload();

  //   var urlgen = document.URL.replace("dashboard", "genie");

  //   petactivationroute = urlgen;
  //   window.location.href = urlgen;
  // }

  if (activetag !== false) {
    localStorage.removeItem("active");
  }
  const [isAuth, setIsAuth] = useState(usertoken !== null ? true : false);
  useEffect(() => {
    setIsAuth(usertoken !== null ? true : false);
    // const firedata = collection(firestore, "test_data");
    // let data = {
    //   testdata: "testdata",
    // };

    // addDoc(firedata, data);
    const db = getDatabase();
    set(ref(db, "users/" + "12345"), {
      username: "name",
      email: "email",
      profile_picture: "imageUrl",
    });
    logEvent(analytics, "custom_event", { some_parameter: "some_value" });
  }, [usertoken, analytics]);

  const isScanRoute =
    location.pathname.startsWith("/scan/") ||
    location.pathname.startsWith("/tags-not-found") ||
    location.pathname.startsWith("/welcome");
  return (
    <div className="PageWrapper">
      <HashRouter history={history}>
        <ErrorBoundary fallback={"An error has occurred"}>
          <div className="AppWrapper">
            <Switch>
              <Route
                path={`/connect/` + uid}
                render={(props) => <Humanwelcome {...props} />}
              />
              <Route
                path={`/scan-notification/` + uid}
                render={(props) => <Notifications {...props} />}
              />
              <Route
                path={"/concent"}
                render={(props) => <Concentform {...props} />}
              />

              <Route
                path={"/scan/:tag_number"}
                render={(props) => (
                  <ScanRouting {...props} excludeFooter={true} />
                )}
              />

              <ProtectedRoute
                path="/profile"
                component={Profile}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={dashboardRoute}
                component={Dashboard}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/calendar/:uid"}
                component={Calendar}
                isAuth={isAuth}
              />
              <Route
                path={"/view-pet-details/:tag_number"}
                render={(props) => <ViewPetDetails {...props} />}
              />
              {/* <Route
            path={"/earphone-activation/:tag_number"}
            render={(props) => <EarphoneActivation {...props} />}
            isAuth={isAuth}
          /> */}
              {/* <ProtectedRoute
            path="/bulkupload"
            component={BulkUpload}
            isAuth={isAuth}
          /> */}
              {/* <ProtectedRoute
            path={"/medical-dashboard/:tag_number"}
            component={MedicalDashboard}
            isAuth={isAuth}
          /> */}
              <Route path="/signup" component={Signup} isAuth={!isAuth} />
              {/* <ProtectedRoute
                path="/login"
                component={Login}
                // render={() =>
                //   isAuth ? <Redirect replace to="/dashboard" /> : <Login />
                // }
                isAuth={!isAuth}
              /> */}
              <Route
                path="/login"
                render={() =>
                  isAuth ? <Redirect to="/dashboard" /> : <Login />
                }
              />
              {/* <ProtectedRoute
            path={"/pet-parent-details/:tag_number"}
            component={EdiParentDetails}
            isAuth={isAuth}
          />
          <ProtectedRoute
            path={"/pet-details/:tag_number"}
            component={EditPetDetails}
            isAuth={isAuth}
          /> */}
              <ProtectedRoute
                path={"/lftag-details/:tag_number"}
                component={LFTagDetails}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/report-lost-found-tags/:tag_number"}
                component={ReportLost}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/poster-lost-found-tags/:tag_number"}
                component={LostFoundPoster}
                isAuth={isAuth}
              />
              <Route
                path="/crm-redirect-login/:email"
                component={CRMRedirect}
                isAuth={!isAuth}
              />
              <ProtectedRoute
                path={"/activate-lftag/:tag_number"}
                component={ActivateLfTag}
                isAuth={isAuth}
              />
              <Route
                path={"/report-foundstepone"}
                render={(props) => <ReportFoundLF {...props} />}
              />
              <ProtectedRoute
                path={"/passport-lost-found-tag/:tag_number"}
                component={OldTagDetails}
                isAuth={isAuth}
              />
              <Route
                path={"/tags-not-found"}
                render={(props) => <NotFoundDetails />}
              />

              <Route
                path="/welcome/:tag_number"
                render={(props) => <Welcome {...props} />}
              />

              {isAuth ? <Redirect to={url} /> : <Redirect to="/login" />}
            </Switch>
          </div>
        </ErrorBoundary>
      </HashRouter>
      <div className="FooterWrapper">{!isScanRoute ? <Footer /> : ""}</div>
    </div>
  );
}

export default Sentry.withProfiler(App);
