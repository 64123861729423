import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HashRouter } from "react-router-dom";

Sentry.init({
  dsn: "https://8f88bdf3fb2bd2862b5d328a43afa98f@o4506562951970816.ingest.sentry.io/4506591718604800",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  autoSessionTracking: false,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.5,
  debug: false,
});
ReactDOM.hydrate(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="972792551017-hvq7nv8mbasb258aa2o21mki68rith9v.apps.googleusercontent.com">
      <HashRouter>
        <App />
      </HashRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
