import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Logo from "https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png";
import "font-awesome/css/font-awesome.min.css";
import Footer from "../footer/footerblack.jsx";
import Card from "@material-ui/core/Card";
import "./notfound.component.css";
import axios from "axios";
// import email from "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/email.png";
// import call from "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/call.png";
// import phone from "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/phone-call.png";
// import notfound from "../../assets/tagnotFound.jpg";
// import Othericon from "https://storage.googleapis.com/pettag/qrtagdev/assets/other.png";
// import money from "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/money.png";
import * as FaIcons from "react-icons/fa";
import { setIcon, setValuableName } from "../../utils/setValues.js";
import { switchUrls } from "../../api/index.js";
// import {getCords} from '../../utils/getcordinates.js';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const baseURL = switchUrls("gateway");

// let test = getCords();
function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function whatsApp(cc, phone) {
  // let phoneCc = cc;
  let phoneCc = cc || "91";
  let number = phone.split("").length > 10 ? phone.slice(1) : phone;

  return phoneCc.match(/\d+/)[0].concat(number);
}

function telOne(cc, phone) {
  // let phoneCc = cc;
  let phoneCc = cc || "91";
  let number = phone.split("").length > 10 ? phone.slice(1) : phone;

  return phoneCc.match(/\d+/)[0].concat(number);
}
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}
var usertoken = readCookie("token");

export default class NotFoundDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tag_number: this.props.tag_number,
      product_category: "",
      tag_status: "found",
      phone_cc: "",
      valuable_type: "other",
      keychain_description: "",
      identification_mark: "",
      owner_phone: "9029008248",
      owner_phonecode: "+91",
      owner_email: "support@tag8.in",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      curlatitude: "",
      curlongitude: "",
      address: "",
      key_imagelocation: "",
      pet_imagelocation: "",
      other_noofkeys: "",
      beacon_name: "",
      identification_mark: "",
      vehicle_licensenumber: "",
      product_type: "",
      passport: "",
      placeofissue: "",
      valuable_desc: "",
      description: "",
      bag_color: "",
      bag_brand: "",
      bag_imagelocation: "",
      earphone_color: "",
      earphone_model: "",
      earphone_brand: "",
      earphone_imagelocation: "",
      latitude: "",
      longitude: "",
      snackbaropen: false,
      scan: false,
      showFooter: true,
      snackbarmsg: "",
      token: false,
      notfound: false,
      notactivated: false,
      showdiv: false,
      drawer: false,
      support_email: "support@tag8.in",
      support_phone: "+919029008248",
    };
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleScreen = this.handleScreen.bind(this);
    // this.getCoordinates = this.getCoordinates.bind(this);
  }

  handleScreen() {
    const showFooter = window.innerWidth > 768;
    this.setState({
      drawer: false,
      showFooter,
    });
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleScreen);

    this.listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;

      if (scrolled >= 20) {
        document.getElementById("genie-nav").style.backgroundColor = "white";
      } else {
        document.getElementById("genie-nav").style.backgroundColor =
          "transparent";
      }
    });
    if (usertoken) {
      this.setState({
        token: true,
      });
    }
  }
  handleLoginClick = () => {
    if (usertoken) {
      const uid = localStorage.getItem("uid");
      const dashboardRoute = "/dashboard/" + uid;
      window.location.href = dashboardRoute;
    } else {
      window.location.href = `${baseURL}/#/login`;
    }
  };

  showOptions() {
    if (this.state.showdiv) {
      document.getElementById("bdiv").style.bottom = "-21.5rem";
      document.getElementById("aup").style.display = "block";
      document.getElementById("adown").style.display = "none";

      this.setState({ showdiv: false });
    } else {
      document.getElementById("bdiv").style.bottom = "0";
      document.getElementById("aup").style.display = "none";
      document.getElementById("adown").style.display = "block";

      this.setState({ showdiv: true });
    }
  }

  render() {
    const {
      tag_number,
      owner_firstname,
      product_category,
      owner_lastname,
      phone_cc,
      keychain_description,
      other_noofkeys,
      owner_phone,
      tag_status,
      owner_email,
      owner_phonecode,
      valuable_type,
      valuable_desc,
      address,
      beacon_name,
      reward,
      rewardcurrency,
      identification_mark,
      lost_date,
      lost_text,
      vehicle_licensenumber,
      chasis_number,
      product_type,
      passport,
      placeofissue,
      owner_altemail,
      owner_altphonecode,
      owner_altphone,
      lost_timeone,
      lost_timetwo,
      description,
      bag_color,
      bag_brand,
      longitude,
      latitude,
      earphone_brand,
      earphone_color,
      earphone_model,
      curlatitude,
      curlongitude,
      drawer,
      support_phone,
      token,
      showFooter,
      support_email,
    } = this.state;
    // const image = setIcon(product_type, tag_number);

    return (
      <>
        <div>
          <div
            className="genie-nav"
            id="genie-nav"
            style={{
              borderBottom: "1px solid #ddd",
              paddingBottom: "5px",
            }}
          >
            <img
              src={
                "https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
              }
              alt="logo"
              style={{ width: "5rem" }}
            />
            <div className="genie-nav-button-container">
              <button
                className="genie-login-button"
                onClick={this.handleLoginClick}
              >
                {token ? "Dashboard" : "LOG IN"}
              </button>
            </div>
          </div>

          <div className="notfound-main">
            <div className="tata-deactive-heading">
              <h1 style={{ padding: "1rem" }}>Tag Not Found</h1>
            </div>
            <div className="notfound-upper">
              <div
                style={{ margin: "1rem" }}
                className="tata-deactive-pasportlogo"
              >
                <img
                  alt="b"
                  src="https://storage.googleapis.com/pettag/qr/assets/tagnotFound.jpg"
                ></img>
              </div>
              <div className="not-found-details">
                <h2 style={{ fontSize: "larger" }}>
                  Get in touch with our customer support
                </h2>
              </div>

              <div className="footerof-tata">
                <div
                  className="drawer  pet-bottom-div not-found-footer"
                  onClick={() => {
                    this.setState({ drawer: !drawer });
                  }}
                >
                  <div className="drawerarrow">
                    {!drawer ? (
                      <i
                        className="fa fa-angle-double-up drawerarrow"
                        onClick={() => this.setState({ drawer: !drawer })}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-angle-double-down drawerarrow"
                        onClick={() => this.setState({ drawer: !drawer })}
                      ></i>
                    )}
                  </div>

                  <p
                    className="returnreward-text"
                    style={{
                      fontSize: "18px",
                      fontFamily: "prompt,sans-serif",
                    }}
                  >
                    tag8 Customer Support
                  </p>

                  <div
                    className="guardianinfo"
                    style={{ paddingLeft: "6rem", paddingRight: "6rem" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="contactdivlg"
                        style={{ marginLeft: "2rem" }}
                      >
                        <>
                          <div
                            style={{
                              marginRight: "1rem",
                              marginBottom: "1rem",
                            }}
                          >
                            <a href={`tel:${support_phone}`}>
                              <p>
                                <span
                                  className="conatct-div-block"
                                  style={{ display: "inline-block" }}
                                >
                                  <img
                                    alt="call"
                                    className="reportfound-vector"
                                    src={
                                      "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                    }
                                  />
                                  {"  "}
                                  Call
                                </span>
                              </p>
                            </a>
                          </div>
                        </>
                      </div>
                      <div className="contactdivlg">
                        <>
                          <div
                            style={{
                              marginRight: "1rem",
                              marginBottom: "1rem",
                            }}
                          >
                            <a
                              href={`sms:${support_phone}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p>
                                <div
                                  className="conatct-div-block"
                                  style={{ display: "inline-block" }}
                                >
                                  <img
                                    alt="phone"
                                    className="reportfound-vector"
                                    src={
                                      "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                    }
                                  />{" "}
                                  SMS
                                </div>
                              </p>
                            </a>
                          </div>
                        </>
                      </div>

                      <div className="contactdivlg">
                        <div
                          style={{
                            marginBottom: "1rem",
                            marginRight: "1rem",
                          }}
                        >
                          <a
                            href={`https://wa.me/${support_phone}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <p>
                              <div
                                className="conatct-div-block"
                                style={{ display: "inline-block" }}
                              >
                                <img
                                  alt="phone"
                                  className="reportfound-vector"
                                  src={
                                    "https://pettag.storage.googleapis.com/qr-bag/WhatsAppImage2024-03-20at2.25.34PM.jpeg1710925717209"
                                  }
                                />{" "}
                                Whatsapp
                              </div>
                            </p>
                          </a>
                        </div>
                      </div>
                      <div className="contactdivlg">
                        <>
                          <div
                            style={{
                              marginRight: "1rem",
                              marginBottom: "1rem",
                            }}
                          >
                            <a href={`mailto:${support_email}`}>
                              <p>
                                <div
                                  className="conatct-div-block"
                                  style={{ display: "inline-block" }}
                                >
                                  <img
                                    alt="email"
                                    className="reportfound-vector"
                                    src={
                                      "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                    }
                                  />{" "}
                                  Email
                                </div>
                              </p>
                            </a>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>

                  <SwipeableDrawer
                    className="swipabledrawer"
                    anchor="bottom"
                    open={drawer}
                    onClose={() => this.setState({ drawer: !drawer })}
                    onOpen={() => this.setState({ drawer: !drawer })}
                    disableSwipeToOpen={false}
                    ModalProps={{
                      keepMounted: true,
                    }}
                    swipeAreaWidth={30}
                  >
                    <div
                      className="reportfound-pet-bottom-inner-div guardianmobile"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <i
                          className="fa fa-angle-double-down drawerarrow"
                          onClick={() => this.setState({ drawer: !drawer })}
                        ></i>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <p
                          style={{
                            fontSize: "20px",
                            fontFamily: "prompt,sans-serif",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        >
                          tag8 Customer Support
                        </p>
                      </div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: "1rem" }}>
                            <a href={`tel:${support_phone}`}>
                              <p className="pabr footer-font flex-between">
                                <div
                                  style={{
                                    display: "inline-block",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div style={{ marginBottom: "5px" }}>
                                    <img
                                      alt="call"
                                      className="reportfound-vector"
                                      src={
                                        "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                      }
                                    />
                                  </div>
                                  <div>
                                    <span>Call</span>
                                  </div>
                                </div>
                              </p>
                            </a>
                          </div>

                          <div style={{ marginBottom: "1rem" }}>
                            <a
                              href={`sms:${support_phone}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p className="flex-between footer-font text-center pabr">
                                <div style={{ display: "inline-block" }}>
                                  <div style={{ marginBottom: "5px" }}>
                                    <img
                                      alt="phone"
                                      className="reportfound-vector"
                                      src={
                                        "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                      }
                                    />
                                  </div>
                                  <div>
                                    <span>SMS</span>
                                  </div>
                                </div>
                              </p>
                            </a>
                          </div>
                        </div>
                        <div>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                marginBottom: "1rem",
                                marginRight: "1rem",
                              }}
                            >
                              <a
                                href={`https://wa.me/${support_phone}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <p className="pabr flex-between footer-font text-center">
                                  <div style={{ display: "inline-block" }}>
                                    <div style={{ marginBottom: "5px" }}>
                                      <img
                                        alt="phone"
                                        className="reportfound-responsive-vector"
                                        src={
                                          "https://pettag.storage.googleapis.com/qr-bag/WhatsAppImage2024-03-20at2.25.34PM.jpeg1710925717209"
                                        }
                                      />
                                    </div>
                                    Whatsapp
                                  </div>
                                </p>
                              </a>
                            </div>

                            <div>
                              <a href={`mailto:${support_email}`}>
                                <p className="flex-between footer-font text-center pabr">
                                  <div style={{ display: "inline-block" }}>
                                    <div style={{ marginBottom: "5px" }}>
                                      <img
                                        alt="email"
                                        className="reportfound-vector"
                                        src={
                                          "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                        }
                                      />
                                    </div>
                                    <div>
                                      <span>Email </span>
                                    </div>
                                  </div>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />
                  </SwipeableDrawer>
                </div>
              </div>
            </div>
          </div>
          {showFooter ? <Footer /> : ""}
        </div>
      </>
    );
  }
}
