import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";
import "./updatecards.component.css";
import Button from "@material-ui/core/Button";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import * as AiIcons from "react-icons/ai";
import { switchUrls } from "../../../api/index.js";

const baseURL = switchUrls("genie");
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let reports = [];

export default class VaccinationUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      selectedImage: null,
      vaccination_reminder: false,
      tag_number: localStorage.getItem("tag_number"),
      Type: "Vaccination",
      vaccination_id: this.props.vaccination_id,
      Vaccination_name: "",
      VaccinationDoctor_name: "",
      VaccinationAdministration_date: "",
      VaccinationNotes: "",
      VaccinationDone: false,
      VaccinationTitle: "",
      VaccinationEverynumber: "",
      VaccinationEvery: "",
      VaccinationNext: "",
      VaccinationRemind_me: "",
      VaccinationReminderNotes: "",
      Vaccination_Reportname: "",
      Vaccination_Reportnames: [],
      Vaccination_Reportlocations: [],
      message: "",
      docs: [],
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setReports = () => {
    for (let i = 0; i < this.state.Vaccination_ReportsNames.length; i++) {
      let obj = {
        name: this.state.Vaccination_ReportsNames[i],
        location: this.state.Vaccination_ReportsLocations[i],
      };

      reports.push(obj);
    }

    this.setState({ docs: reports });
  };

  vaccinationChangeHandler = (e) => {
    this.setState({ [e.target.name]: !this.state.VaccinationDone });
  };

  handleChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileSize = file.size;

    const fileExtension = fileName.split(".")[1];
    const size = fileSize / (1024 * 1024);

    const regex = /\./gm;

    const isValidFileExtension = [
      "pdf",
      "docx",
      "docs",
      "png",
      "jpg",
      "jpeg",
      "csv",
      "xlsx",
    ].includes(fileExtension);
    const isValidSize = size < 5 ? true : false;
    const isValidFileName = fileName.match(regex).length === 1 ? true : false;

    if (isValidFileExtension && isValidSize && isValidFileName) {
      this.setState({ message: "" });
      this.setState((prevState) => ({
        message: "",
        docs: [...prevState.docs, event.target.files[0]],
      }));
    } else if (!isValidFileName) {
      this.setState({
        message:
          "Oops!! File name is not appropriate. Kindly rename the file and try again.",
      });
    } else if (!isValidSize) {
      this.setState({ message: "File size should be less than 5 MB" });
    } else if (!isValidFileExtension) {
      this.setState({
        message: `File with extension .${fileExtension} are not allowed!`,
      });
    }
  };

  join = (t, a, s) => {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);

      return f.format(t);
    }

    return a.map(format).join(s);
  };

  month = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];

  componentDidMount() {
    axios
      // .get(
      //   "https://genie.tag8.in/api/pet/getMedicalDetails/" +
      //     this.state.tag_number
      // )
      .get(`${baseURL}/api/pet/getMedicalDetails/` + this.state.tag_number)
      .then((response) => {
        response.data[0].Vaccination.map((elm) => {
          if (this.state.vaccination_id === elm.vaccination_id) {
            this.setState({
              Vaccination_name: elm.Vaccination_name,
              VaccinationDoctor_name: elm.VaccinationDoctor_name,
              VaccinationAdministration_date: this.join(
                new Date(elm.VaccinationAdministration_date),
                this.month,
                "-"
              ),
              VaccinationNotes: elm.VaccinationNotes,
              VaccinationDone: elm.VaccinationDone,
              VaccinationTitle: elm.VaccinationTitle,
              VaccinationEverynumber: elm.VaccinationEverynumber,
              VaccinationEvery: elm.VaccinationEvery,
              VaccinationNext: elm.VaccinationNext
                ? this.join(new Date(elm.VaccinationNext), this.month, "-")
                : "",
              VaccinationRemind_me: elm.VaccinationRemind_me,
              VaccinationReminderNotes: elm.VaccinationReminderNotes,
              // VaccinationDone: elm.VaccinationDone,
              Vaccination_Reportname: elm.Vaccination_Reportname,
              Vaccination_Reportlocation: elm.Vaccination_Reportlocation,
              Vaccination_Reportnames: elm.Vaccination_ReportsNames,
              Vaccination_Reportlocations: elm.Vaccination_ReportsLocations,
            });
          }
        });

        for (let i = 0; i < this.state.Vaccination_Reportnames.length; i++) {
          let obj = {
            name: this.state.Vaccination_Reportnames[i],
            location: this.state.Vaccination_Reportlocations[i],
          };

          reports.push(obj);
        }

        this.setState({ docs: reports });
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  submitHandler = (e) => {
    e.preventDefault();

    axios
      .post(`${baseURL}/api/pet/updateMedicalDetails`, this.state, {
        headers: { Authorization: usertoken },
      })
      .then((res) => {
        const fd = new FormData();

        for (let i = 0; i < this.state.docs.length; i++) {
          fd.append("image", this.state.docs[i]);
        }

        fd.append("tag_number", this.state.tag_number);
        fd.append("Type", this.state.Type);
        fd.append("vaccination_id", this.state.vaccination_id);

        axios
          .post(`${baseURL}/api/pet/reports-upload`, fd, {
            Accept: "application/json",
          })
          // .post("http://localhost:3020/api/pet/reports-upload", fd, {
          //   Accept: "application/json",
          // })
          .then((response) => {
            window.location.reload();

            console.info(response);
          })
          .catch((error) => {
            console.error(error.message);
          });
      })
      .catch((error) => {
        console.error(error.message);

        this.props.closeDialog();
      });
  };

  render() {
    const {
      vaccination_reminder,
      Vaccination_name,
      VaccinationDoctor_name,
      VaccinationAdministration_date,
      VaccinationNotes,
      VaccinationDone,
      VaccinationTitle,
      VaccinationEverynumber,
      VaccinationEvery,
      VaccinationNext,
      VaccinationRemind_me,
      VaccinationReminderNotes,
      Vaccination_Reportname,
      Vaccination_Reportlocation,
    } = this.state;

    return (
      <div className="update-card-details-form-wrapper">
        <form>
          <h4>Vaccination</h4>

          <div className="update-card-details-form-content">
            <label>Vaccination Name *</label>

            <input
              type="textbox"
              className="update-card-details-form-input"
              name="Vaccination_name"
              required
              value={Vaccination_name}
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Doctor's Name</label>

            <input
              type="textbox"
              className="update-card-details-form-input"
              name="VaccinationDoctor_name"
              value={VaccinationDoctor_name}
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Administration Date *</label>

            <input
              type="date"
              className="update-card-details-form-input"
              name="VaccinationAdministration_date"
              value={VaccinationAdministration_date}
              required
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Notes</label>

            <textarea
              rows="2"
              name="VaccinationNotes"
              value={VaccinationNotes}
              onChange={this.changeHandler}
            />
          </div>

          <Button
            onClick={() => {
              this.setState({
                vaccination_reminder: !vaccination_reminder,
              });
            }}
          >
            <AddAlertIcon />
            Set Reminder
          </Button>

          {vaccination_reminder ? (
            <>
              {/* <div className="row ml-1 mr-1  mt-3 p-1  bg-warning">
                      <p className="ml-2 mb-0 font-weight-bold">Vaccination</p>

                      <Button
                        className="p-0 myexp-btn"
                        style={{ marginLeft: "80px" }}
                        onClick={() => {
                          this.setState({
                            vaccination_reminder: !vaccination_reminder,
                          });
                        }}
                      >
                        <ExpandMoreIcon />
                      </Button>
                    </div> */}

              <div className="update-card-details-form-content">
                <label>Reminder Title</label>

                <input
                  type="textbox"
                  className="update-card-details-form-input"
                  name="VaccinationTitle"
                  value={VaccinationTitle}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="update-card-details-form-content">
                <label>Next Appointment Date</label>

                <input
                  type="date"
                  className="update-card-details-form-input"
                  name="VaccinationNext"
                  value={VaccinationNext}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="update-card-details-form-content">
                <label>Remind Me</label>

                <select
                  className="update-card-details-form-input"
                  value={VaccinationRemind_me}
                  name="VaccinationRemind_me"
                  onChange={this.changeHandler}
                >
                  <option hidden></option>
                  <option value="On The Same Day">On The Same Day</option>
                  <option value="1 Day Before">1 Day Before</option>
                  <option value="2 Days Before">2 Days Before</option>
                  <option value="3 Days Before">3 Days Before</option>
                  <option value="15 Days Before">15 Days Before</option>
                  <option value="1 Month Before">1 Month Before</option>
                </select>
              </div>

              <div className="update-card-details-form-content">
                <label>Notes</label>

                <textarea
                  row="2"
                  name="VaccinationReminderNotes"
                  value={VaccinationReminderNotes}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="update-card-details-form-checkbox">
                <label htmlFor="vac">Done</label>

                <input
                  type="checkbox"
                  checked={VaccinationDone}
                  id="vac"
                  name="VaccinationDone"
                  value={VaccinationDone}
                  onChange={this.vaccinationChangeHandler}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="update-card-details-uploadReport-container">
            <label>
              <AiIcons.AiOutlineUpload /> Report
            </label>

            <input type="file" onChange={this.handleChange} />

            <label style={{ color: "red" }}>{this.state.message}</label>

            {this.state.docs.length > 0
              ? this.state.docs.map((pic, index) => (
                  <label key={index}>{pic.name}</label>
                ))
              : null}

            {Vaccination_Reportlocation ? (
              <Tooltip title={Vaccination_Reportname}>
                <a href={Vaccination_Reportlocation}>
                  <AiIcons.AiFillFileText />
                </a>
              </Tooltip>
            ) : null}
          </div>

          <div className="update-card-details-btn-wrapper">
            <button
              className="update-card-details-btns"
              style={{ backgroundColor: "#ffc107", color: "white" }}
              onClick={this.submitHandler}
            >
              Submit
            </button>

            <button
              className="update-card-details-btns"
              style={{
                color: "black",
              }}
              onClick={this.props.closeDialog}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    );
  }
}
