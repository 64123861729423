import React, { useEffect, useState } from "react";
const initalTimer = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};

function Warrantycounter({travelstartDate}) {
    const [counterTimer, setcounterTimer] = useState(initalTimer);
  const updateRemaining = (travelstartDate) => {
    const travelStartdate = new Date(travelstartDate);
    
    const difference = travelStartdate - new Date();
    if (difference > 0) {
      const differenceInSeconds = Math.floor(difference / 1000);
      const days = Math.floor(differenceInSeconds / (3600 * 24)); // Number of days
      const hours = Math.floor((differenceInSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((differenceInSeconds % 3600) / 60);
      const seconds = differenceInSeconds % 60;
      setcounterTimer({
        ...counterTimer,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });
      //console.log(`Hours: ${hours}, Minutes: ${minutes}, Seconds: ${seconds}`);
    } else {
      //console.log(`diff is 0${difference}`);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemaining(travelstartDate);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="timer-div_digital">
      
      {
       counterTimer.days !== 0 && <>
       <div className="timer_block_div">
       <div  className="smallcounter-div">
       <div>
       <span className="time_instance_div">{counterTimer?.days}</span>
     
 
       </div>
       
       </div>
       <div>
         <span className="time_type">DD</span>
       </div>
       </div>
      
       </>
      
  
      }
     
 
     <div className="timer_block_div">
     <div className="smallcounter-div">
         <div>
         <span className="time_instance_div">{counterTimer?.hours}</span>
       
         </div>
       </div>
       <span className="time_type">HH</span>
     </div>
 
     
 
     <div className="timer_block_div">
     <div className="smallcounter-div">
        <div>
        <span className="time_instance_div">{counterTimer?.minutes}</span>
        
        </div>
       </div>
 <span className="time_type">MM</span>
     </div>
      
 
      {
       counterTimer.days === 0 &&  <div className="timer_block_div">
         <div className="smallcounter-div">
       <div>
       <span className="time_instance_div">{counterTimer?.seconds}</span>
      
       </div>
      </div>
      <span className="time_type">SS</span>
       </div>
      }
     </div>
    </>
  )
}

export default Warrantycounter
