import React, { useState, useEffect } from "react";
import axios from "axios";
import "./humanwelcome.component.css";
// import iphone from "../../assets/iphone.jpg";
// import iphone15 from "../../assets/iphone15.png";
// import { Helmet } from "react-helmet-async";
import { switchUrls } from "../../api/index.js";
import MetaDecorator from "../linkpreview/linkpreview.component";
const baseURL = switchUrls("dolphin");

const uid = localStorage.getItem("uid");

const Humanwelcome = (props) => {
  const [memberslist, setmemberslist] = useState([]);
  const [user, setUser] = useState(null);
  const [metaContent, setMetaContent] = useState(null);

  useEffect(() => {
    axios
      .post(`${baseURL}/api/dolphin/getacceptedlist`, { currentUserUid: uid })
      .then(({ data }) => {
        // console.log(data);

        setmemberslist(data?.requestData);
        const userData =
          data?.requestData.length > 0 ? data.requestData[0] : null;
        setUser(userData);
        // console.log(userData, "userr");
        const newMetaContent = {
          title: `${userData?.first_name} ${userData?.last_name} want to connect with you on dolphin tracker.`,
          description: `Dolphin Tracker is one stop for all your questions! ${userData?.first_name} ${userData?.last_name} wants to connect with you. Download now and share your location, send SOS instantly.`,
          imageUrl: userData?.person_profile_img_link,
          imageAlt: `${userData?.first_name} ${userData?.last_name}`,
          url: `Add me now using this link to share the location and send you SOS in need of help ${window.location.href}`,
        };

        setMetaContent(newMetaContent);
      })
      .catch((er) => {
        // console.log(er);
      });
  }, []);

  // console.log(metaContent, "metacon");
  return (
    <>
      {/* {metaContent && (
        <MetaDecorator
          title={metaContent.title}
          description={metaContent.description}
          image={metaContent.image}
          // imageAlt={metaContent.imageAlt}
          // url={metaContent.url}
        />
      )} */}
      <div>
        <div className="genie-nav" id="genie-nav">
          <img
            src={
              "https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
            }
            alt="logo"
            style={{ width: "5rem" }}
          />
          <div className="download-button">Download</div>
        </div>
        <div className="human-container">
          <div className="content">
            <button className="custom-button">
              How Dolphin Member Tracking Works
            </button>
            <div className="user-info">
              {memberslist.map((member) => (
                <div key={member._id}>
                  <div className="user-info">
                    <div className="user-image-human">
                      <img
                        src={member.person_profile_img_link}
                        alt={`${member.first_name} ${member.last_name}`}
                      />
                    </div>
                    <div className="connect-text">
                      <p>Connect with</p>
                      <p className="user-name">{`${member.first_name} ${member.last_name}`}</p>
                    </div>
                  </div>
                  <div>
                    <p className="description">
                      Adding {`${member.first_name} ${member.last_name}`} on
                      Dolphin Tracker app will help you to connect,
                      <br />
                      share your location, send help via SOS, etc. for free.
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <button className="get-started-button"> Get Started </button>
            <div className="question-section">
              <p className="feeling-lost">
                Feeling Lost in the chaos of Daily life?
              </p>
              <p className="worried-text">
                worried about loved ones getting home safe?
              </p>
              <p className="elderly-text">
                Do you have elderly relatives living alone?
              </p>
            </div>
            <div className="tracker-text">
              <p>Dolphin Tracker is one stop for all your questions!</p>
              <p className="availability-text">
                Dolphin Tracker is available for Android and iOS
              </p>
            </div>

            <div className="feature-box">
              <div className="feature-button">
                <h4>See all Features</h4>
              </div>
            </div>

            <div className="users-location-history-box">
              <div className="users-location-history-container">
                <div className="users-location-history-content-box">
                  <div className="users-location-history-text">
                    <div className="text-content">
                      <div className="users-location-history-title">
                        Users Location History
                      </div>
                      <span style={{ textAlign: "left" }}>
                        Knowing where your loved ones are, especially children
                        or vulnerable adults, can <b>provide peace of mind</b>
                        and allow for quick intervention if needed.
                      </span>
                    </div>
                  </div>
                  <div className="users-location-history-image">
                    <img
                      src={
                        "https://storage.googleapis.com/pettag/qr/assets/iphone.jpg"
                      }
                      alt="Location Image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="users-sos-history-box">
              <div className="users-sos-history-container">
                <div className="users-sos-history-content-box">
                  <div className="users-sos-history-image">
                    <img
                      src={
                        "https://storage.googleapis.com/pettag/qr/assets/iphone15.png"
                      }
                      alt="sos Image"
                    />
                  </div>
                  <div className="users-sos-history-text">
                    <div className="text-sos-content">
                      <div className="users-sos-history-title">Send SOS</div>
                      <span style={{ textAlign: "left", fontSize: "1rem" }}>
                        One tap, instant help. Send an{" "}
                        <b>SOS with your current location</b> even without
                        opening the app. Your loved ones get your location and
                        know you need them.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Humanwelcome;
