import React from 'react'
import './loginanimation.component.css'
function LoginAnimation() {
    return (
        <div className="loginAnimation">
            <div className="context"></div>
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
    )
}

export default LoginAnimation
