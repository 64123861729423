import React, { useState, useEffect } from "react";
import "./welcome.component.css";
import Card from "@material-ui/core/Card";
import axios from "axios";
// import Login from "../../assets/next.png";
import { checkOs } from "../../utils/checkOs.js";
import { switchUrls } from "../../api/index.js";
import moment from "moment";
import Findermodal from "./Findermodal";
import Footer from "../footer/footerblack.jsx";
import { PRODUCTCATEGORY_ETAGS } from "../../utils/setValues.js";

const baseURL = switchUrls("gateway");
const gatewayFrontend = switchUrls("gateway_frontend");

export const Welcome = (props) => {
  const [tagtype, settagtype] = useState("");

  const [lostDate, setLostDate] = useState();
  const [address, setAddress] = useState();
  const [scrollNav, setScrollNav] = useState(false);
  const [initialletter, showinitialletter] = useState("");
  const [petletter, showpetletter] = useState("");
  const [allletter, showallletter] = useState("");
  const [trackerletter, showtrackerletter] = useState("");
  const [trackerwalletletter, showtrackerwalletletter] = useState("");
  const [companyname, setCompany] = useState("");
  const [departuredate, setDeparturedate] = useState("");
  // const [deleteflag, setDeleteFlag] = useState("");
  const [snackbarmsg, setSnackbarMsg] = useState("");
  // const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [bagletter, showbagletter] = useState("");
  const [loading, setLoading] = useState(true);
  const [foundermodal, setfoundermodal] = useState(false);
  const [drawer, setdrawer] = useState(false);
  const [tataaig_email, settataaigemail] = useState(
    "EA.TATAclaims@europa-assistance.in"
  );
  const [tataaig_phone, settataaigphone] = useState("+912268227600");
  const [scanpageheading, setscanpageheading] = useState({
    "QR Passport": "Passport Security",
    "E-Passport": "Passport Security",
    "QR Bag": "Bag Security",
    "E-Bag": "Bag Security",
  });

  const handelform = () => {
    setdrawer(false);
    setfoundermodal(true);
  };

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }
  let usertoken = readCookie("token");

  const handleLoginClick = () => {
    if (usertoken) {
      const uid = localStorage.getItem("uid");

      window.location.href = `${gatewayFrontend}/dashboard/${uid}`;
    } else {
      window.location.href = `${gatewayFrontend}/#/login`;
    }
  };

  let tag_number = props.match.params.tag_number;
  localStorage.setItem("tag_number", tag_number);

  const getproductInformation = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/genericreportfound/` + tag_number
      );
      setDeparturedate(data?.journey_startdate);
      settataaigemail(data?.details?.owner_email);
      if (data?.details?.owner_phonecode && data?.details?.owner_phone) {
        const ownerPh = data?.details?.owner_phone?.replace(/[^0-9]/g, "");
        const ownerPhone = data?.details?.owner_phonecode + ownerPh;

        settataaigphone(ownerPhone);
      } else {
        const ownerPh = data?.details?.owner_phone?.replace(/[^0-9]/g, "");
        settataaigphone(ownerPh);
      }
      return;
    } catch (er) {
      throw er;
    }
  };

  const getTagnumber = async () => {
    try {
      setLoading(true);
      const tag_number = props.match?.params?.tag_number;
      const { data } = await axios.get(`${baseURL}/api/getTagNumber`, {
        params: {
          tag: tag_number,
        },
      });

      if (data?.tagResult?.product_category) {
        const product_category = data?.tagResult?.product_category;

        showinitialletter(product_category);

        if (
          data?.tagResult?.company_name === "Tata_aig" ||
          PRODUCTCATEGORY_ETAGS.includes(product_category)
        ) {
          // if it is a etag or tata aig tag ,welcome page should only rendered to the user if the tag status is inactive
          if (data?.tagResult?.tag_status !== "inactive") {
            window.location.href = `${gatewayFrontend}/#/scan/${tag_number}`;
            return;
          }

          if (data?.tagResult?.company_name === "Tata_aig") {
            const departureDate =
              data?.insuranceResult?.certificateStartDate?.split("T")[0];
            setDeparturedate(departureDate);
            settagtype("Tata_aig");
          } else {
            settagtype("e_tag");
            getproductInformation();
          }
          setLoading(false);
        } else {
          //normal welcome page for dolphin products without countdown
          setLoading(false);
        }
      } else {
        //error
      }
    } catch (er) {
      //error
    }
  };

  useEffect(() => {
    getTagnumber();
  }, []);

  useEffect(() => {
    if (initialletter?.length) {
      // showpetletter(initialletter.includes("QR PET"));
      showallletter(initialletter.includes("QR"));
      showtrackerletter(initialletter.includes("Dolphin"));
      showtrackerwalletletter(initialletter.includes("Smart Wallet"));
    }
  }, [initialletter]);

  useEffect(() => {
    if (tagtype === "Tata_aig" || tagtype === "e_tag") {
      const intervalId = setInterval(() => {
        setTimeRemaining(getTimeRemaining(departuredate));
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [departuredate, tagtype]);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);
  function getTimeRemaining(departuredate) {
    console.log("working...............");
    // Convert journey_startdate to a Date object
    const activeDate = new Date(departuredate);
    //time will be changed to ist if the time is not in that format
    console.log(activeDate);
    if (
      tagtype === "Tata_aig" &&
      activeDate.getUTCHours() === 0 &&
      activeDate.getUTCMinutes() === 0
    ) {
      activeDate.setHours(activeDate.getHours() - 5);
      activeDate.setMinutes(activeDate.getMinutes() - 30);
    }
    // Subtract one day from the startDate to get the Active Since
    activeDate?.setDate(activeDate?.getDate() - 1);
    // console.log(departuredate, "departureeee");
    const targetMoment = moment(activeDate);
    // console.log(targetMoment, "momennett");
    const now = moment();
    const difference = targetMoment.diff(now);
    // console.log(difference, "nowwww");
    const duration = moment.duration(difference);
    // console.log(duration, "difference");
    console.log(difference);
    if (
      difference < 0 ||
      difference === undefined ||
      isNaN(difference) ||
      difference === null
    ) {
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      console.log(days, hours, minutes, seconds);
      // Departure date has passed
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    }

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    console.log(days, hours, minutes, seconds);

    return {
      days: days.toString().padStart(2, "0"),
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    };
  }
  // console.log(timeRemaining, "name of");
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      ) : (
        <>
          {tagtype === "Tata_aig" || tagtype === "e_tag" ? (
            <>
              {foundermodal ? (
                <Findermodal
                  findermodal={foundermodal}
                  setfindermodal={setfoundermodal}
                  tagnumber={props.match.params.tag_number}
                />
              ) : null}

              <div className="tata-welcome-main">
                <div style={{ overflow: "hidden" }}>
                  <div
                    className="genie-nav"
                    id="genie-nav"
                    style={{
                      borderBottom: "1px solid #ddd",
                      paddingBottom: "5px",
                    }}
                  >
                    <img
                      src={
                        "https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
                      }
                      alt="logo"
                      style={{ width: "5rem" }}
                    />
                    {tagtype !== "Tata_aig" && (
                      <div className="genie-nav-button-container">
                        <button
                          className="genie-login-button"
                          onClick={handleLoginClick}
                        >
                          {usertoken ? "Dashboard" : "Login"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="tata-uperpart">
                  <div className="tata-welcome-div">
                    <h1>Welcome</h1>
                    <h1>to</h1>
                    <div className="tata-logo-div">
                      <img
                        src={
                          "https://storage.googleapis.com/pettag/qr/tag8logoblack.png"
                        }
                        alt="tag8 logo"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="tata-lowerpart">
                  <div className="tata-activesoon-div">
                    {departuredate !== undefined ? (
                      <>
                        {timeRemaining.days !== undefined ? (
                          <>
                            <h1>
                              Your {scanpageheading[initialletter]} Tag will be
                              Active Soon
                            </h1>
                          </>
                        ) : (
                          <>
                            <h1>
                              Your {scanpageheading[initialletter]} Tag is
                              inactive
                            </h1>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <h1>
                          Your {scanpageheading[initialletter]} Tag is inactive
                        </h1>
                      </>
                    )}
                  </div>
                  <div>
                    {departuredate ? (
                      <>
                        {departuredate && timeRemaining && (
                          <div className="timer-div">
                            <div className="timer-div">
                              <div className="timer-div">
                                <span className="timer-hrs-div">
                                  <span className="timer-value">
                                    {timeRemaining?.days
                                      ? timeRemaining.days
                                      : "00"}
                                  </span>
                                  <span className="timer-info">Days</span>
                                </span>
                                <span className="timer-dot-div">
                                  <span className="timer-dot-div-1"></span>
                                  <span className="timer-dot-div-2"></span>
                                </span>
                                <span className="timer-hrs-div">
                                  <span className="timer-value">
                                    {timeRemaining?.hours
                                      ? timeRemaining.hours
                                      : "00"}
                                  </span>
                                  <span className="timer-info">HRS</span>
                                </span>

                                <span className="timer-dot-div">
                                  <span className="timer-dot-div-1"></span>
                                  <span className="timer-dot-div-2"></span>
                                </span>

                                <span className="timer-hrs-div">
                                  <span className="timer-value">
                                    {timeRemaining?.minutes
                                      ? timeRemaining.minutes
                                      : "00"}
                                  </span>
                                  <span className="timer-info">MINS</span>
                                </span>

                                <span className="timer-dot-div">
                                  <span className="timer-dot-div-1"></span>
                                  <span className="timer-dot-div-2"></span>
                                </span>

                                <span className="timer-hrs-div">
                                  <span className="timer-value">
                                    {timeRemaining?.seconds
                                      ? timeRemaining.seconds
                                      : "00"}
                                  </span>
                                  <span className="timer-info">SECS</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="tata-details"
                    style={{ paddingBottom: "1.5rem" }}
                  >
                    {departuredate ? (
                      <>
                        <>
                          {tagtype === "Tata_aig" ? (
                            <p>
                              Please note as per your travel insurance policy
                              issued your Passport tag will be active 24 hours
                              before your journey commence.
                            </p>
                          ) : (
                            <p>
                              Please note your{" "}
                              {scanpageheading[initialletter] === "Bag Security"
                                ? "bag"
                                : "passport"}{" "}
                              tag will be active 24 hours before your journey
                              commence.
                            </p>
                          )}
                        </>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="extrabottomdiv"></div>

                  <div style={{ marginTop: "3rem" }} className="lg_footer">
                    <Footer />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="nav"
                style={{ background: scrollNav ? "white" : "transparent" }}
              ></div>

              <div className="pb-3" id="contentToConvert">
                <Card className="mb-5 mat-card-welcome">
                  <div className="row bg-gray row-center text-center pa">
                    <p className="header-font lost-valuable header-text mt-5 pb-4">
                      Welcome
                      <br />
                      to
                    </p>
                  </div>

                  <div className="image-content-wrapper">
                    <div className="col-md-5 img-container colorw">
                      <div className="main-img">
                        {allletter ? (
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qr/tag8logoblack.png"
                            }
                            alt="tag8 logo"
                            className="mat-card-image3"
                            width="auto"
                            height="140px"
                            loading="lazy"
                          />
                        ) : (
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qr/tag8logoblack.png"
                            }
                            alt="tag8 logo"
                            className="mat-card-image3"
                            width="auto"
                            height="140px"
                            loading="lazy"
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-md-7 colorw">
                      {allletter ? (
                        <p className="lost-info-text2">
                          Thank you for choosing to secure your valuable with
                          us!
                          <br />
                          <br />
                          You are just a few clicks away from securing your
                          valuable
                        </p>
                      ) : trackerletter || trackerwalletletter ? (
                        <p className="lost-info-text2">
                          Download the Dolphin Tracker App and connect the
                          Tracker.{" "}
                          {/* <a
                    className="download-btn-apk"
                    href={
                      checkOs("Android")
                        ? "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker"
                        : "https://apps.apple.com/in/app/dolphin-tracker/id1524423508"
                    }
                  >
                    click here
                  </a> */}
                        </p>
                      ) : (
                        ""
                      )}
                      {/* : trackerletter || trackercameraletter || trackerwalletletter || bagletter ? (
              <p className="lost-info-text2">
                Download the Dolphin Tracker App and connect the Tracker.{" "}
                <a
                  className="download-btn-apk"
                  href={
                    checkOs("Android")
                      ? "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker"
                      : "https://apps.apple.com/in/app/dolphin-tracker/id1524423508"
                  }
                >
                  click here
                </a>
              </p>
              ) */}
                    </div>
                  </div>

                  {lostDate || address ? (
                    <div className="row bg-gray row-center mt-2 colorw text-center">
                      <div style={{ width: "100%" }}>
                        <p className="lost-info-text2"></p>
                      </div>
                    </div>
                  ) : null}

                  <div className="text-center pt-e bottom-div2">
                    {petletter || bagletter || allletter ? (
                      <p style={{ fontSize: "1.5rem" }}>
                        Login To Activate Tag
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="bottom-inner-div">
                      <div>
                        {petletter || allletter ? (
                          <a
                            href={`${baseURL}/#/login`}
                            style={{ textDecoration: "none" }}
                          >
                            <p className="pabr flex-between welcome-footer-font text-center">
                              <div style={{ display: "inline-block" }}>
                                <img
                                  src={
                                    "https://storage.googleapis.com/pettag/qr/assets/next.png"
                                  }
                                  alt="email login"
                                  className="vector"
                                  loading="lazy"
                                />
                                &nbsp; Login
                              </div>
                            </p>
                          </a>
                        ) : trackerletter || trackerwalletletter ? (
                          <a
                            href={
                              checkOs("Android")
                                ? "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker"
                                : "https://apps.apple.com/in/app/dolphin-tracker/id1524423508"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <p
                              style={{
                                border: "0.5px solid gray",
                                borderRadius: "10px",
                                display: "flex",
                                gap: "11px",
                                alignItems: "center",
                                padding: "1rem",
                                backgroundColor: "white",
                              }}
                            >
                              <span>
                                <img
                                  src={
                                    "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/Logo.png"
                                  }
                                  alt="email login"
                                  className="vector"
                                  loading="lazy"
                                />
                              </span>
                              <span>Download Dolphin Tracker App</span>
                            </p>
                          </a>
                        ) : (
                          <a
                            href={`${baseURL}/#/login`}
                            style={{ textDecoration: "none" }}
                          >
                            <p className="pabr flex-between welcome-footer-font text-center">
                              <div style={{ display: "inline-block" }}>
                                <img
                                  src={
                                    "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/email.png"
                                  }
                                  alt="email login"
                                  className="vector"
                                  loading="lazy"
                                />
                                &nbsp; Login
                              </div>
                            </p>
                          </a>
                        )}
                      </div>
                      <div>
                        <a
                          href={`mailto:support@tag8.in`}
                          style={{ textDecoration: "none" }}
                        >
                          <p className="pabr flex-between welcome-footer-font text-center">
                            <div style={{ display: "inline-block" }}>
                              <img
                                src={
                                  "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/email.png"
                                }
                                alt="contact support"
                                className="vector"
                                loading="lazy"
                              />
                              &nbsp; Contact Support
                            </div>
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Welcome;
