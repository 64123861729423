import React from "react";
import "./reportlost.component.css";
import { useEffect, useState, useRef } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { event } from "jquery";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Snackbar from "@material-ui/core/Snackbar";
import StepLabel from "@material-ui/core/StepLabel";
import IconButton from "@material-ui/core/IconButton";

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}
function getSteps() {
  return ["Loss Details", "Location", "Date & Time of Loss", "Tag Details"];
}

const steps = getSteps();

let usertoken = readCookie("token");

export class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: 19.002157,
      longitude: 72.841576,
      maplocation: {
        name: "React",
        latitude: 19.002157,
        longitude: 72.841576,
      },
      changelocation: {
        latitude: "",
        longitude: "",
      },
      // activeStep: 0,
    };

    this.getCoordinates = this.getCoordinates.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    // this.clickposition = this.clickposition.bind(this);
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getCoordinates(position) {
    // console.log("======================>", position);
    this.setState({
      latitude: parseFloat(position.coords.latitude),
      longitude: parseFloat(position.coords.longitude),
    });
  }

  updatePosition = (event, index) => {
    // console.log("drag end", event);
    // console.log("drag index", index);
  };

  onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    let data = {
      lat,
      lng,
    };

    this.props.sendlatitude(data);

    this.setState({
      latitude: parseFloat(lat),
      longitude: parseFloat(lng),
    });

    // this.setState(prevState => {
    //   const markers = [...this.state.markers];
    //   markers[index] = { ...markers[index], position: { lat, lng } };
    //   return { markers };
    // });
  };

  render() {
    const coords = {
      lat: this.state.latitude,
      lng: this.state.longitude,
    };
    const { latitude, longitude } = this.state;
    return (
      <>
        <div>
          <Map
            google={this.props.google}
            zoom={15}
            initialCenter={this.setState((prevState) => {
              // console.log();
            })}
            center={coords}
            // onClick={(event) => console.log(event.)}
            className="googlemaps"
          >
            <Marker
              draggable={true}
              position={coords}
              onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
            />
          </Map>
        </div>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAAwak05wJ9r_l4XUfs468Zu1CmF-It0y8",
})(MapContainer);
