import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

// import Logo from 'https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png';
// import Bagimage from '../../assets/bagpack.png'

// import other from "https://storage.googleapis.com/pettag/qrtagdev/assets/other.png";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "./lost-foundposter.css";
import axios from "axios";

import html2canvas from "html2canvas";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default class LostFoundPoster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      owner_firstname: "Tag 8 Support",
      owner_lastname: "",
      valuable_type: "",
      description: "",
      bag_color: "",
      bag_brand: "",
      identification_mark: "",
      owner_phone: "+919029008248",
      owner_email: "support@tag8.in",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      address: "",
      bag_imagelocation: "",
    };
  }

  componentDidMount() {
    // axios.get('/api/bag/getreportlocation/'+this.props.match.params.tag_number).then(response =>{
    //     console.log(response)
    //     this.setState({
    //         address: response.data.data[0].address
    //     })
    // }).catch(error =>{
    //     console.log(error)
    // })

    // axios.get('/api/bag/getBagImagePoster/'+this.props.match.params.tag_number).then(response =>{
    //     console.log(response)
    //     this.setState({
    //         bag_imagelocation:response.data.bag_imagelocation
    //     })
    // }).catch(error =>{
    //     console.log(error)
    // })

    axios
      .get("/api/gettagDetailsByNumber/" + this.props.match.params.tag_number)
      .then((response) => {
        // console.log(response.data)
        this.setState({
          // owner_firstname: response.data.owner_firstname,
          // owner_lastname: response.data.owner_lastname,
          valuable_type: response.data[0].valuable_type,
          description: response.data[0].description
            ? response.data[0].description
            : response.data[0].valuable_desc,
          // bag_color: response.data.bag_color,
          // bag_brand: response.data.bag_brand,
          // identification_mark:response.data.identification_mark,
          owner_phone: this.state.owner_phone,
          owner_email: this.state.owner_email,
          // other_noofbags:response.data.other_noofbags,
          lost_text: response.data[0].lost_text,
          lost_date: response.data[0].lost_date,
          reward: response.data[0].reward,
          rewardcurrency: response.data[0].rewardcurrency,
          lost_timeone: response.data[0].lost_timeone,
          lost_timetwo: response.data[0].lost_timetwo,
          latitude: response.data[0].latitude,
          longitude: response.data[0].longitude,
        });
      })
      .catch((error) => {
        // console.log(error)
      });
  }

  downloadposter() {
    let elem = document.getElementById("contentToConvert");
    html2canvas(elem, { useCORS: true, allowTaint: true }).then(function (
      canvas
    ) {
      let generatedImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let a = document.createElement("a");
      a.href = generatedImage;
      a.download = `lost-foundPoster.png`;
      a.click();
    });
  }
  render() {
    const {
      owner_firstname,
      owner_lastname,
      bag_color,
      bag_brand,
      valuable_type,
      description,
      lost_text,
      identification_mark,
      owner_phone,
      owner_email,
      reward,
      rewardcurrency,
      lost_date,
      address,
      bag_imagelocation,
    } = this.state;
    return (
      <div>
        <div
          className="row text-center pt-4"
          style={{ width: "60%", marginLeft: "20%" }}
        >
          {/* <div className="col">
                <Button variant="contained" className="bg-warning postar-res button-styling mt-3" onClick={this.downloadposter} style={{'width':'80%'}}>Download</Button>
            </div> */}
          {/* <div className="col">
                <a href={"/edit-report-lost/"+this.props.match.params.tag_number} style={{'textDecoration':'none'}}><Button variant="contained" className="bg-warning postar-res button-styling mt-3" style={{'width':'80%'}}>Edit</Button></a>
            </div> */}
          <div className="col">
            <Button
              variant="contained"
              onClick={() => {
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              }}
              className="bg-warning postar-res button-styling mt-3"
              style={{ width: "80%" }}
            >
              Go To My Tags
            </Button>
          </div>
        </div>
        <div id="contentToConvert" className="py-3">
          <Card className="mb-5 poster-mat-card">
            <div className="row text-center">
              {valuable_type ? (
                <p className="header-font lost-valuable header-text mt-5">
                  LOST {valuable_type.toUpperCase()} !
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-md-5">
                {bag_imagelocation ? (
                  <img
                    alt=""
                    src={`data:image/png;base64,${bag_imagelocation}`}
                    className="mat-card-image pt-5 pl-5"
                    width="auto"
                    height="340px"
                  />
                ) : (
                  <img
                    alt=""
                    className="mat-card-image pt-5 pl-5"
                    src={
                      "https://storage.googleapis.com/pettag/qrtagdev/assets/other.png"
                    }
                    width="auto"
                    height="340px"
                  />
                )}
              </div>
              <div className="col-md-7">
                <div className="row mt-5"></div>
                <hr />

                <div className="row">
                  {lost_text ? (
                    <div className="col text-center">
                      <p className="header-font lost-info-header lost-info-header-res">
                        Lost Message
                      </p>
                      <p className="text-center lost-info-text">
                        {toTitleCase(lost_text)}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {description ? (
                    <div className="col text-center">
                      <p className="header-font lost-info-header lost-info-header-res">
                        BAG DESCRIPTION
                      </p>
                      <p className="text-center lost-info-text">
                        {toTitleCase(description)}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <hr />

                {lost_date || address ? (
                  <div className="row">
                    <div className="col text-center">
                      <p className="header-font lost-info-header">LAST SEEN</p>
                      <p className="text-center lost-info-text">{lost_date}</p>
                      {/* <p className="text-center lost-info-text-address">{address}</p> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="p-3 text-center answers-to-name mt-3 mb-3">
              <h1
                className="answers-to-name-header"
                style={{ wordWrap: "break-word" }}
              >
                ANSWER TO THE NAME OF
              </h1>
              <h1
                className="answers-to-name-header mt-3"
                style={{ wordWrap: "break-word" }}
              >
                {valuable_type}
              </h1>
            </div>

            <div className="row text-center mb-4">
              <div className="col-md">
                <p className="bottom-text-1 mt-4">PLEASE</p>
                <p className="bottom-text-1">
                  <span className="bottom-help-text">HELP US &nbsp; </span>FIND
                </p>
                {/* <p className="bottom-text-1">Bag</p> */}
                {owner_phone ? (
                  <div>
                    <p className="answers-to-name-text-1 header-font text-center">
                      Call : {owner_firstname} {owner_lastname}
                    </p>
                    <p className="answers-to-name-text-1 header-font text-center">
                      {owner_phone}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {rewardcurrency && reward ? (
                <div className="col-md mt-4">
                  <p className="header-font reward-header bottom-text-res">
                    REWARD
                  </p>
                  <p className="bottom-text bottom-text-res mt-5">
                    {rewardcurrency}
                    <br /> <br />
                    {reward}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <hr />
            <div className="row text-center">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
                }
                className="mb-3 text-center"
                width="100px"
                height="50px"
                alt=""
                style={{ margin: "auto" }}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
