import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";
import "./updatecards.component.css";
import Button from "@material-ui/core/Button";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import * as AiIcons from "react-icons/ai";
import { switchUrls } from "../../../api/index.js";

const baseURL = switchUrls("genie");
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let reports = [];

export default class MedicalconditionUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      selectedImage: null,
      medicalcondition_reminder: false,
      tag_number: localStorage.getItem("tag_number"),
      Type: "Medicalcondition",
      medicalcondition_id: this.props.medicalcondition_id,
      Medicalcondition_type: "",
      MedicalconditionDoctor_name: "",
      MedicalconditionDiagnosed_date: "",
      Medicalcondition_enddate: "",
      MedicalconditionSeverity: "",
      MedicalconditionNotes: "",
      MedicalconditionTitle: "",
      MedicalconditionEverynumber: "",
      MedicalconditionEvery: "",
      MedicalconditionNext: "",
      MedicalconditionRemind_me: "",
      MedicalconditionReminderNotes: "",
      MedicalconditionDone: false,
      Medicalcondition_Reportname: "",
      Medicalcondition_Reportlocation: "",
      Medicalcondition_Reportnames: [],
      Medicalcondition_Reportlocations: [],
      message: "",
      docs: [],
    };
  }

  join = (t, a, s) => {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);

      return f.format(t);
    }

    return a.map(format).join(s);
  };

  month = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];

  componentDidMount() {
    axios
      .get(`${baseURL}/api/pet/getMedicalDetails/` + this.state.tag_number)
      .then((response) => {
        response.data[0].Medicalcondition.map((elm) => {
          if (this.state.medicalcondition_id === elm.medicalcondition_id) {
            this.setState({
              Medicalcondition_type: elm.Medicalcondition_type,
              MedicalconditionDoctor_name: elm.MedicalconditionDoctor_name,
              MedicalconditionDiagnosed_date: this.join(
                new Date(elm.MedicalconditionDiagnosed_date),
                this.month,
                "-"
              ),
              MedicalconditionSeverity: elm.MedicalconditionSeverity,
              MedicalconditionNotes: elm.MedicalconditionNotes,
              MedicalconditionTitle: elm.MedicalconditionTitle,
              MedicalconditionNext: elm.MedicalconditionNext
                ? this.join(new Date(elm.MedicalconditionNext), this.month, "-")
                : "",
              MedicalconditionRemind_me: elm.MedicalconditionRemind_me,
              MedicalconditionReminderNotes: elm.MedicalconditionReminderNotes,
              MedicalconditionDone: elm.MedicalconditionDone,
              Medicalcondition_Reportname: elm.Medicalcondition_Reportname,
              Medicalcondition_Reportlocation:
                elm.Medicalcondition_Reportlocation,
              Medicalcondition_Reportnames: elm.Medicalcondition_ReportsNames,
              Medicalcondition_Reportlocations:
                elm.Medicalcondition_ReportsLocations,
            });
          }
        });
        for (
          let i = 0;
          i < this.state.Medicalcondition_Reportnames.length;
          i++
        ) {
          let obj = {
            name: this.state.Medicalcondition_Reportnames[i],
            location: this.state.Medicalcondition_Reportlocations[i],
          };
          reports.push(obj);
        }
        this.setState({ docs: reports });
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  setReports = () => {
    for (let i = 0; i < this.state.Medicalcondition_ReportsNames.length; i++) {
      let obj = {
        name: this.state.Medicalcondition_ReportsNames[i],
        location: this.state.Medicalcondition_ReportsLocations[i],
      };
      reports.push(obj);
    }
    this.setState({ docs: reports });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  medicalconditionChangeHandler = (e) => {
    this.setState({ [e.target.name]: !this.state.MedicalconditionDone });
  };

  handleChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileSize = file.size;

    const fileExtension = fileName.split(".")[1];
    const size = fileSize / (1024 * 1024);

    const regex = /\./gm;

    const isValidFileExtension = [
      "pdf",
      "docx",
      "docs",
      "png",
      "jpg",
      "jpeg",
      "csv",
      "xlsx",
    ].includes(fileExtension);
    const isValidSize = size < 5 ? true : false;
    const isValidFileName = fileName.match(regex).length === 1 ? true : false;

    if (isValidFileExtension && isValidSize && isValidFileName) {
      this.setState({ message: "" });
      this.setState((prevState) => ({
        message: "",
        docs: [...prevState.docs, event.target.files[0]],
      }));
    } else if (!isValidFileName) {
      this.setState({
        message:
          "Oops!! File name is not appropriate. Kindly rename the file and try again.",
      });
    } else if (!isValidSize) {
      this.setState({ message: "File size should be less than 5 MB" });
    } else if (!isValidFileExtension) {
      this.setState({
        message: `File with extension .${fileExtension} are not allowed!`,
      });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();

    axios
      .post(`${baseURL}/api/pet/updateMedicalDetails`, this.state, {
        headers: { Authorization: usertoken },
      })
      .then((res) => {
        const fd = new FormData();

        for (let i = 0; i < this.state.docs.length; i++) {
          fd.append("image", this.state.docs[i]);
        }

        fd.append("tag_number", this.state.tag_number);
        fd.append("Type", this.state.Type);
        fd.append("medicalcondition_id", this.state.medicalcondition_id);

        axios
          .post(`${baseURL}/api/pet/reports-upload`, fd, {
            Accept: "application/json",
          })
          .then((response) => {
            window.location.reload();

            console.info(response);
          })
          .catch((error) => {
            console.error(error.message);
          });
      })
      .catch((error) => {
        console.error(error.message);

        this.props.closeDialog();
      });
  };

  render() {
    const {
      medicalcondition_reminder,
      Medicalcondition_type,
      MedicalconditionDoctor_name,
      MedicalconditionDiagnosed_date,
      Medicalcondition_enddate,
      MedicalconditionSeverity,
      MedicalconditionNotes,
      MedicalconditionTitle,
      MedicalconditionEverynumber,
      MedicalconditionEvery,
      MedicalconditionNext,
      MedicalconditionRemind_me,
      MedicalconditionReminderNotes,
      MedicalconditionDone,
      Medicalcondition_Reportname,
      Medicalcondition_Reportlocation,
    } = this.state;

    return (
      <div className="update-card-details-form-wrapper">
        <form>
          <h4>Medical Condition</h4>

          <div className="update-card-details-form-content">
            <label>Medical Condition Type *</label>

            <input
              type="textbox"
              className="update-card-details-form-input"
              name="Medicalcondition_type"
              required
              value={Medicalcondition_type}
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Doctor's Name</label>

            <input
              type="textbox"
              className="update-card-details-form-input"
              name="MedicalconditionDoctor_name"
              value={MedicalconditionDoctor_name}
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Diagnosis Date *</label>

            <input
              type="date"
              className="update-card-details-form-input"
              name="MedicalconditionDiagnosed_date"
              required
              value={MedicalconditionDiagnosed_date}
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Severity</label>

            <input
              type="textbox"
              className="update-card-details-form-input"
              name="MedicalconditionSeverity"
              value={MedicalconditionSeverity}
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Notes</label>

            <textarea
              rows="2"
              name="MedicalconditionNotes"
              value={MedicalconditionNotes}
              onChange={this.changeHandler}
            />
          </div>

          <Button
            onClick={() => {
              this.setState({
                medicalcondition_reminder: !medicalcondition_reminder,
              });
            }}
          >
            <AddAlertIcon />
            Set Reminder
          </Button>

          {medicalcondition_reminder ? (
            <>
              {/* <div className="row ml-1 mr-1 mt-3 bg-warning">
                      <p className="ml-2 mb-0 font-weight-bold">
                        Medical Condition
                      </p>
                      <Button
                        className="p-0 measure-update-btn med-con-update-btn"
                        style={{ marginLeft: "80px" }}
                        onClick={() => {
                          this.setState({
                            medicalcondition_reminder:
                              !medicalcondition_reminder,
                          });
                        }}
                      >
                        <ExpandMoreIcon />
                      </Button>
                    </div> */}

              <div className="update-card-details-form-content">
                <label>Reminder Title</label>

                <input
                  type="textbox"
                  className="update-card-details-form-input"
                  name="MedicalconditionTitle"
                  value={MedicalconditionTitle}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="update-card-details-form-content">
                <label>Next Appointment Date</label>

                <input
                  type="date"
                  className="update-card-details-form-input"
                  name="MedicalconditionNext"
                  value={MedicalconditionNext}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="update-card-details-form-content">
                <label>Remind Me</label>

                <select
                  className="update-card-details-form-input"
                  name="MedicalconditionRemind_me"
                  value={this.state.MedicalconditionRemind_me}
                  onChange={this.changeHandler}
                >
                  <option hidden></option>
                  <option value="On The Same Day">On The Same Day</option>
                  <option value="1 Day Before">1 Day Before</option>
                  <option value="2 Days Before">2 Days Before</option>
                  <option value="3 Days Before">3 Days Before</option>
                  <option value="15 Days Before">15 Days Before</option>
                  <option value="1 Month Before">1 Month Before</option>
                </select>
              </div>

              <div className="update-card-details-form-content">
                <label>Notes</label>

                <textarea
                  row="2"
                  name="MedicalconditionReminderNotes"
                  value={MedicalconditionReminderNotes}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="update-card-details-form-checkbox">
                <label htmlFor="all">Done</label>

                <input
                  type="checkbox"
                  checked={MedicalconditionDone}
                  id="all"
                  name="MedicalconditionDone"
                  value={MedicalconditionDone}
                  onChange={this.medicalconditionChangeHandler}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="update-card-details-uploadReport-container">
            <label>
              <AiIcons.AiOutlineUpload /> Report
            </label>

            <input type="file" onChange={this.handleChange} />

            <label style={{ color: "red" }}>{this.state.message}</label>

            {this.state.docs.length
              ? this.state.docs.map((rep) => (
                  <Tooltip title={rep.name}>
                    <a href={rep.location}>
                      <AiIcons.AiFillFileText />
                    </a>
                  </Tooltip>
                ))
              : null}

            {Medicalcondition_Reportlocation ? (
              <Tooltip title={Medicalcondition_Reportname}>
                <a href={Medicalcondition_Reportlocation}>
                  <AiIcons.AiFillFileText />
                </a>
              </Tooltip>
            ) : null}
          </div>

          <div className="update-card-details-btn-wrapper">
            <button
              className="update-card-details-btns"
              style={{ backgroundColor: "#ffc107", color: "white" }}
              onClick={this.submitHandler}
            >
              Submit
            </button>

            <button
              className="update-card-details-btns"
              style={{
                color: "black",
              }}
              onClick={this.props.closeDialog}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    );
  }
}
