import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./reportlost.component.css";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import { ToggleButton } from "@material-ui/lab";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import axios from "axios";
import Footer from "../footer/footer.component.jsx";
import MapContainer from "../report-lost-form/mapcontainer";
import "date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// import Userimage from "https://storage.googleapis.com/pettag/qrtagdev/assets/user2.png";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import * as FaIcons from "react-icons/fa";
import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../api/index.js";
// import { genieurl } from "../../api/route";
const baseURL = switchUrls("genie");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let token = usertoken;

function getSteps() {
  return ["Loss Details", "Location", "Date & Time of Loss", "Tag Details"];
}

const steps = getSteps();

export default class ReportLost extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      sidebar: true,
      subMenu: true,
      alignment: "left",
      tag_number: this.props.match.params.tag_number,
      lf_status: "lost",
      description: "",
      valuable_type: "",
      pet_age: "",
      pet_name: "",
      pet_bloodgrp: "",
      pet_breed: "",
      pet_color: "",
      pet_dob: "",
      pet_ident: "",
      veternity: "",
      pet_gender: "",
      identification_mark: "",
      parent_firstname: "",
      parent_lastname: "",
      phone_one: "",
      phone_two: "",
      phone_three: "",
      parent_email: "",
      parent_altemail: "",
      lost_text: "",
      lost_date: "2023-03-28",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "12:45:25",
      lost_timetwo: "03:45:25",
      latitude: "",
      longitude: "",
      dr_phone: "",
      pet_imagelocation: "",
      snackbaropen: false,
      snackbarmsg: "",
      activeStep: 0,
    };

    // this.getCoordinates = this.getCoordinates.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    // this.clickPosition = this.clickPosition.bind(this);
  }

  handleAlignment = (event, alignment) => this.setState({ alignment });

  componentDidMount() {
    const access_token = token;
    // console.log(this.props.match.params.tag_number);

    axios
      .get(`/api/getAllLFtagsById/${this.props.match.params.tag_number}`, {})
      .then((res) => {
        if (res.data.data.length === 0) {
          //shownotfound(true);

          window.location.href = "/#/tags-not-found";
          // /tags-not-found

          //Check if the Tag is L&F Tag
        } else if (res.data.data.length > 0) {
          // console.log(res.data.data);
          this.setState({ valuable_type: res.data.data[0].itemName });
          this.setState({ description: res.data.data[0].comments });
          this.setState({ dr_phone: res.data.data[0].tagProduct_ActiveDate });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  sendlatitude = (data) => {
    // console.log(data);
    this.setState({
      latitude: data.lat,
      longitude: data.lng,
    });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // clickPosition = (val) => {
  //   console.log("argument", val);
  //   // this.setState({
  //   //   latitude: val,
  //   // });
  // };
  dateChangeHandler = (Date) => {
    // console.log(Date.target.value);
    this.setState({ [Date.target.name]: Date.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  submitHandler = (e) => {
    e.preventDefault();

    const tag_number = this.state.tag_number;
    const mid = localStorage.getItem("mid");
    // console.log("this.state--->",this.state);

    const state = {
      tag_number: tag_number,
      tag_status: "active",
      lf_status: "lost",
      valuable_type: this.state.valuable_type,
      valuable_desc: this.state.description,
      product_category: this.state.valuable_type,
      lost_text: this.state.lost_text,
      lost_date: this.state.lost_date,
      lost_timeone: this.state.lost_date,
      lost_timetwo: this.state.lost_date,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      reward: this.state.reward,
      rewardcurrency: this.state.rewardcurrency,
      create_date: this.state.dr_phone,
      user: mid,
    };

    // console.log(state);

    axios
      .post("/api/lostfoundtags/reportLost", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data != null) {
          // window.location.href = "/dashboard/" + localStorage.getItem("uid");

          window.location.href = "/#/poster-lost-found-tags/" + tag_number;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  getStepContent = (stepIndex) => {
    const {
      lost_text,
      lost_date,
      latitude,
      longitude,
      lost_timeone,
      lost_timetwo,
      alignment,
      tag_number,
      valuable_type,
      description,
      parent_firstname,
      parent_lastname,
      phone_one,
      phone_two,
      parent_email,
      parent_altemail,
      reward,
      rewardcurrency,
      pet_age,
      pet_name,
      pet_breed,
      pet_gender,
      pet_color,
      pet_dob,
      pet_bloodgrp,
      pet_ident,
      veternity,
      dr_phone,
      phone_three,
    } = this.state;

    switch (stepIndex) {
      case 0:
        return (
          <div className="reportlost-loss-details">
            <textarea
              rows="8"
              className="firstComment"
              name="lost_text"
              placeholder="Give a brief account of loss. [Eg] Were you in a cab?"
              value={lost_text}
              onChange={this.changeHandler}
            ></textarea>

            <div className="reportlost-loss-details-btns">
              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>

              <Button
                variant="contained"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="reportlost-location">
            <div className="reportlost-location-map-container">
              <MapContainer sendlatitude={this.sendlatitude} />
            </div>

            {/* <div className="reportlost-location-input-cnt">
              <div className="reportlost-location-input-cnt-lat">
                <span className="">Latitude</span>

                <input
                  type="text"
                  className="reportlost-location-input"
                  value={latitude}
                  name="latitude"
                  onChange={this.changeHandler}
                  readOnly
                />
              </div>

              <div className="reportlost-location-input-cnt-lon">
                <span className="">Longitude</span>

                <input
                  type="text"
                  className="reportlost-location-input"
                  value={longitude}
                  name="longitude"
                  onChange={this.changeHandler}
                  readOnly
                />
              </div>
            </div> */}

            <div className="reportlost-location-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                value="right"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="date-time-loss">
            <span>
              <input
                type="date"
                className="date-time-loss-input"
                name="lost_date"
                value={lost_date}
                onChange={this.changeHandler}
              />
            </span>

            <span className="date-time-loss-time-picker">
              <h5 className="">Set indicative time range of loss</h5>

              <input
                type="time"
                className="date-time-loss-input"
                name="lost_timeone"
                value={lost_timeone}
                onChange={this.changeHandler}
              />

              <input
                type="time"
                className="date-time-loss-input"
                name="lost_timetwo"
                value={lost_timetwo}
                onChange={this.changeHandler}
              />
            </span>

            <div className="date-time-loss-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="reportlost-container">
            <span className="reportlost-container-pet-details-span">
              <h4>Tag Details</h4>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-details-span-input-cnt">
                  <span>Tag Number</span>

                  <input
                    type="textbox"
                    value={tag_number}
                    name="tag_number"
                    readOnly
                    required
                  />
                </div>

                <div className="reportlost-container-pet-details-span-input-cnt">
                  <span>Product Type </span>

                  <input
                    type="textbox"
                    value={valuable_type}
                    name="valuable_type"
                    readOnly
                    required
                  />
                </div>
              </span>

              <div className="reportlost-container-pet-details-span-input-cnt">
                <span>Description </span>

                <input
                  type="textbox"
                  id="nextfieldpetname"
                  maxLength="20"
                  required
                  name="description"
                  value={description}
                  readOnly
                />
              </div>
            </span>

            <span className="reportlost-container-owner-reward-span">
              <h4>
                <i className="fa fa-gift fa-2x"></i>
                <span>Owner Reward</span>
              </h4>

              <span className="reportlost-one-line">
                <div className="reportlost-container-owner-reward-span-select-cnt">
                  <span>Currency type</span>

                  <select
                    name="rewardcurrency"
                    value={rewardcurrency}
                    onChange={this.changeHandler}
                  >
                    <option hidden></option>
                    <option value="Rupees">Indian Rupees</option>
                    <option value="U.S. dollar">U.S. dollar</option>
                    <option value="Euro">Euro</option>
                    <option value="Dirham">Dirham</option>
                    <option value="Dinar">Dinar</option>
                    <option value="Yen">Yen</option>
                    <option value="Afghani">Afghani</option>
                    <option value="Lek">Lek</option>
                    <option value="New Kwanza">New Kwanza</option>
                    <option value="Dram">Dram</option>
                    <option value="Bahamian dollar">Bahamian dollar</option>
                    <option value="Bahrain dinar">Bahrain dinar</option>
                    <option value="Taka">Taka</option>
                    <option value="Barbados dollar">Barbados dollar</option>
                    <option value="Belorussian ruble">Belorussian ruble</option>
                    <option value="Belize dollar">Belize dollar</option>
                    <option value="Ngultrum">Ngultrum</option>
                    <option value="Boliviano">Boliviano</option>
                    <option value="Convertible Mark">Convertible Mark</option>
                    <option value="Pula">Pula</option>
                    <option value="Real">Real</option>
                    <option value="Brunei dollar">Brunei dollar</option>
                    <option value="Lev">Lev</option>
                    <option value="Burundi franc">Burundi franc</option>
                    <option value="Riel">Riel</option>
                    <option value="Canadian dollar">Canadian dollar</option>
                    <option value="Cape Verdean escudo">
                      Cape Verdean escudo
                    </option>
                    <option value="Chilean Peso">Chilean Peso</option>
                    <option value="Chinese Yuan">Chinese Yuan</option>
                    <option value="Colombian Peso">Colombian Peso</option>
                    <option value="Franc">Franc</option>
                    <option value="United States dollar">
                      United States dollar
                    </option>
                    <option value="Colón">Colón</option>
                    <option value="Croatian">Croatian</option>
                    <option value="Cuban Peso">Cuban Peso</option>
                    <option value="Koruna">Koruna</option>
                    <option value="Danish Krone">Danish Krone</option>
                    <option value="Djiboutian franc">Djiboutian franc</option>
                    <option value="Dominican Peso">Dominican Peso</option>
                    <option value="Egyptian pound">Egyptian pound</option>
                    <option value="Colón; U.S. dollar">
                      Colón; U.S. dollar
                    </option>
                    <option value="Nakfa">Nakfa</option>
                    <option value="Birr">Birr</option>
                    <option value="Fiji dollar">Fiji dollar</option>
                    <option value="Dalasi">Dalasi</option>
                    <option value="Lari">Lari</option>
                    <option value="Cedi">Cedi</option>
                    <option value="Quetzal">Quetzal</option>
                    <option value="Guinean franc">Guinean franc</option>
                    <option value="Guyanese dollar">Guyanese dollar</option>
                    <option value="Gourde">Gourde</option>
                    <option value="Lempira">Lempira</option>
                    <option value="Forint">Forint</option>
                    <option value="Icelandic króna">Icelandic króna</option>
                    <option value="Rupiah">Rupiah</option>
                    <option value="Iraqi Dinar">Iraqi Dinar</option>
                    <option value="Shekel">Shekel</option>
                    <option value="Jamaican dollar">Jamaican dollar</option>
                    <option value="Jordanian dinar">Jordanian dinar</option>
                    <option value="Tenge">Tenge</option>
                    <option value="Kenya shilling">Kenya shilling</option>
                    <option value="Kiribati dollar">Kiribati dollar</option>
                    <option value="Won">Won</option>
                    <option value="Kuwaiti Dinar">Kuwaiti Dinar</option>
                    <option value="Som">Som</option>
                    <option value="New Kip">New Kip</option>
                    <option value="Lats">Lats</option>
                    <option value="Lebanese pound">Lebanese pound</option>
                    <option value="Maluti">Maluti</option>
                    <option value="Liberian dollar">Liberian dollar</option>
                    <option value="Libyan dinar">Libyan dinar</option>
                    <option value="Litas">Litas</option>
                    <option value="Denar">Denar</option>
                    <option value="Malagasy Ariary">Malagasy Ariary</option>
                    <option value="Ringgit">Ringgit</option>
                    <option value="Rufiyaa">Rufiyaa</option>
                    <option value="Ouguiya">Ouguiya</option>
                    <option value="Mauritian rupee">Mauritian rupee</option>
                    <option value="Mexican peso">Mexican peso</option>
                    <option value="Leu">Leu</option>
                    <option value="Togrog">Togrog</option>
                    <option value="Metical">Metical</option>
                    <option value="Kyat">Kyat</option>
                    <option value="Namibian dollar">Namibian dollar</option>
                    <option value="Australian dollar">Australian dollar</option>
                    <option value="Nepalese rupee">Nepalese rupee</option>
                    <option value="New Zealand dollar">
                      New Zealand dollar
                    </option>
                    <option value="Gold cordoba">Gold cordoba</option>
                    <option value="Naira">Naira</option>
                    <option value="Norwegian krone">Norwegian krone</option>
                    <option value="Omani rial">Omani rial</option>
                    <option value="Pakistani rupee">Pakistani rupee</option>
                    <option value="Palestine Pound">Palestine Pound</option>
                    <option value="Balboa; U.S. dollar">
                      Balboa; U.S. dollar
                    </option>
                    <option value="Kina">Kina</option>
                    <option value="Guaraní">Guaraní</option>
                    <option value="Nuevo sol (1991)">Nuevo sol (1991)</option>
                    <option value="Peso">Peso</option>
                    <option value="Zloty">Zloty</option>
                    <option value="Qatari riyal">Qatari riyal</option>
                    <option value="Romanian Rupee">Romanian Rupee</option>
                    <option value="Ruble">Ruble</option>
                    <option value="Rwandan franc">Rwandan franc</option>
                    <option value="East Caribbean dollar">
                      East Caribbean dollar
                    </option>
                    <option value="Tala">Tala</option>
                    <option value="Dobra">Dobra</option>
                    <option value="Riyal">Riyal</option>
                    <option value="Serbian Dinar">Serbian Dinar</option>
                    <option value="Seychelles rupee">Seychelles rupee</option>
                    <option value="Leone">Leone</option>
                    <option value="Singapore dollar">Singapore dollar</option>
                    <option value="Solomon Islands dollar">
                      Solomon Islands dollar
                    </option>
                    <option value="Somali shilling">Somali shilling</option>
                    <option value="Rand">Rand</option>
                    <option value="Sri Lankan rupee">Sri Lankan rupee</option>
                    <option value="Sudanese Pound">Sudanese Pound</option>
                    <option value="Surinamese dollar">Surinamese dollar</option>
                    <option value="Lilangeni">Lilangeni</option>
                    <option value="Krona">Krona</option>
                    <option value="Swiss franc">Swiss franc</option>
                    <option value="Syrian pound">Syrian pound</option>
                    <option value="Taiwan dollar">Taiwan dollar</option>
                    <option value="somoni">somoni</option>
                    <option value="Tanzanian shilling">
                      Tanzanian shilling
                    </option>
                    <option value="Baht">Baht</option>
                    <option value="CFA Franc">CFA Franc</option>
                    <option value="Pa’anga">Pa’anga</option>
                    <option value="Trinidad and Tobago dollar">
                      Trinidad and Tobago dollar
                    </option>
                    <option value="Tunisian dinar">Tunisian dinar</option>
                    <option value="Turkish lira (YTL)">
                      Turkish lira (YTL)
                    </option>
                    <option value="Manat">Manat</option>
                    <option value="Tuvaluan Dollar">Tuvaluan Dollar</option>
                    <option value="Ugandan new shilling">
                      Ugandan new shilling
                    </option>
                    <option value="Hryvnia">Hryvnia</option>
                    <option value="U.A.E. Dirham">U.A.E. Dirham</option>
                    <option value="Pound sterling">Pound sterling</option>
                    <option value="Dollar">Dollar</option>
                    <option value="Uruguay peso">Uruguay peso</option>
                    <option value="Uzbekistani sum">Uzbekistani sum</option>
                    <option value="Vatu">Vatu</option>
                    <option value="Bolivar">Bolivar</option>
                    <option value="Dong">Dong</option>
                    <option value="Rial">Rial</option>
                    <option value="Kwacha">Kwacha</option>
                  </select>
                </div>

                <div className="reportlost-container-owner-reward-span-select-cnt">
                  <span>Reward</span>

                  <input
                    type="number"
                    name="reward"
                    value={reward}
                    onChange={this.changeHandler}
                  />
                </div>
              </span>

              <div className="reportlost-container-owner-reward-btns">
                <Button variant="contained" onClick={this.handleBack}>
                  Back
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  style={{ backgroundColor: "#ffc107" }}
                >
                  Submit
                </Button>

                <Button
                  type="button"
                  onClick={() => {
                    window.location.href =
                      "/dashboard/" + localStorage.getItem("uid");
                  }}
                  variant="contained"
                >
                  CANCEL
                </Button>
              </div>
            </span>
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  handleNext = () => {
    this.setState((prevActiveStep) => {
      // console.log("prev", prevActiveStep);
      return {
        activeStep: prevActiveStep.activeStep + 1,
      };
    });
  };

  handleBack = () => {
    this.setState((prevActiveStep) => {
      return {
        activeStep: prevActiveStep.activeStep - 1,
      };
    });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  render() {
    const {
      sidebar,
      subMenu,
      alignment,
      tag_number,
      valuable_type,
      parent_firstname,
      parent_lastname,
      phone_one,
      phone_two,
      parent_email,
      parent_altemail,
      reward,
      rewardcurrency,
      pet_age,
      pet_name,
      pet_breed,
      pet_gender,
      pet_color,
      pet_dob,
      pet_bloodgrp,
      pet_ident,
      veternity,
      dr_phone,
      phone_three,
    } = this.state;

    return (
      <>
        <Navbar />

        <div className="reportLost-main-wrapper">
          <div className="reportLost-left-wrapper">
            <div className="reportLost-sidebar-toggle-btn-wrapper">
              <button
                className="reportLost-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "reportLost-sidebar-container-hide"
                  : "reportLost-sidebar-container"
              }
            >
              <button
                className="reportLost-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "reportLost-sidebar-menu-ul-hide"
                    : "reportLost-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
              >
                ACTIVATE TAG
              </button>

              <button
                className="dashboard-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div>

          <div className="reportLost-right-wrapper">
            <div className="col-xl-9 col-lg">
              <form onSubmit={this.submitHandler}>
                <Stepper
                  activeStep={this.state.activeStep}
                  alternativeLabel
                  className=""
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <div
                  className="container uppercontainer bg-white px-0 p-info text-center reportlostheight"
                  style={{
                    height: this.state.activeStep === 3 ? "100%" : "570px",
                  }}
                >
                  {this.getStepContent(this.state.activeStep)}
                </div>

                <Snackbar
                  open={this.state.snackbaropen}
                  autoHideDuration={3000}
                  onClose={this.snackbarClose}
                  message={this.state.snackbarmsg}
                  action={[
                    <IconButton
                      arial-label="Close"
                      color="inherit"
                      onClick={this.snackbarClose}
                    >
                      X
                    </IconButton>,
                  ]}
                />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
