import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Footer from "../../footer/footerblack";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiPhoneInput from "material-ui-phone-number";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  IconButton,
  Dialog,
  DialogContent,
  Snackbar,
  Typography,

  // Button,
} from "@material-ui/core";
// import call from "../../../assets/call.png";
// import email from "../../../assets/email.png";
// import whatsapp from "../../../assets/whatsapp.png";
// import callback from "../../../assets/callback.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import "../viewhumandetails/viewhumandetails.component.css";
import "./viewpetdetails.component.css";
import axios from "axios";
import { switchUrls } from "../../../api";
import Medicalcard from "../viewhumandetails/Medicalcard";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Findermodal from "../../scan-routing/Findermodal";
const baseURL = switchUrls("genie");
const baseGatewayURL = switchUrls("gateway");

var usertoken = readCookie("token");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}
function ViewPetDetails({ tag_number }) {
  const [defaultRegion, setDefaultRegion] = useState("");
  const [token, setToken] = useState(false);
  const [error,seterror] = useState(false)
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  function toTitleCase(str) {
    if (str !== undefined) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  }
  const phonehandler = async (value, details) => {
    try {
      seterror(false)
      let { dialCode } = details;
      if (dialCode && value) {
        const phCode = "+" + dialCode;
        const sanitizedValue = value.replace(/[^0-9]/g, "");

        const phoneNumber = sanitizedValue.replace(
          phCode.replace(/[^0-9]/g, ""),
          ""
        );
        setformdata({
          ...formdata,
          contact_no: phoneNumber,
          phcode: phCode,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const handelmedical = async () => {
    if (!disabletab) {
      setmedicalemergency(true);
    } else {
      setvalue(1);
    }
  };
  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        
        const {data} = await axios.get("https://ipapi.co/json/")
        // console.log(data, "dtattata");

        if (data && data.country_code) {
          const userCountryCode = data.country_code.toLowerCase();
          // console.log(userCountryCode, "dtattata");
          setDefaultRegion(userCountryCode);
          
        } else {
           setDefaultRegion("in");
           
        }
      } catch (error) {
        setDefaultRegion("in");
      }
    };
    if (usertoken) {
      setToken(true);
    }
    fetchUserCountry();
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLoginClick = () => {
    const token = readCookie("token");
    if (token) {
      const uid = localStorage.getItem("uid");
      const dashboardRoute = "/dashboard/" + uid;
      window.location.href = dashboardRoute;
    } else {
      window.location.href = `${baseURL}/#/login`;
    }
  };
  const fetchdetails = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/pet/getPetParentDetails/` + tag_number
      );

      setpetdetails(data);
    } catch (error) {
      // console.log(error);
    }
  };
  const getlostdetails = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/pet/getLostDetails/` + tag_number
      );

      const lastIndexData = data.length > 0 ? data.length - 1 : 0;
      setlostdetails(data[lastIndexData]);
    } catch (error) {
      // console.log(error);
    }
  };
  const medicalcount = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/pet/countMedicalDetails/` + tag_number
      );

      setcountmedical(data);
    } catch (error) {}
  };
  const getallmedical = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/pet/getMedicalDetails/` + tag_number
      );

      setmedicaldetails(data);
    } catch (error) {}
  };
  const getcordinates = async (position) => {
    // console.log(position, "positionnnn");
    if (position.coords.latitude !== "" && position.coords.longitude !== "") {
      // console.log(this.state.latitude, "latitude");
      // console.log(this.state.longitude, "longitude");

      axios
        .post(`${baseGatewayURL}/api/ScanLocation/` + tag_number, {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
        .then((response) => {
          if (response.data) {
            //location is added
          } else {
            setsnackbar(true);
            setsnackbarmsg("Could not find location");
          }
        })
        .catch((error) => {
          // console.error(error.message);
          setsnackbar(true);
          setsnackbarmsg("Location could not be added");
          // this.setState({
          //   snackbaropen: true,
          //   snackbarmsg: "",
          // });
        });
    }

    try {
      const { data } = await axios.get(
        `${baseGatewayURL}/api/sendemail/` + tag_number,
        {
          params: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        }
      );
      setcurlatitude(position?.coords?.latitude);
      setcurlongitude(position?.coords?.longitude);
      setsnackbarmsg(data.result);
    } catch (error) {
      setsnackbar(true);
      setsnackbarmsg("Location could not be added");
      // console.log(error);
    }
  };
  const addscandata = async () => {
    try {
      const { data } = await axios.post(
        `${baseGatewayURL}/api/ScanLocation/:tag_number` +
          this.state.tag_number,
        { latitude: curlatitud, longitude: curlongitude }
      );
    } catch (error) {}
  };
  const reportfounduser = async () => {
    // console.log(tag_number, "tag_number");
    try {
      if (
        formdata?.first_name &&
        formdata?.email &&
        formdata?.contact_no &&
        formdata?.phcode &&
        formdata?.last_name
      ) {
        const updatedFormdata = {
          ...formdata,
          curlatitude: curlatitud,
          curlongitude: curlongitude,
        };
        const { data } = await axios.post(
          `${baseURL}/api/pet/ReportFoundUser/` + tag_number,
          updatedFormdata
        );
        // console.log(data);
        setmedicalemergency(false);
        setshowmedical(true);
        setdisabletab(true);
        setvalue(1);
      }
      else{
        
        seterror(true)
      }
    } catch (error) {
      // console.log(error);
      setmedicalemergency(false);

      // console.log(error);
      //  setsnackbar(true)
      // setsnackbarmsg('please try again')
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        getcordinates(position);
      });
    }
    fetchdetails();
    getlostdetails();
    medicalcount();
    getallmedical();
    addscandata();
  }, []);
  const [drawer, setdrawer] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarmsg, setsnackbarmsg] = useState("");
  const [petdetails, setpetdetails] = useState({});
  const [lostdetails, setlostdetails] = useState({});
  const [countmedical, setcountmedical] = useState({});
  const [showmedical, setshowmedical] = useState(true);
  const [medicaldetails, setmedicaldetails] = useState([]);
  const [medicalemegency, setmedicalemergency] = useState(false);
  const [showFooter, setShowFooter] = useState(window.innerWidth > 768);
  const [formdata, setformdata] = useState({});
  const [menu1, setmenu1] = useState(true);
  const [menu2, setmenu2] = useState(true);
  const [menu3, setmenu3] = useState(true);
  const [value, setvalue] = useState(0);
  const [medicalvalue, setmedicalvalue] = useState(0);
  const [disabletab, setdisabletab] = useState(false);
  const [curlatitud, setcurlatitude] = useState("");
  const [curlongitude, setcurlongitude] = useState("");
  const [vectorimg, setvectorimg] = useState({
    Cat: "https://storage.googleapis.com/pettag/qr/assets/cat.png",
    Dog: "https://storage.googleapis.com/pettag/qr/assets/dog.png",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window?.innerWidth >= 800);
  const [foundermodal, setfoundermodal] = useState(false);

  const changehandler = async (e) => {
    try {
      // console.log(e.target.name);
      setformdata({ [e.target.name]: e.target.value });
      setformdata({ ...formdata, [e.target.name]: e.target.value });
    } catch (error) {
      // console.log("er");
    }
  };
  const submithandler = async (e) => {
    e?.preventDefault()
    reportfounduser();
  };
  const handleScreen = () => {
    setShowFooter(window.innerWidth > 768);
    setdrawer(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreen);
    return () => {
      window.removeEventListener("resize", handleScreen);
    };
  }, []);
  return (
    <>
      <div>
        {foundermodal ? (
          <Findermodal
            findermodal={foundermodal}
            setfindermodal={setfoundermodal}
            tagnumber={tag_number}
            image= {petdetails?.pet_imagelocation || vectorimg[petdetails?.valuable_type] || "https://storage.googleapis.com/pettag/qrtagdev/assets/catdog1.jpg"  }
          />
        ) : null}
        <Dialog
          className="emerd-dilog"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          fullWidth
          maxWidth="sm"
          open={medicalemegency}
          onClose={() => setmedicalemergency(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="inner-emerd-div">
            <div style={{ padding: "0px", margin: "0px" }}>
              <h1
                style={{
                  margin: "15px",
                  font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                  letterSpacing: "normal",
                }}
              >
                Please Enter the Details.
              </h1>

              <form
                className="emerg_call"
                style={{
                  margin: "5px 25px",
                  display: "flex",
                  flexDirection: "column",
                }}
                onSubmit={submithandler}
              >
                <div className="reportfoundusername">
                  <div className="namediv">
                    <TextField
                      label="First Name"
                      value={formdata?.first_name}
                      onChange={changehandler}
                      name="first_name"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="namediv">
                    <TextField
                      label="Last Name"
                      value={formdata?.last_name}
                      onChange={changehandler}
                      name="last_name"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>

                <div className="reportfoundusername">
                  {defaultRegion && (
                    <MuiPhoneInput
                      label="Contact No"
                      defaultCountry={defaultRegion}
                      value={`+${formdata?.phcode} ${formdata?.contact_no}`}
                      onChange={phonehandler}
                      error = {error}
                      helperText = {error ? "Please enter a valid phone number" : ""}
                      regions={[
                        "america",
                        "caribbean",
                        "asia",
                        "middle-east",
                        "oceania",
                        "europe",
                        "africa",
                      ]}
                      variant="outlined"
                      name="guardian_alternate_mobile"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            edge="end"
                            aria-label="personal-details-icon"
                          >
                            <ContactPhoneIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                </div>
                <div className="reportfoundusername">
                  <TextField
                    label="Email"
                    value={formdata?.email}
                    onChange={changehandler}
                    name="email"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
           

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "7px",
                  marginTop: "15px",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                type="submit"
                  variant="contained"
                  style={{
                    margin: "10px 20px",
                    marginBottom: "35px",
                    flex: 0.5,
                    border: "none",
                  }}
                  className="otp-buttons bg-warning confirm subbutton"
                 
                >
                  CONFIRM
                </Button>

                <Button
                  variant="contained"
                  className="otp-buttons cancel subbutton"
                  style={{
                    margin: "10px 20px",
                    marginBottom: "35px",
                    flex: 0.5,
                    border: "none",
                  }}
                  onClick={() => setmedicalemergency(false)}
                >
                  CANCEL
                </Button>
              </div>
              </form>
            </div>
          </DialogContent>
        </Dialog>
        <div
          className="genie-nav"
          style={{
            borderBottom: "1px solid #ddd",
            paddingBottom: "5px",
          }}
        >
          <div className="left-logo">
            <img
              style={{ width: "5em" }}
              src="https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
              alt="logo"
            ></img>
          </div>
          <div className="genie-nav-button-container">
            <button className="genie-login-button" onClick={handleLoginClick}>
              {token ? "Dashboard" : "LOG IN"}
            </button>
          </div>
        </div>

        <div style={{ marginTop: "2rem" }}>
          <Container
            style={{ backgroundColor: "whitesmoke", border: "1px solid #ddd" }}
          >
            <div>
              <div className="text" style={{ textAlign: "center" }}>
                <p
                  className="header-font headingtext"
                  style={{ marginBottom: "0px" }}
                >
                  Thank you for your Help!
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div></div>
                <Tabs
                  value={value}
                  onChange={() => setvalue(value)}
                  // variant="scrollable"
                  scrollButtons={false}
                  centered
                >
                  <Tab
                    value={0}
                    onClick={() => setvalue(0)}
                    label="Pet Information"
                    wrapped
                  />

                  <Tab
                    value={1}
                    onClick={handelmedical}
                    label={
                      disabletab ? "Medical Information" : "Medical Emergency"
                    }
                    wrapped
                  />
                </Tabs>
              </div>
              {value === 0 ? (
                <>
                  <div className="basicdetailsdiv">
                    <List>
                      <ListItemButton onClick={() => setmenu1(!menu1)}>
                        <ListItemText>
                          <h5>Pet Details</h5>
                        </ListItemText>
                        {menu1 ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>

                      <Collapse in={menu1}>
                        <div className="basicdetailstab">
                          <div className="left">
                            <div className="imagebox">
                              <img
                                alt="img"
                                className="mat-card-image2"
                                src={
                                  petdetails?.pet_imagelocation
                                    ? petdetails?.pet_imagelocation
                                    : vectorimg[petdetails?.valuable_type]
                                } // Provide a default image source
                                width="auto"
                                height="340px"
                              />
                            </div>
                          </div>
                          <div className="right">
                            <div
                              className="information"
                              style={{ padding: "20px" }}
                            >
                              <Box
                                component="form"
                                sx={{
                                  "& .MuiTextField-root": {
                                    m: 1,
                                    width: "25ch",
                                  },
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <div className="twodiv">
                                  <TextField
                                    disabled
                                    label="Pet Name"
                                    value={toTitleCase(petdetails?.pet_name)}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { color: "#666" },
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                  />
                                  <TextField
                                    disabled
                                    label="Age"
                                    value={petdetails?.pet_age}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { color: "#666" },
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                  />
                                </div>
                                <div className="twodiv">
                                  <TextField
                                    disabled
                                    label="Gender"
                                    value={toTitleCase(petdetails?.pet_gender)}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { color: "#666" },
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                  />
                                  <TextField
                                    disabled
                                    label="Blood Group"
                                    value={petdetails?.pet_bloodgrp}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { color: "#666" },
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                  />
                                </div>
                                <div className="twodiv">
                                  <TextField
                                    disabled
                                    label="Pet Breed"
                                    value={petdetails?.pet_breed}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { color: "#666" },
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                  />
                                </div>
                                <div className="twodiv">
                                  <TextField
                                    disabled
                                    label="Identity Mark"
                                    value={petdetails?.pet_ident}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { color: "#666" },
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                  />
                                </div>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </List>
                    <List>
                      <ListItemButton onClick={() => setmenu2(!menu2)}>
                        <ListItemText>
                          <h5>Guardian Details</h5>
                        </ListItemText>
                        {menu2 ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={menu2}>
                        <div className="basicdetailstab">
                          <div className="left"></div>
                          <div className="right">
                            <div
                              className="information"
                              style={{ padding: "20px" }}
                            >
                              <Box
                                component="form"
                                sx={{
                                  "& .MuiTextField-root": {
                                    m: 1,
                                    width: "25ch",
                                  },
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <div>
                                  {petdetails?.parent_firstname ? (
                                    <TextField
                                      disabled
                                      label="Guardian Firstname"
                                      value={toTitleCase(
                                        petdetails?.parent_firstname
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#666" },
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                      }}
                                    />
                                  ) : null}
                                  {petdetails?.parent_lastname ? (
                                    <TextField
                                      disabled
                                      label="Guardian Lastname"
                                      value={petdetails?.parent_lastname}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#666" },
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                      }}
                                    />
                                  ) : null}
                                </div>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </List>
                  </div>
                </>
              ) : null}
              {value === 1 ? (
                <>
                  {showmedical && (
                    <>
                      <div
                        id="medicaldetails"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "2rem",
                        }}
                      >
                        <Tabs
                          value={medicalvalue}
                          onChange={() => setmedicalvalue(value)}
                          // variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                        >
                          <div className="d-flex flex-column align-items-center">
                            <div className="imgcount">
                              <span className="medicalcount">
                                {countmedical?.Vaccination}
                              </span>
                              <img
                                className="medicalimg"
                                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/vaccinated.png"
                                alt="vaccine"
                              ></img>
                            </div>

                            <Tab
                              value={0}
                              onClick={() => setmedicalvalue(0)}
                              label="Vaccination"
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <div className="imgcount">
                              <span className="medicalcount">
                                {countmedical?.Allergy}
                              </span>
                              <img
                                className="medicalimg"
                                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/allergy.png"
                                alt="allergy"
                              ></img>
                            </div>

                            <Tab
                              value={1}
                              onClick={() => setmedicalvalue(1)}
                              label="Allergy"
                            />
                          </div>

                          <div className="d-flex flex-column align-items-center">
                            <div className="imgcount">
                              <span className="medicalcount">
                                {countmedical?.Surgery}
                              </span>
                              <img
                                className="medicalimg"
                                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
                                alt="surgery"
                              ></img>
                            </div>

                            <Tab
                              value={2}
                              onClick={() => setmedicalvalue(2)}
                              label="Surgery"
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <div className="imgcount">
                              <span className="medicalcount">
                                {countmedical?.Medication}
                              </span>
                              <img
                                className="medicalimg"
                                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medication.png"
                                alt="medication"
                              ></img>
                            </div>

                            <Tab
                              value={3}
                              onClick={() => setmedicalvalue(3)}
                              label="Medication"
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <div className="imgcount">
                              <span className="medicalcount">
                                {countmedical?.Medicalcondition}
                              </span>
                              <img
                                className="medicalimg"
                                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medical-condition.png"
                                alt="Mc"
                              ></img>
                            </div>

                            <Tab
                              value={4}
                              onClick={() => setmedicalvalue(4)}
                              label="Medical Condition"
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <div className="imgcount">
                              <span className="medicalcount">
                                {countmedical?.Measurement}
                              </span>
                              <img
                                className="medicalimg"
                                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
                                alt="Measure"
                              ></img>
                            </div>

                            <Tab
                              value={5}
                              onClick={() => setmedicalvalue(5)}
                              label="Measurement"
                            />
                          </div>
                        </Tabs>
                      </div>

                      <div
                        className=""
                        style={{ marginTop: "2rem", marginBottom: "2rem" }}
                      >
                        {medicalvalue === 0 ? (
                          medicaldetails[0]?.Vaccination?.length ? (
                            <>
                              <div className="scrollablediv">
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  {medicaldetails?.length && (
                                    <>
                                      {medicaldetails[0]?.Vaccination
                                        ?.length && (
                                        <>
                                          {medicaldetails[0]?.Vaccination.map(
                                            (item) => {
                                              return (
                                                <Grid
                                                  item
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                >
                                                  <Medicalcard
                                                    props={item}
                                                    type={"vaccine"}
                                                  />
                                                </Grid>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100px",
                              }}
                            >
                              <p>No Record Found</p>
                            </div>
                          )
                        ) : null}
                        {medicalvalue === 1 ? (
                          medicaldetails[0]?.Allergy?.length ? (
                            <div className="scrollablediv">
                              <Grid container spacing={2}>
                                {medicaldetails?.length && (
                                  <>
                                    {medicaldetails[0]?.Allergy?.map((item) => {
                                      return (
                                        <Grid
                                          item
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                        >
                                          <Medicalcard
                                            props={item}
                                            type={"allergy"}
                                          />
                                        </Grid>
                                      );
                                    })}
                                  </>
                                )}
                              </Grid>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100px",
                              }}
                            >
                              <p>No Record Found</p>
                            </div>
                          )
                        ) : null}
                        {medicalvalue === 2 ? (
                          medicaldetails[0]?.Surgery?.length ? (
                            <div className="scrollablediv">
                              <Grid container spacing={2}>
                                {medicaldetails?.length && (
                                  <>
                                    {medicaldetails[0]?.Surgery?.length && (
                                      <>
                                        {medicaldetails[0]?.Surgery?.map(
                                          (item) => {
                                            return (
                                              <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Medicalcard
                                                  props={item}
                                                  type={"surgery"}
                                                />
                                              </Grid>
                                            );
                                          }
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Grid>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100px",
                              }}
                            >
                              <p>No Record Found</p>
                            </div>
                          )
                        ) : null}
                        {medicalvalue === 3 ? (
                          medicaldetails[0]?.Medication?.length ? (
                            <div className="scrollablediv">
                              <Grid container spacing={2}>
                                {medicaldetails?.length && (
                                  <>
                                    {medicaldetails[0]?.Medication?.length && (
                                      <>
                                        {medicaldetails[0]?.Medication?.map(
                                          (item) => {
                                            return (
                                              <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Medicalcard
                                                  props={item}
                                                  type={"med"}
                                                />
                                              </Grid>
                                            );
                                          }
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Grid>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100px",
                              }}
                            >
                              <p>No Record Found</p>
                            </div>
                          )
                        ) : null}
                        {medicalvalue === 4 ? (
                          medicaldetails[0]?.Medicalcondition?.length ? (
                            <div className="scrollablediv">
                              <Grid container spacing={2}>
                                {medicaldetails?.length && (
                                  <>
                                    {medicaldetails[0]?.Medicalcondition
                                      ?.length && (
                                      <>
                                        {medicaldetails[0]?.Medicalcondition?.map(
                                          (item) => {
                                            return (
                                              <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Medicalcard
                                                  props={item}
                                                  type={"medicalcondition"}
                                                />
                                              </Grid>
                                            );
                                          }
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Grid>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100px",
                              }}
                            >
                              <p>No Record Found</p>
                            </div>
                          )
                        ) : null}
                        {medicalvalue === 5 ? (
                          medicaldetails[0]?.Measurement?.length ? (
                            <div className="scrollablediv">
                              <Grid container spacing={2}>
                                {medicaldetails?.length && (
                                  <>
                                    {medicaldetails[0]?.Measurement?.map(
                                      (item) => {
                                        return (
                                          <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                          >
                                            <Medicalcard
                                              props={item}
                                              type={"Measurement"}
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </Grid>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100px",
                              }}
                            >
                              <p>No Record Found</p>
                            </div>
                          )
                        ) : null}
                      </div>
                    </>
                  )}
                </>
              ) : null}

              {/*
         <div  style={{backgroundColor:"white",borderRadius:"20px"}}>
        <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12} >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <img
                    alt="img"
                    className="mat-card-image2"
                    src="https://png.pngtree.com/png-vector/20190621/ourmid/pngtree-human-pose-icon-graphic-design-template-vector-png-image_1497707.jpg" // Provide a default image source
                    width="auto"
                    height="340px"
                  />
                </div>
                <div>
                  <p className="viewPetDetails-info-container-name">
                    {toTitleCase(petdetails?.name)}
                  </p>
                </div>
                <div>
                  <div className="viewPetDetails-info-container-bottom">
                    {petdetails?.age && `${petdetails?.age} year old`}
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div>
                <Grid style={{ marginBottom: "2rem" }} container spacing={1}>
                  {petdetails?.gender && (
                    <>
                      <Grid item lg={6} md={6} sm={6} xs={6} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <div className="text-center box1 ">
                          <p className="text-center lost-info-text-petdetails">
                            <img
                              alt="blood"
                              className="vector"
                              src={
                                "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/gender.svg"
                              }
                            />
                            {petdetails?.gender}
                          </p>
                        </div>
                      </Grid>
                    </>
                  )}
                  {petdetails?.blood_group && (
                    <>
                      <Grid item lg={6} md={6} sm={6} xs={6} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <div className="text-center box1">
                          <p className="text-center  lost-info-text-petdetails">
                            <img
                              alt="blood"
                              className="vector"
                              src={
                                "https://static.vecteezy.com/system/resources/thumbnails/020/967/309/small/red-blood-icon-free-png.png"
                              }
                            />
                            {petdetails?.blood_group}
                          </p>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>

                {petdetails?.lf_status === "lost" && (
                  <>
                    <Grid container spacing={1}>
                      <Grid item lg={6} md={6} sm={6} xs={6} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <div className="text-center box1">
                          <p
                            className=" text-center lost-info-text-petdetails"
                            style={{ wordBreak: "break-all" }}
                          >
                            <img
                              alt="blood"
                              className="vector"
                              src={
                                "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/money.png"
                              }
                            />
                            {lostdetails?.reward_currency} {lostdetails?.reward}
                          </p>
                          <p></p>
                        </div>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <div className="text-center box1">
                          <p className="text-center losttext">
                            <a
                              href={`https://maps.google.com/maps?q=${lostdetails?.lost_latitude},${lostdetails?.lost_longitude}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                alt="location"
                                className="vector"
                                src={
                                  "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/location.jpeg"
                                }
                              />
                              Location
                            </a>
                          </p>
                        </div>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <div className="text-center box1">
                          <p className="text-center losttext">
                            {lostdetails?.lost_date}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div> 
      */}
            </div>
            <div className="extrabottomdiv"></div>
            <div
              className="drawer  pet-bottom-div"
              onClick={() => {
                if (!isLargeScreen) setdrawer(!drawer);
              }}
            >
              {!drawer ? (
                <i
                  className=" fa-angle-double-up drawerarrow"
                  onClick={() => setdrawer(!drawer)}
                ></i>
              ) : (
                <i
                  className=" fa-angle-double-down drawerarrow"
                  onClick={() => setdrawer(!drawer)}
                ></i>
              )}

              <p
                style={{
                  fontSize: "25px",
                  fontFamily: "prompt,sans-serif",
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
              >
                Emergency Contact
              </p>

              <div className="guardianinfo">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="contactdivlg-human">
                    {petdetails?.parent_phone ? (
                      <>
                        <div
                          style={{ marginRight: "1rem", marginBottom: "1rem" }}
                        >
                          <a
                            href={`tel:${petdetails?.parent_phonecode}${petdetails?.parent_phone}`}
                            style={{ color: "black" }}
                          >
                            <p>
                              <div style={{ display: "inline-block" }}>
                                <img
                                  alt="call"
                                  className="reportfound-vector-humangenie"
                                  src={
                                    "https://storage.googleapis.com/pettag/qr/assets/call.png"
                                  }
                                />
                                {"  "}
                                Call
                              </div>

                              {/* <span style={{ display: "inline-block" }}>
                              &gt;
                            </span> */}
                            </p>
                          </a>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="contactdivlg-human">
                    {petdetails?.parent_phone ? (
                      <>
                        <div
                          style={{ marginRight: "1rem", marginBottom: "1rem" }}
                        >
                          <a
                            href={`sms:${petdetails?.parent_phonecode}${petdetails?.parent_phone}?body=Hello%20Owner`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "black" }}
                          >
                            <p>
                              <div style={{ display: "inline-block" }}>
                                <img
                                  alt="phone"
                                  className="reportfound-vector-humangenie"
                                  src={
                                    "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                  }
                                />{" "}
                                SMS
                              </div>
                            </p>
                          </a>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="contactdivlg-human">
                    {petdetails?.parent_phone ? (
                      <div
                        style={{ marginBottom: "1rem", marginRight: "1rem" }}
                      >
                        <a
                          href={`https://wa.me/${petdetails?.parent_phonecode?.replace(
                            "+",
                            ""
                          )}${petdetails?.parent_phone}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "black" }}
                        >
                          <p>
                            <div style={{ display: "inline-block" }}>
                              <img
                                alt="phone"
                                className="reportfound-vector-humangenie"
                                src={
                                  "https://storage.googleapis.com/pettag/qr/assets/whatsapp1.png"
                                }
                              />{" "}
                              Whatsapp
                            </div>

                            {/* <div style={{ display: "inline-block" }}>&gt;</div> */}
                          </p>
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="contactdivlg-human">
                    {petdetails?.parent_email && (
                      <>
                        <div
                          style={{ marginRight: "1rem", marginBottom: "1rem" }}
                        >
                          <a
                            href={`mailto:${petdetails?.parent_email}`}
                            style={{ color: "black" }}
                          >
                            <p>
                              <div style={{ display: "inline-block" }}>
                                <img
                                  alt="email"
                                  className="reportfound-vector-humangenie"
                                  src={
                                    "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                  }
                                />{" "}
                                Email
                              </div>

                              {/* <div style={{ display: "inline-block" }}>&gt;</div> */}
                            </p>
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="contactdivlg-human">
                    <>
                      <div
                        style={{
                          marginRight: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <p onClick={() => setfoundermodal(true)}>
                          <p
                            style={{
                              color: "black",
                              cursor: "pointer",
                            }}
                          >
                            <div style={{ display: "inline-block" }}>
                              <img
                                alt="email"
                                className="reportfound-vector-humangenie"
                                src={
                                  "https://storage.googleapis.com/pettag/qr/assets/callback.png"
                                }
                              />{" "}
                              Contact Me
                            </div>
                          </p>
                        </p>
                      </div>
                    </>
                  </div>
                </div>
              </div>

              <SwipeableDrawer
                className="swipabledrawer"
                anchor="bottom"
                open={drawer}
                onClose={() => setdrawer(!drawer)}
                onOpen={() => setdrawer(!drawer)}
                disableSwipeToOpen={false}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <div
                  className="reportfound-pet-bottom-inner-div guardianmobile"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <i
                      className="fa fa-angle-double-down drawerarrow"
                      onClick={() => setdrawer(!drawer)}
                    ></i>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      {petdetails?.parent_phone ? (
                        <>
                          <div
                            style={{
                              marginRight: "1rem",
                              height: "50%",
                              width: "50%",
                            }}
                          >
                            <a
                              href={`tel:${petdetails?.parent_phonecode}${petdetails?.parent_phone}`}
                              style={{ color: "black" }}
                            >
                              <p className="pabr footer-font flex-between">
                                <div
                                  style={{
                                    display: "inline-block",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div style={{ marginBottom: "5px" }}>
                                    <img
                                      alt="call"
                                      className="reportfound-vector"
                                      src={
                                        "https://storage.googleapis.com/pettag/qr/assets/phone-call.png"
                                      }
                                    />
                                  </div>
                                  <div>
                                    <span>Call</span>
                                  </div>
                                </div>

                                {/* <span style={{ display: "inline-block" }}>
                              &gt;
                            </span> */}
                              </p>
                            </a>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {petdetails?.parent_phone ? (
                        <>
                          <div
                            style={{
                              marginBottom: "1rem",
                              height: "50%",
                              width: "50%",
                            }}
                          >
                            <a
                              href={`sms:${petdetails?.parent_phonecode}${petdetails?.parent_phone}?body=Hello%20Owner`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "black" }}
                            >
                              <p className="flex-between footer-font text-center pabr">
                                <div style={{ display: "inline-block" }}>
                                  <div
                                    style={{
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <img
                                      alt="phone"
                                      className="reportfound-vector"
                                      src={
                                        "https://storage.googleapis.com/pettag/qr/assets/message.png"
                                      }
                                    />
                                  </div>
                                  <div>
                                    <span>SMS</span>
                                  </div>
                                </div>
                              </p>
                            </a>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div style={{ display: "flex" }}>
                        {petdetails?.parent_phone ? (
                          <div
                            style={{
                              marginBottom: "1rem",
                              marginRight: "1rem",
                              height: "50%",
                              width: "47%",
                            }}
                          >
                            <a
                              href={`https://wa.me/${petdetails?.guardian_code?.replace(
                                "+",
                                ""
                              )}${petdetails?.parent_phone?.replace(/-/g, "")}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "black" }}
                            >
                              <p className="pabr flex-between footer-font text-center">
                                <div style={{ display: "inline-block" }}>
                                  <div
                                    style={{
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <img
                                      alt="phone"
                                      className="reportfound-vector"
                                      src={
                                        "https://pettag.storage.googleapis.com/qr-bag/WhatsAppImage2024-03-20at2.25.34PM.jpeg1710925717209"
                                      }
                                    />
                                  </div>
                                  <div>Whatsapp</div>
                                </div>

                                {/* <div style={{ display: "inline-block" }}>&gt;</div> */}
                              </p>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        {petdetails?.parent_email ? (
                          <div
                            style={{
                              height: "50%",
                              width: "50%",
                            }}
                          >
                            <a
                              href={`mailto:${petdetails?.parent_email}`}
                              style={{ color: "black" }}
                            >
                              <p className="flex-between footer-font text-center pabr">
                                <div style={{ display: "inline-block" }}>
                                  <div
                                    style={{
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <img
                                      alt="email"
                                      className="reportfound-vector"
                                      src={
                                        "https://storage.googleapis.com/pettag/qr/assets/email.png"
                                      }
                                    />
                                  </div>
                                  <div>
                                    <span> Email</span>
                                  </div>
                                </div>

                                {/* <div style={{ display: "inline-block" }}>&gt;</div> */}
                              </p>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <p onClick={() => setfoundermodal(true)}>
                          <p className="pabr flex-between welcome-footer-font text-center">
                            <div style={{ display: "inline-block" }}>
                              <img
                                src={
                                  "https://pettag.storage.googleapis.com/qr-bag/Untitleddesign(39)(1).png1710925466237"
                                }
                                alt="contact support"
                                className="vector"
                                loading="lazy"
                              />
                              &nbsp; Contact Me
                            </div>
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </SwipeableDrawer>
            </div>
          </Container>
        </div>
        <Snackbar
          open={snackbar}
          autoHideDuration={8000}
          onClose={() => setsnackbar(false)}
          message={snackbarmsg}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={() => setsnackbar(false)}
            >
              X
            </IconButton>,
          ]}
        />
      </div>
      {showFooter ? <Footer /> : ""}
    </>
  );
}

export default ViewPetDetails;
