import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";
import "./updatecards.component.css";
import Button from "@material-ui/core/Button";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import * as AiIcons from "react-icons/ai";
import { switchUrls } from "../../../api/index.js";

const baseURL = switchUrls("genie");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let reports = [];

export default class AllergyUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      selectedImage: null,
      allergy_reminder: false,
      tag_number: localStorage.getItem("tag_number"),
      Type: "Allergy",
      allergy_id: this.props.allergy_id,
      Allergy_name: "",
      AllergyDoctor_name: "",
      AllergyDiagnosis_date: "",
      AllergySeverity: "",
      AllergyNotes: "",
      AllergyTitle: "",
      AllergyEverynumber: "",
      AllergyEvery: "",
      AllergyNext: "",
      AllergyRemind_me: "",
      AllergyReminderNotes: "",
      AllergyDone: false,
      Allergy_Reportname: "",
      Allergy_Reportlocation: "",
      Allergy_Reportnames: [],
      Allergy_Reportlocations: [],
      message: "",
      docs: [],
    };
  }

  join = (t, a, s) => {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);

      return f.format(t);
    }

    return a.map(format).join(s);
  };

  month = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];

  componentDidMount() {
    axios
      .get(`${baseURL}/api/pet/getMedicalDetails/` + this.state.tag_number)
      .then((response) => {
        response.data[0].Allergy.map((elm) => {
          if (this.state.allergy_id === elm.allergy_id) {
            this.setState({
              Allergy_name: elm.Allergy_name,
              AllergyDoctor_name: elm.AllergyDoctor_name,
              AllergyDiagnosis_date: this.join(
                new Date(elm.AllergyDiagnosis_date),
                this.month,
                "-"
              ),
              AllergySeverity: elm.AllergySeverity,
              AllergyNotes: elm.AllergyNotes,
              AllergyTitle: elm.AllergyTitle,
              AllergyNext: elm.AllergyNext
                ? this.join(new Date(elm.AllergyNext), this.month, "-")
                : "",
              AllergyRemind_me: elm.AllergyRemind_me,
              AllergyReminderNotes: elm.AllergyReminderNotes,
              AllergyDone: elm.AllergyDone,
              Allergy_Reportname: elm.Allergy_Reportname,
              Allergy_Reportlocation: elm.Allergy_Reportlocation,
              Allergy_Reportnames: elm.Allergy_ReportsNames,
              Allergy_Reportlocations: elm.Allergy_ReportsLocations,
            });
          }
        });

        for (let i = 0; i < this.state.Allergy_Reportnames.length; i++) {
          let obj = {
            name: this.state.Allergy_Reportnames[i],
            location: this.state.Allergy_Reportlocations[i],
          };

          reports.push(obj);
        }

        this.setState({ docs: reports });
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  setReports = () => {
    for (let i = 0; i < this.state.Allergy_ReportsNames.length; i++) {
      let obj = {
        name: this.state.Allergy_ReportsNames[i],
        location: this.state.Allergy_ReportsLocations[i],
      };

      reports.push(obj);
    }

    this.setState({ docs: reports });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  allergyChangeHandler = (e) => {
    this.setState({ [e.target.name]: !this.state.AllergyDone });
  };

  handleChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileSize = file.size;

    const fileExtension = fileName.split(".")[1];
    const size = fileSize / (1024 * 1024);

    const regex = /\./gm;

    const isValidFileExtension = [
      "pdf",
      "docx",
      "docs",
      "png",
      "jpg",
      "jpeg",
      "csv",
      "xlsx",
    ].includes(fileExtension);
    const isValidSize = size < 5 ? true : false;
    const isValidFileName = fileName.match(regex).length === 1 ? true : false;

    if (isValidFileExtension && isValidSize && isValidFileName) {
      this.setState({ message: "" });
      this.setState((prevState) => ({
        message: "",
        docs: [...prevState.docs, event.target.files[0]],
      }));
    } else if (!isValidFileName) {
      this.setState({
        message:
          "Oops!! File name is not appropriate. Kindly rename the file and try again.",
      });
    } else if (!isValidSize) {
      this.setState({ message: "File size should be less than 5 MB" });
    } else if (!isValidFileExtension) {
      this.setState({
        message: `File with extension .${fileExtension} are not allowed!`,
      });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();

    axios
      .post(`${baseURL}/api/pet/updateMedicalDetails`, this.state, {
        headers: { Authorization: usertoken },
      })
      .then((res) => {
        const fd = new FormData();

        for (let i = 0; i < this.state.docs.length; i++) {
          fd.append("image", this.state.docs[i]);
        }

        fd.append("tag_number", this.state.tag_number);
        fd.append("Type", this.state.Type);
        fd.append("allergy_id", this.state.allergy_id);

        axios
          .post(`${baseURL}/api/pet/reports-upload`, fd, {
            Accept: "application/json",
          })
          .then((response) => {
            window.location.reload();

            console.info(response);
          })
          .catch((error) => {
            console.error(error.message);
          });
      })
      .catch((error) => {
        console.error(error.message);

        this.props.closeDialog();
      });
  };

  render() {
    const {
      allergy_reminder,
      Allergy_name,
      AllergyDoctor_name,
      AllergyDiagnosis_date,
      AllergySeverity,
      AllergyNotes,
      AllergyTitle,
      AllergyEverynumber,
      AllergyEvery,
      AllergyNext,
      AllergyRemind_me,
      AllergyReminderNotes,
      AllergyDone,
      Allergy_Reportname,
      Allergy_Reportlocation,
    } = this.state;

    return (
      <div className="update-card-details-form-wrapper">
        <form>
          <h4>Allergy</h4>

          <div className="update-card-details-form-content">
            <label>Allergy Name *</label>

            <input
              type="textbox"
              className="update-card-details-form-input"
              name="Allergy_name"
              required
              value={Allergy_name}
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Doctor's Name</label>

            <input
              type="textbox"
              className="update-card-details-form-input"
              maxlength="25"
              name="AllergyDoctor_name"
              value={AllergyDoctor_name}
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Diagnosis Date *</label>

            <input
              type="date"
              className="update-card-details-form-input"
              name="AllergyDiagnosis_date"
              required
              value={AllergyDiagnosis_date}
              onChange={this.changeHandler}
            />
          </div>

          <div className="update-card-details-form-content">
            <label>Severity</label>

            <select
              className="update-card-details-form-input"
              name="AllergySeverity"
              value={AllergySeverity}
              onChange={this.changeHandler}
            >
              <option hidden></option>
              <option value="RAST 0 - Undetectable">
                RAST 0 - Undetectable
              </option>
              <option value="RAST 1 - Low">RAST 1 - Low</option>
              <option value="RAST 2- Moderate">RAST 2- Moderate</option>
              <option value="RAST 3 - High">RAST 3 - High</option>
              <option value="RAST 4 - Very High">RAST 4 - Very High</option>
              <option value="RAST 5 - Ultra High">RAST 5 - Ultra High</option>
              <option value="RAST 6 - Extremely High">
                RAST 6 - Extremely High
              </option>
            </select>
          </div>

          <div className="update-card-details-form-content">
            <label>Notes</label>

            <textarea
              rows="2"
              name="AllergyNotes"
              value={AllergyNotes}
              onChange={this.changeHandler}
            />
          </div>

          <Button
            onClick={() => {
              this.setState({ allergy_reminder: !allergy_reminder });
            }}
          >
            <AddAlertIcon />
            Set Reminder
          </Button>

          {allergy_reminder ? (
            <>
              {/* <div className="">
                <p className="">Allergy</p>
                <Button
                  className="p-0 myexp-btn"
                  style={{ marginLeft: "80px" }}
                  onClick={() => {
                    this.setState({
                      allergy_reminder: !allergy_reminder,
                    });
                  }}
                >
                  <ExpandMoreIcon />
                </Button>
              </div> */}
              <div className="update-card-details-form-content">
                <label>Reminder Title</label>

                <input
                  type="textbox"
                  className="update-card-details-form-input"
                  name="AllergyTitle"
                  value={AllergyTitle}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="update-card-details-form-content">
                <label>Next Appointment Date</label>

                <input
                  type="date"
                  className="update-card-details-form-input"
                  name="AllergyNext"
                  value={AllergyNext}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="update-card-details-form-content">
                <label>Remind Me</label>

                <select
                  className="update-card-details-form-input"
                  name="AllergyRemind_me"
                  value={AllergyRemind_me}
                  onChange={this.changeHandler}
                >
                  <option hidden></option>
                  <option value="On The Same Day">On The Same Day</option>
                  <option value="1 Day Before">1 Day Before</option>
                  <option value="2 Days Before">2 Days Before</option>
                  <option value="3 Days Before">3 Days Before</option>
                  <option value="15 Days Before">15 Days Before</option>
                  <option value="1 Month Before">1 Month Before</option>
                </select>
              </div>

              <div className="update-card-details-form-content">
                <label>Notes</label>

                <textarea
                  row="2"
                  name="AllergyReminderNotes"
                  value={AllergyReminderNotes}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="update-card-details-form-checkbox">
                <label htmlFor="all">Done</label>

                <input
                  type="checkbox"
                  checked={AllergyDone}
                  id="all"
                  name="AllergyDone"
                  value={AllergyDone}
                  onChange={this.allergyChangeHandler}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="update-card-details-uploadReport-container">
            <label>
              <AiIcons.AiOutlineUpload /> Report
            </label>

            <input type="file" onChange={this.handleChange} />

            <label style={{ color: "red" }}>{this.state.message}</label>

            {this.state.docs.length
              ? this.state.docs.map((rep) => (
                  <Tooltip title={rep.name}>
                    <a href={rep.location}>
                      <AiIcons.AiFillFileText />
                    </a>
                  </Tooltip>
                ))
              : null}

            {Allergy_Reportlocation ? (
              <Tooltip title={Allergy_Reportname}>
                <a href={Allergy_Reportlocation}>
                  <AiIcons.AiFillFileText />
                </a>
              </Tooltip>
            ) : null}
          </div>

          <div className="update-card-details-btn-wrapper">
            <button
              className="update-card-details-btns"
              style={{ backgroundColor: "#ffc107", color: "white" }}
              onClick={this.submitHandler}
            >
              Submit
            </button>

            <button
              className="update-card-details-btns"
              style={{
                color: "black",
              }}
              onClick={this.props.closeDialog}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    );
  }
}
