import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
} from "@material-ui/core";
import MuiPhoneInput from "material-ui-phone-number";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import axios from "axios";
import { switchUrls } from "../../api";
const baseUrl = switchUrls("gateway");
function Userdetailscomponent({
  userdetailsmodal,
  userdetails,
  setuserdetailsmodal,
}) {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }
  const [formdata, setformdata] = useState({});
  const [error, seterror] = useState("");
  const [defaultRegion, setDefaultRegion] = useState("");
  const [loader,setloader] = useState(false)
  const changehandler = async (e) => {
    try {
      setformdata({ ...formdata, [e.target.name]: e.target.value });
    } catch (error) {}
  };

  const submitHandler = async (e) => {
    try {
      e?.preventDefault();
    if(!userdetails?.phonecountrycode || !userdetails?.phone){
      if((formdata?.phonecountrycode && formdata?.phone) || (formdata?.phone && defaultRegion)){
        const token = readCookie("token");

        const { data } = await axios
        .post(`${baseUrl}/api/updatemissingdetails`, formdata, {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((data) => {
          setuserdetailsmodal(false);

          window.location.reload();
        })
        .catch((er) => {
          setuserdetailsmodal(false);
          
        });

      }
      else{
        seterror(true)
      }
    }
    else{
      const token = readCookie("token");
      const { data } = await axios
      .post(`${baseUrl}/api/updatemissingdetails`, formdata, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        setuserdetailsmodal(false);

        window.location.reload();
      })
      .catch((er) => {
        setuserdetailsmodal(false);
        seterror("unexpected error ocured");
      });

    }
    

    } catch (er) {
      console.log(er);
    }
  };
  const phonehandler = async (value, details) => {
    try {
    seterror(false)
      let { dialCode } = details;
      if (dialCode && value) {
        const phCode = "+" + dialCode;
        const sanitizedValue = value.replace(/[^0-9]/g, "");
        // const phoneNumber = value.replace(phCode, "");
        const phoneNumber = sanitizedValue.replace(
          phCode.replace(/[^0-9]/g, ""),
          ""
        );
        setformdata({
          ...formdata,
          phone: phoneNumber,
          phonecountrycode: phCode,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const fetchUserCountry = async () => {
    try {
      setloader(true)
      const {data} = await axios.get("https://ipapi.co/json/")

      if (data && data.country_code) {
        const userCountryCode = data.country_code.toLowerCase();
        setDefaultRegion(userCountryCode);
        setloader(false)
      } else {
        setDefaultRegion("in");
        setloader(false)
      }
    } catch (error) {
      setDefaultRegion("in");
      setloader(false)
    }
  };
  useEffect(() => {
    fetchUserCountry();
  }, []);

  return (
    <>
   {
    loader ? <>
     <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
    </> : <>
       <Dialog
        className="emerd-dilog"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        fullWidth
        maxWidth="sm"
        open={userdetailsmodal}
        onClose={() => setuserdetailsmodal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="inner-emerd-div">
          <div style={{ padding: "0px", margin: "0px" }}>
            <h1
              style={{
                margin: "15px",
                font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                letterSpacing: "normal",
              }}
            >
              Please Enter the Details
            </h1>
            <p style={{ textAlign: "center", padding: "1rem" }}>
              Your Details Are Missing Kindly Fill It For A Smooth Working{" "}
            </p>

            <form
             onSubmit={submitHandler}
              className="emerg_call"
              style={{
                margin: "5px 25px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="reportfoundusername">
                {!userdetails?.first_name ? (
                  <div className="namediv">
                    <TextField
                      label="First Name"
                      value={formdata?.first_name}
                      onChange={changehandler}
                      name="first_name"
                      required
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                ) : null}
                {!userdetails?.last_name ? (
                  <div className="namediv">
                    <TextField
                      label="Last Name"
                      value={formdata?.last_name}
                      onChange={changehandler}
                      name="last_name"
                      required
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                ) : null}
              </div>

              <div className="reportfoundusername">
                {!userdetails?.phonecountrycode || !userdetails?.phone ? (
                  <MuiPhoneInput
                    required
                    label="Contact No"
                    defaultCountry={defaultRegion ? defaultRegion : "in"}
                    value={`+${formdata?.phonecountrycode} ${formdata?.phone}`}
                    onChange={phonehandler}
                    error = {error}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    variant="outlined"
                    name="mobile"
                    helperText = {error ? "Please enter a valid phone number" : ""}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="personal-details-icon"
                        >
                          <ContactPhoneIcon />
                        </IconButton>
                      ),
                    }}
                  />
                ) : null}
              </div>
            

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "7px",
                  marginTop: "15px",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                 
                  type="submit"
                  variant="contained"
                  style={{
                    margin: "10px 20px",
                    marginBottom: "35px",
                    flex: 0.5,
                    border: "none",
                  }}
                  className="otp-buttons bg-warning confirm subbutton"
                >
                  CONFIRM
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
   }
    </>
  );
}

export default Userdetailscomponent;
